import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import useSelectAuth from 'hooks/use-select-auth';

export default function (walletsCollection) {
  const auth = useSelectAuth();

  useFirestoreConnect(auth.uid ? [
    { collection: `${walletsCollection}/${auth.uid}/history`, storeAs: 'renderWalletHistory', orderBy: ['date', 'desc'] },
  ] : []);
  const history = useSelector(({ firestore: { ordered } }) => ordered.renderWalletHistory);

  return history || [];
}
