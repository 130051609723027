import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

import FirebaseImage from 'components/FirebaseImage';
import { red } from 'colors';

const HeartHoverWrapper = styled(({ favourite, ...props }) => <div {...props} />)`
  position: relative;
  user-select: none;
  border-radius: 2px;
  overflow: hidden;
  transition: transform 0.2s;
  cursor: pointer;

  .anticon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${({ favourite }) => favourite ? 1 : 0};
    transition: opacity 0.3s, font-size 0.2s, color 0.5s;
    color: ${({ favourite }) => favourite ? red[4] : 'white'};
    font-size: ${({ favourite }) => favourite ? 50 : 40}px;
    filter: drop-shadow(0 0 8px #00000020);
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    transform: scale(1.05);

    .anticon {
      font-size: 60px;
      opacity: 1;
    }
  }

  &:active .anticon {
    font-size: 55px;
    opacity: 1;
  }
`;

export default function ({ favourite = false, onClick, ...props }) {
  return (
    <HeartHoverWrapper favourite={favourite} onClick={onClick}>
      <Icon type="heart" theme="filled" />

      <FirebaseImage {...props} />
    </HeartHoverWrapper>
  );
}
