import React from 'react';
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { Route, Redirect } from 'react-router';
import useSelectAuth from 'hooks/use-select-auth';
import DelayedSpin from './DelayedSpin';

export default function ({ children, ...rest }) {
  const auth = useSelectAuth();

  if (!isLoaded(auth)) {
    return <DelayedSpin />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => isEmpty(auth) ? children : (
        <Redirect
          to={{ pathname: "/intro", state: { from: location } }}
        />
      )}
    />
  );
}
