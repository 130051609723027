import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { useDrop } from 'react-dnd'
import shortid from 'shortid';

import { lightgrey } from 'colors';
import { selectAudioDurationSeconds, selectNodesTimes, selectWaveform } from 'store/selectors';
import useSelectAuth from 'hooks/use-select-auth';
import useSelectProject from 'hooks/use-select-project';
import fixCustomNodes from 'utils/fix-custom-nodes';
import SelectImageModal from '../SelectImageModal';
import DroppableNodeTime from './DroppableNodeTime'

const Wrapper = styled.div`
  position: relative;
  height: 80px;
  margin: 0 auto;
  will-change: width;
  transition: width 0.3s;

  > div {
    position: absolute;
    top: 0;
    transition: left 0.3s;
    will-change: left;
    padding-top: 10px;
    margin-top: -10px;

    &:hover {
      z-index: 3;
    }

    > div {
      width: 80px;
      height: 80px;
      background-color: ${lightgrey[0]};
      border-radius: 0 4px 4px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      cursor: default;
      user-select: none;
      position: relative;

      &.dragging {
        transition: background-color 0.3s;
      }

      &.grabbable {
        cursor: grab;

        &:active {
          cursor: grabbing;
        }
      }

      &:after {
        content: '';
        position: absolute;
        display: block;
        top: -10px;
        left: 0;
        z-index: 1;
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-left: 10px solid ${lightgrey[8]};
        transition: border-left-color 0.3s;
      }

      .firebase-img {
        z-index: 2;
        border-radius: 0 4px 4px 4px;
      }

      .anticon {
        font-size: 30px;
        color: ${lightgrey[3]};
        transition: color 0.3s;
      }
    }
  }
`;

function getDumbKey(imageUrlCounter, projectNodes, time) {
  const imageUrl = projectNodes[time];
  let key;
  if (imageUrl) {
    imageUrlCounter[imageUrl] = (imageUrlCounter[imageUrl] || 0) + 1;
    if (imageUrlCounter[imageUrl] > 1) {
      key = `${imageUrl}${imageUrlCounter[imageUrl]}`;
    } else {
      key = imageUrl;
    }
  } else {
    key = time;
  }

  return key;
}
const smartKeys = {};
function getStableSmartKey(dumbKey) {
  if (!smartKeys[dumbKey]) {
    smartKeys[dumbKey] = shortid.generate();
  }
  return smartKeys[dumbKey];
}
function clearStableSmartKey(dumbKey) {
  delete smartKeys[dumbKey];
}

export default function () {
  const firestore = useFirestore();
  const auth = useSelectAuth();
  const project = useSelectProject();

  const waveform = useSelector(selectWaveform);
  const samplesPerSecond = (waveform || {}).num_samples_per_second || 2; // default is 2 samples per second
  const waveformData = (waveform || {}).data || waveform;
  const pixelsPerSecond = useSelector((state) => state.view.pixelsPerSecond);
  const pixelsPerBar = pixelsPerSecond / samplesPerSecond;
  const width = waveformData.length * pixelsPerBar;

  const nodesTimes = useSelector(selectNodesTimes);
  const audioDurationSeconds = useSelector(selectAudioDurationSeconds);

  const [isSelectImageModalVisible, setIsSelectImageModalVisible] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const projectNodes = (project || {}).nodes || {};

  const [nodesStateLocal, setNodesStateLocal] = useState({
    nodesTimes,
    projectNodes,
  });

  useEffect(() => {
    const nodesState = {
      nodesTimes,
      projectNodes,
    };
    if (JSON.stringify(nodesState) !== JSON.stringify(nodesStateLocal)) {
      setNodesStateLocal(nodesState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesTimes, projectNodes])

  const [, dropRef] = useDrop(
    {
      accept: 'FirebaseImage',
      drop(item, monitor) {
        if (!project) {
          return;
        }

        clearStableSmartKey(item.dumbKey);

        const delta = monitor.getDifferenceFromInitialOffset();
        const deltaSeconds = delta.x / pixelsPerSecond;
        const oldTime = item.time;
        const newTime = oldTime + deltaSeconds;

        const existingNodes = projectNodes || {};
        let newNodes = {
          ...existingNodes,
        };
        const image = newNodes[oldTime];
        delete newNodes[oldTime];
        newNodes[newTime] = image;

        const newEntries = Object.entries(newNodes);
        newEntries.sort((a, b) => Number.parseFloat(a[0]) - Number.parseFloat(b[0]));
        const newIndex = newEntries.findIndex(([time, image]) => Number.parseFloat(time) === newTime && image === existingNodes[oldTime]);

        newNodes = fixCustomNodes(newNodes, audioDurationSeconds, newIndex);
        const newNodesTimes = Object.keys(newNodes).map((t) => Number.parseFloat(t));
        // Need to use local state for rendering otherwise the UI glitches since it renders
        // before the new state has propagates... :/
        setNodesStateLocal({
          nodesTimes: newNodesTimes,
          projectNodes: newNodes,
        });

        firestore.collection('projects').doc(project.id).update({
          nodes: newNodes,
          updatedAt: new Date(),
        });
      },
    },
    [projectNodes, audioDurationSeconds],
  );

  function selectFavourite(sampleId) {
    if (project && (selectedTime || selectedTime === 0)) {
      firestore.collection('projects').doc(project.id).update({
        nodes: {
          ...(projectNodes || {}),
          [selectedTime]: sampleId,
        },
        updatedAt: new Date(),
      });
    }
  }

  function clear(time) {
    if (project) {
      const existingNodes = projectNodes || {};
      let newNodes = {
        ...existingNodes,
      };

      delete newNodes[time];

      // Temporary equal spacing solution
      newNodes = fixCustomNodes(newNodes, audioDurationSeconds, null);
      const newNodesTimes = Object.keys(newNodes).map((t) => Number.parseFloat(t));
      // Need to use local state for rendering otherwise the UI glitches since it renders
      // before the new state has propagated... :/
      setNodesStateLocal({
        nodesTimes: newNodesTimes,
        projectNodes: newNodes,
      });
      // End of temporary spacing solution

      firestore.collection('projects').doc(project.id).update({
        nodes: newNodes,
        updatedAt: new Date(),
      });
    }
  }

  if (!nodesTimes || nodesTimes.length === 0) {
    return <Wrapper />
  }

  const imageUrlCounter = {};

  return (
    <Wrapper
      ref={dropRef}
      style={{ width }}
    >
      {nodesStateLocal.nodesTimes.map((time, index) => {
        const dumbKey = getDumbKey(imageUrlCounter, nodesStateLocal.projectNodes, time);
        return (
          <DroppableNodeTime
            key={getStableSmartKey(dumbKey)}
            dumbKey={dumbKey}
            time={time}
            setSelectedTime={setSelectedTime}
            setIsSelectImageModalVisible={setIsSelectImageModalVisible}
            clear={clear}
            project={project}
            projectNodes={nodesStateLocal.projectNodes}
            pixelsPerSecond={pixelsPerSecond}
            auth={auth}
            isDraggable={index !== 0 && index !== nodesStateLocal.nodesTimes.length - 1}
          />
        );
      })}

      <SelectImageModal
        visible={isSelectImageModalVisible}
        close={() => {
          setIsSelectImageModalVisible(false);
          setSelectedTime(null);
        }}
        onSelect={(favourite) => selectFavourite(favourite)}
      />
    </Wrapper>
  );
}
