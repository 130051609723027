import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { withSize } from 'react-sizeme';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { Button, message } from 'antd';

import { brand, lightgrey, magenta } from 'colors';
import useSelectProject from 'hooks/use-select-project';
import { currentTimeAugmentedSubject } from 'store/current-time-subject';
import {
  selectWaveform,
  selectPlaybackDuration,
  selectAudioDurationSeconds,
  selectIsProjectImageSourceUser,
  selectNodesTimes,
} from 'store/selectors';
import setCurrentTimeSubject from 'store/set-current-time-subject';
import fixCustomNodes, { MINIMUM_SECONDS_BETWEEN_NODES } from 'utils/fix-custom-nodes';
import SelectImageModal from './SelectImageModal';
import config from 'config';


const HEIGHT = 120;

const Wrapper = styled.div`
  position: relative;
  height: ${HEIGHT}px;
  margin: 0 auto;
  background-color: ${lightgrey[1]};
  transition: opacity 0.3s;

  .progress, canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: ${HEIGHT}px;
  }

  .progress {
    z-index: 0;
    background-color: ${brand.primary};
    background: linear-gradient(180deg, ${brand.primary} 15%, ${magenta[3]} 100%);
    transform-origin: top left;
    will-change: transform;
  }

  .add-image-button {
    opacity: 0;
    transition: opacity 0.2s;
    will-change: opacity;
  }
  &:hover .add-image-button {
    opacity: 1;
  }

  canvas {
    z-index: 1;
  }
`;

const AddImageButton = styled.div`
  position: absolute;
  left: 0;
  bottom: 10px;
  transform: translateX(-50%) scale(0.8);
  cursor: pointer;
  z-index: 1;

  button {
    outline: none !important;
    border: none !important;
  }

  svg {
    transform: translateY(-1px);
  }
`;

function Waveform({ size }) {
  const firestore = useFirestore();
  const waveform = useSelector(selectWaveform);
  const waveformData = (waveform || {}).data || waveform;
  const samplesPerSecond = (waveform || {}).num_samples_per_second || 2; // default is 2 samples per second
  const waveformDuration = (waveformData || []).length / samplesPerSecond;
  const project = useSelectProject();
  const isImageSourceUser = useSelector(selectIsProjectImageSourceUser);

  const pixelsPerSecond = useSelector((state) => state.view.pixelsPerSecond);
  const duration = useSelector(selectPlaybackDuration);
  const audioDurationSeconds = useSelector(selectAudioDurationSeconds);

  const canvasRef = useRef();
  const progressRef = useRef();
  const pixelsPerSecondRef = useRef(pixelsPerSecond);
  const waveformDurationRef = useRef(waveformDuration);
  const currentTimeRef = useRef(null);
  const addImageButtonRef = useRef(null);
  
  const [isRendered, setIsRendered] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [addImageTime, setAddImageTime] = useState(null);
  const [isSelectImageModalVisible, setIsSelectImageModalVisible] = useState(false);
  const pixelsPerBar = pixelsPerSecond / samplesPerSecond;
  const projectNodes = (project || {}).nodes || {};

  const nodesTimes = useSelector(selectNodesTimes);
  let durationBetweenNodeTimesAfterExtraNode = Number.MAX_SAFE_INTEGER;
  if (nodesTimes.length > 1) {
    durationBetweenNodeTimesAfterExtraNode = (nodesTimes[nodesTimes.length - 1] - nodesTimes[0]) / (nodesTimes.length + 1);
  }

  function updateProgress(currentTime) {
    if (progressRef.current && (currentTime || currentTime === 0)) {
      const currentTimeCapped = Math.min(waveformDurationRef.current, currentTime);
      progressRef.current.style.transform = `scaleX(${currentTimeCapped * pixelsPerSecondRef.current / 100})`;
      if (!progressRef.current.style.width || progressRef.current.style.width === '0px') {
        progressRef.current.style.width = '100px';
      }
    }
  }

  useEffect(() => {
    pixelsPerSecondRef.current = pixelsPerSecond;

    if (currentTimeRef.current || currentTimeRef.current === 0) {
      updateProgress(currentTimeRef.current);
    }
  }, [pixelsPerSecond]);

  useEffect(() => {
    waveformDurationRef.current = waveformDuration;
  }, [waveformDuration]);

  useEffect(() => {
    const subscription = currentTimeAugmentedSubject.subscribe((currentTime) => {
      currentTimeRef.current = currentTime;
      updateProgress(currentTime);
    });

    return () => {
      subscription.unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!waveformData || !canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;

    let maxWf = Number.MIN_SAFE_INTEGER;

    for (let i = 0, wfLength = waveformData.length; i < wfLength; i += 1) {
      const value = waveformData[i];
      if (value > maxWf) {
        maxWf = value;
      }
    }

    const width = waveformData.length * pixelsPerBar;

    canvas.width = width * 2;
    canvas.height = size.height * 2;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${size.height}px`;

    const ctx = canvas.getContext('2d');
    ctx.scale(2, 2);

    ctx.fillStyle = 'white';
    ctx.beginPath();
    ctx.rect(0, 0, width, size.height);
    ctx.fill();

    ctx.beginPath();
    const maxRadius = Math.min(pixelsPerBar / 2, Math.max(pixelsPerBar / 3, 2));
    let lastDrawnHeight = 0;
    for (let i = 0, wfLength = waveformData.length; i < wfLength; i += 1) {
      const height = (1 - (waveformData[i] / maxWf)) * size.height;
      const previousHeight = i > 0 ? (1 - (waveformData[i - 1] / maxWf)) * size.height : null;
      const nextHeight = i + 1 < waveformData.length ? (1 - (waveformData[i + 1] / maxWf)) * size.height : null;
      const didIncrease = i === 0 || waveformData[i - 1] < waveformData[i];
      const didDecrease = i > 0 && waveformData[i - 1] > waveformData[i];
      const willIncrease = i + 1 < waveformData.length && waveformData[i] < waveformData[i + 1];
      const willDecrease = i + 1 === waveformData.length || waveformData[i] > waveformData[i + 1];

      let drawnHeight = height;
      if (Math.abs(lastDrawnHeight - drawnHeight) < maxRadius / 2) {
        drawnHeight = lastDrawnHeight;
      }
      lastDrawnHeight = drawnHeight;

      ctx.roundRect(
        i * pixelsPerBar,
        drawnHeight,
        pixelsPerBar,
        size.height - drawnHeight,
        [
          didIncrease ? Math.min(maxRadius, Math.abs(drawnHeight - previousHeight) / 2) : 0,
          willDecrease ? Math.min(maxRadius, Math.abs(drawnHeight - nextHeight) / 2) : 0,
          0,
          0,
        ],
      );
      if (didDecrease) {
        ctx.moveTo(i * pixelsPerBar, drawnHeight);
        const radius = Math.min(maxRadius, Math.abs(drawnHeight - previousHeight) / 2);
        ctx.arc(
          (i * pixelsPerBar) + radius,
          drawnHeight - radius,
          radius,
          Math.PI,
          Math.PI / 2,
          true,
        );
      }
      if (willIncrease) {
        ctx.moveTo((i + 1) * pixelsPerBar, drawnHeight);
        const radius = Math.min(maxRadius, Math.abs(drawnHeight - nextHeight) / 2);
        ctx.arc(
          ((i + 1) * pixelsPerBar) - radius,
          drawnHeight - radius,
          radius,
          Math.PI / 2,
          0,
          true,
        );
      }
    }
    ctx.clip();
    ctx.clearRect(0, 0, width, size.height);

    setIsRendered(true);
  }, [canvasRef, waveformData, size, pixelsPerBar]);

  function setCurrentTime(time) {
    setCurrentTimeSubject.next(Math.min(duration, time));
  }

  function onMouseUp() {
    setIsMouseDown(false);
  }

  function addImage(image) {
    if (project && (addImageTime || addImageTime === 0)) {
      const newNodes = {
        ...(projectNodes || {}),
        [addImageTime]: image,
      };
      const nodesTimes = Object.keys(newNodes).map((t) => Number.parseFloat(t));
      nodesTimes.sort((a, b) => a - b);
      const newIndex = nodesTimes.indexOf(addImageTime);

      firestore.collection('projects').doc(project.id).update({
        nodes: fixCustomNodes(newNodes, audioDurationSeconds, newIndex),
        updatedAt: new Date(),
      });
    }
  }

  return (
    <>
      <Wrapper
        onMouseDown={(e) => {
          let offsetX = e.nativeEvent.offsetX;
          const isOnAddImageButton = e.target.tagName === 'BUTTON';
          
          if (isOnAddImageButton && addImageButtonRef.current) {
            offsetX += Number.parseInt(addImageButtonRef.current.style.left) -
              (addImageButtonRef.current.offsetWidth / 2);
          }

          setIsMouseDown(true);
          setCurrentTime(Math.max(0, waveformDuration * (offsetX / size.width)));
        }}
        onMouseMove={(e) => {
          let offsetX = e.nativeEvent.offsetX;
          const isOnAddImageButton = e.target.tagName === 'BUTTON';
          
          if (isOnAddImageButton && addImageButtonRef.current) {
            offsetX += Number.parseInt(addImageButtonRef.current.style.left) -
              (addImageButtonRef.current.offsetWidth / 2);
          }

          if (addImageButtonRef.current) {
            addImageButtonRef.current.style.left = `${offsetX}px`;
          }

          if (!isMouseDown) {
            return;
          }
          setCurrentTime(Math.max(0, waveformDuration * (offsetX / size.width)));
        }}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
        style={{ width: waveformData ? waveformData.length * pixelsPerBar : 0, opacity: isRendered ? 1 : 0 }}
      >
        <canvas ref={canvasRef} />

        <div className="progress" ref={progressRef} />

        {isImageSourceUser && config.minNodesSpacingSd < durationBetweenNodeTimesAfterExtraNode && (
          <AddImageButton
            ref={addImageButtonRef}
            className="add-image-button"
            onClick={(e) => {
              const numNodesAfterAddition = Object.keys(projectNodes || {}).length + 1;
              const averageSecondsBetweenNodesAfterAddition = audioDurationSeconds / (numNodesAfterAddition - 1);
              if (averageSecondsBetweenNodesAfterAddition < MINIMUM_SECONDS_BETWEEN_NODES) {
                // Don't allow too many images to be added
                message.error('You can\'t add more images');
                return;
              }

              let offsetX = e.nativeEvent.offsetX;
              offsetX += Number.parseInt(addImageButtonRef.current.style.left) -
                (addImageButtonRef.current.offsetWidth / 2);
              setAddImageTime(Math.max(0, waveformDuration * (offsetX / size.width)));
              setIsSelectImageModalVisible(true);

              // Otherwise spacebar keyup triggers this click again
              e.target.blur();
            }}
          >
            <Button shape="circle" icon="plus" size="small" />
          </AddImageButton>
        )}
      </Wrapper>

      {isImageSourceUser && config.minNodesSpacingSd < durationBetweenNodeTimesAfterExtraNode && (
        <SelectImageModal
          visible={isSelectImageModalVisible}
          close={() => {
            setIsSelectImageModalVisible(false);
            setAddImageTime(null);
          }}
          onSelect={addImage}
        />
      )}
    </>
  )
}

export default withSize({ monitorWidth: true, monitorHeight: true })(Waveform);
