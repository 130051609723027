import {
  red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta,
  grey,
  generate,
} from '@ant-design/colors';

const brand = generate('#1890ff');
brand.primary = brand[5];

const lightgrey = generate('#b0b0b0');
lightgrey.primary = lightgrey[5];

const text = 'rgba(0, 0, 0, 0.65)';
const success = green[5];
const warning = orange[5];
const danger = red[4];

export {
  brand,
  lightgrey,
  
  // single value
  text,
  success,
  warning,
  danger,

  red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta,
  grey,
}
