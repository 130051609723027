import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom';

export default function () {
  const { projectId } = useParams();
  const projectKey = `projectDetails.${projectId}`;
  useFirestoreConnect([
    { collection: 'projects', doc: projectId, storeAs: projectKey },
  ]);
  const project = useSelector(({ firestore: { data } }) => data[projectKey]);

  if (project) {
    return {
      id: projectId,
      ...project,
    };
  }

  return undefined;
}
