import React from 'react';
import { isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { Route } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import useSelectProject from 'hooks/use-select-project';
import { selectIsProjectImageSourceUser, selectNodesTimes } from 'store/selectors';
import RenderModal from '../RenderModal';
import config from 'config';
// import RenderModalCustomImages from '../RenderModalCustomImages';

export default function ({ setVideoUrl }) {
  const project = useSelectProject();
  const isImageSourceUser = useSelector(selectIsProjectImageSourceUser);
  const nodesTimes = useSelector(selectNodesTimes);
  const history = useHistory();

  if (!isLoaded(project) || !nodesTimes) {
    return null;
  }

  const unFilledNodeTimes = nodesTimes.filter((time) => !(project.nodes || {})[time]);

  let durationBetweenNodeTimes = 0;
  let isDisabled = false;
  let diffImagesToEnable = 0;
  if (isImageSourceUser) {
    durationBetweenNodeTimes = nodesTimes.length > 1 ?
      nodesTimes[1] - nodesTimes[0] :
      Number.MAX_SAFE_INTEGER;
    if (unFilledNodeTimes.length > 0) {
      isDisabled = true;
    } else if (nodesTimes.length > 2) {
      const audioDuration = nodesTimes.length > 0 ? nodesTimes[nodesTimes.length - 1] : 1;
      if (durationBetweenNodeTimes < config.minNodesSpacingSd) {
        const maxNumImages = Math.floor(audioDuration / config.minNodesSpacingSd) + 1;
        diffImagesToEnable = nodesTimes.length - maxNumImages;
        isDisabled = true;
      } else if (durationBetweenNodeTimes > config.maxNodesSpacingSd) {
        const minNumImages = Math.ceil(audioDuration / config.maxNodesSpacingSd) + 1;
        diffImagesToEnable = minNumImages - nodesTimes.length;
        isDisabled = true;
      }
    }
  } else {
    isDisabled = unFilledNodeTimes.length > 0;
  }

  return (
    <>
      {isDisabled ? (
        <Tooltip
          title={unFilledNodeTimes.length > 0 ?
            'Please select a start and end image' :
            (durationBetweenNodeTimes < config.minNodesSpacingSd ?
              `Please remove ${diffImagesToEnable} image${diffImagesToEnable > 1 ? 's' : ''} from the timeline` :
              `Please add ${diffImagesToEnable} more image${diffImagesToEnable > 1 ? 's' : ''} to the timeline`)}
          placement="topLeft"
        >
          <Button type="primary" disabled>
            Render video
          </Button>
        </Tooltip>
      ) : (
        <Link to={`/project/${project.id}/timeline/start-render`}>
          <Button type="primary">
            Render video
          </Button>
        </Link>
      )}

      <Route path="/project/:projectId/timeline/start-render">
        {/* {isImageSourceUser ? (
          <RenderModalCustomImages
            close={() => history.push(`/project/${project.id}/timeline`)}
          />
        ) : (
          <RenderModal
            close={() => history.push(`/project/${project.id}/timeline`)}
            setVideoUrl={setVideoUrl}
          />
        )} */}
        <RenderModal
          close={() => history.push(`/project/${project.id}/timeline`)}
          setVideoUrl={setVideoUrl}
        />
      </Route>
    </>
  );
}
