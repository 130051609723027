import { getFirebase } from 'react-redux-firebase';

export default function (options) {
  const firebase = getFirebase();

  const getRenderLogsFunction = firebase.functions().httpsCallable('getRenderLogs');

  return getRenderLogsFunction(options)
    .then(({ data }) => {
      return data;
    });
}
