import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

import FirebaseImage from 'components/FirebaseImage';
import FirebaseImageWithEnlarge from 'components/FirebaseImageWithEnlarge';
import config from 'config';
import { brand, lightgrey } from 'colors';
import { KEY_FRAME_TO_INTER_FRAME_DIMENSION, NUMBER_OF_INTER_FRAMES, NUMBER_OF_SEEDS, SEED_BACKGROUND_PADDING, SPACING_BETWEEN_IMAGES, SPACING_BETWEEN_ROWS } from './config';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;

  > div > div {
    margin-top: ${SPACING_BETWEEN_ROWS}px;

    > div {
      display: inline-block;
    }
  }

  .firebase-img {
    border-radius: 3px;
  }
`;

const Seed = styled.div`
  cursor: pointer;
  background: ${lightgrey[0]};
  box-shadow: 0 0 0 ${SEED_BACKGROUND_PADDING}px ${lightgrey[0]};
  transition: background 0.3s, box-shadow 0.3s, transform 0.2s;
  border-radius: 3px;

  &:hover {
    transform: scale(1.03);
    background: ${brand[3]};
    box-shadow: 0 0 0 ${SEED_BACKGROUND_PADDING}px ${brand[3]};

    .anticon {
      color: ${brand[7]};
    }
  }
`;

const Frame = styled.div`
  display: flex;
  align-items: center;
`;

const NextIcon = styled(({ double, ...rest }) => <div {...rest} />)`
  width: ${({ double }) => double ? 2 * SPACING_BETWEEN_IMAGES : SPACING_BETWEEN_IMAGES}px;
  transform: scale(${({ double }) => double ? 1.5 : 1});
  text-align: center;
  color: rgba(0,0,0, 0.3);

  .anticon {
    transition: color 0.3s;
  }
`;

export default function ({
  project,
  auth,
  imageDimension,
  projectNodesImages,
  transitionIndex,
  widthRowWithoutLastImage,
  enlargeImage,
}) {
  return (
    <Wrapper
      key={`t${transitionIndex}`}
      style={{
        width: widthRowWithoutLastImage,
        left: widthRowWithoutLastImage * transitionIndex,
      }}
    >
      <Frame>
        <FirebaseImage
          key={`t${transitionIndex}.0.0`}
          path={`${auth.uid}/${project.id}/${projectNodesImages[transitionIndex]}`}
          bucket={config.customImagesBucket}
          width={imageDimension * KEY_FRAME_TO_INTER_FRAME_DIMENSION}
          height={imageDimension * KEY_FRAME_TO_INTER_FRAME_DIMENSION}
          animated={false}
          lazyload={false}
        />

        <NextIcon double style={{ marginRight: SEED_BACKGROUND_PADDING }}>
          <Icon type="caret-right" />
        </NextIcon>
      </Frame>

      <div>
        {[...Array(NUMBER_OF_SEEDS).keys()].map((_, index) => (
          <Seed
            key={`t${transitionIndex}.${index}`}
            style={{ height: imageDimension }}
          >
            {[...Array(NUMBER_OF_INTER_FRAMES).keys()].map((_, index2) => {
              const path = `${auth.uid}/${project.id}/${projectNodesImages[transitionIndex]}`;
              return (
                <div key={`t${transitionIndex}.${index}.${index2}.wrap`}>
                  <Frame>
                    <FirebaseImageWithEnlarge
                      key={`t${transitionIndex}.${index}.${index2}`}
                      path={path}
                      bucket={config.customImagesBucket}
                      width={imageDimension}
                      height={imageDimension}
                      animated={false}
                      lazyload={false}
                    />

                    {index2 < NUMBER_OF_INTER_FRAMES - 1 && (
                      <NextIcon>
                        <Icon type="caret-right" />
                      </NextIcon>
                    )}
                  </Frame>
                </div>
              );
            })}
          </Seed>
        ))}
      </div>

      <NextIcon style={{ marginTop: SPACING_BETWEEN_ROWS, marginLeft: SEED_BACKGROUND_PADDING }} double>
        <Icon type="caret-right" />
      </NextIcon>
    </Wrapper>
  )
}