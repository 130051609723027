import React, { useState } from 'react';
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { useFirestoreConnect } from 'react-redux-firebase'
import { Modal, message } from 'antd';
import { isMobile } from 'react-device-detect';

import GoogleAnalytics from 'utils/google-analytics';
import useSelectAuth from 'hooks/use-select-auth';
import useSelectIsAdmin from 'hooks/use-select-is-admin';
import NewProjectForm from './NewProjectForm';


export default function () {
  const auth = useSelectAuth();
  const isAdmin = useSelectIsAdmin();
  const firestore = useFirestore();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  // useFirestoreConnect([{ collection: 'models', where: [user.isAdmin ? 'isActive' : 'isPublic', '==', true] }]);
  useFirestoreConnect([{ collection: 'models', where: ['isPublic', '==', true] }]);
  const models = useSelector((state) => state.firestore.ordered.models);

  const uploadProgress = useSelector((state) => state.uploadProgress);

  function addProject(project) {
    setSubmitting(true);

    const { audioFilePath, audioDuration, ...projectData } = project;
    projectData.audio = {
      filePath: audioFilePath,
      duration: audioDuration,
    };

    firestore.collection('projects').add({
      ...projectData,
      updatedAt: new Date(),
      userId: auth.uid,
    }).then(({ id: projectId }) => {
      setSubmitting(false);

      GoogleAnalytics.event({
        category: 'project',
        action: 'create-project'
      });

      history.push(`/project/${projectId}`);

      if (isMobile) {
        Modal.info({
          title: 'WZRD is not optimized for mobile devices',
          content: <span style={{ textAlign: 'justify' }}>WZRD{'\''}s advanced edit functionalities make it difficult to use on a mobile device. Please switch to a laptop or desktop PC for a better experience.</span>,
        });
      }

    }).catch((err) => {
      setSubmitting(false);
      console.error(err);
      message.error('Something went wrong');
    });
  }

  return (
    <NewProjectForm
      onSubmit={addProject}
      submitting={submitting}
      models={models}
      userId={auth.uid}
      uploadProgress={uploadProgress}
      isAdmin={isAdmin}
    />
  );
}
