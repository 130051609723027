import { call, put, takeEvery, select } from 'redux-saga/effects'
import { get as getHttp } from 'axios';
import getDownloadURL, { clearDownloadURLCache } from 'utils/get-download-url';
import stripPathExtension from 'utils/strip-path-extension';
import { selectProjectId } from './selectors';
import audioPathToAac from 'utils/audio-path-to-aac';
import config from 'config';

function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function* fetchWaveform(action) {
  const projectId = action.projectId;
  const project = yield select(({ firestore: { data } }) => data[`projectDetails.${projectId}`]);

  if (!project) {
    return;
  }

  let url = null;
  let tries = 0;
  let lastError = null;

  const bucketName = 'wzrd-3585d-waveforms';
  const path = `${stripPathExtension(project.audio.filePath)}.json`;

  while (url === null && tries < 20) {
    try {
      url = yield call(getDownloadURL, bucketName, path);
      break;
    } catch (error) {
      lastError = error;
    }

    yield call(wait, 5000);
    tries += 1;
  }

  if (!url) {
    yield put({ type: 'FETCH_WAVEFORM_FAILED', projectId, error: lastError });
    return;
  }

  try {
    const { data } = yield call(getHttp, url);
    yield put({ type: 'FETCH_WAVEFORM_SUCCEEDED', projectId, data });

  } catch (error) {
    clearDownloadURLCache(bucketName, path);
    yield put({ type: 'FETCH_WAVEFORM_FAILED', projectId, error });
  }
}

function* fetchWaveformIfNeeded() {
  const projectId = yield select(selectProjectId);

  const isNeeded = yield select(({ project: { [projectId]: projectDetails } }) =>
    !projectDetails ||
    (!projectDetails.fetched.waveform && !projectDetails.fetching.waveform)
  );

  if (isNeeded) {
    yield put({ type: 'FETCH_WAVEFORM_REQUESTED', projectId });
  }
}

function* fetchNodeSpacing(action) {
  const projectId = action.projectId;
  const project = yield select(({ firestore: { data } }) => data[`projectDetails.${projectId}`]);

  if (!project) {
    return;
  }

  let url = null;
  let tries = 0;
  let lastError = null;
  const bucketName = 'wzrd-3585d-audio-feature-extraction';
  const path = `${stripPathExtension(project.audio.filePath)}_node_spacing.json`;

  while (url === null && tries < 20) {
    try {
      url = yield call(getDownloadURL, bucketName, path);
      break;
    } catch (error) {
      lastError = error;
    }

    yield call(wait, 5000);
    tries += 1;
  }

  if (!url) {
    yield put({ type: 'FETCH_NODE_SPACING_FAILED', projectId, error: lastError });
    return;
  }

  try {
    const { data } = yield call(getHttp, url);
    yield put({ type: 'FETCH_NODE_SPACING_SUCCEEDED', projectId, data });

  } catch (error) {
    clearDownloadURLCache(bucketName, path);
    yield put({ type: 'FETCH_NODE_SPACING_FAILED', projectId, error });
  }
}

function* fetchNodeSpacingIfNeeded() {
  const projectId = yield select(selectProjectId);

  const isNeeded = yield select(({ project: { [projectId]: projectDetails } }) =>
    !projectDetails ||
    (!projectDetails.fetched.nodeSpacing && !projectDetails.fetching.nodeSpacing)
  );

  if (isNeeded) {
    yield put({ type: 'FETCH_NODE_SPACING_REQUESTED', projectId });
  }
}

function* fetchAudioUrl(action) {
  const projectId = action.projectId;
  const project = yield select(({ firestore: { data } }) => data[`projectDetails.${projectId}`]);

  if (!project) {
    return;
  }

  try {
    const aacFilePath = audioPathToAac(project.audio.filePath);
    const url = yield call(getDownloadURL, config.audioReencodedBucket, aacFilePath);
    yield put({ type: 'FETCH_AUDIO_URL_SUCCEEDED', projectId, url });

  } catch (error) {
    yield put({ type: 'FETCH_AUDIO_URL_FAILED', projectId, error });
  }
}

function* fetchAudioUrlIfNeeded() {
  const projectId = yield select(selectProjectId);

  const isNeeded = yield select(({ project: { [projectId]: projectDetails } }) =>
    !projectDetails ||
    (!projectDetails.fetched.audioUrl && !projectDetails.fetching.audioUrl)
  );

  if (isNeeded) {
    yield put({ type: 'FETCH_AUDIO_URL_REQUESTED', projectId });
  }
}

export default function* () {
  yield takeEvery('FETCH_WAVEFORM_REQUESTED', fetchWaveform);
  yield takeEvery('FETCH_WAVEFORM_IF_NEEDED_REQUESTED', fetchWaveformIfNeeded);
  yield takeEvery('FETCH_NODE_SPACING_REQUESTED', fetchNodeSpacing);
  yield takeEvery('FETCH_NODE_SPACING_IF_NEEDED_REQUESTED', fetchNodeSpacingIfNeeded);
  yield takeEvery('FETCH_AUDIO_URL_REQUESTED', fetchAudioUrl);
  yield takeEvery('FETCH_AUDIO_URL_IF_NEEDED_REQUESTED', fetchAudioUrlIfNeeded);
}
