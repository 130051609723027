import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, Dropdown, Menu } from 'antd';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

import config from 'config';
import { selectIsProjectImageSourceUser } from 'store/selectors';
import DraggableFirebaseImage from 'components/DraggableFirebaseImage';

const StyledDraggableFirebaseImage = styled(DraggableFirebaseImage)`
  object-fit: cover;
`;

export default function ({
  time,
  index,
  setSelectedTime,
  setIsSelectImageModalVisible,
  clear,
  swap,
  project,
  pixelsPerSecond,
  auth,
}) {
  const isImageSourceUser = useSelector(selectIsProjectImageSourceUser);

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: 'FirebaseImage',
    drop: (item) => swap(item.time, time),
    canDrop: (item) => item.time !== time,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  function openSelectImageModal() {
    setSelectedTime(time);
    setIsSelectImageModalVisible(true);
  }

  return (
    <Dropdown
      key={index}
      placement="topLeft"
      overlay={(
        <Menu>
          <Menu.Item disabled>
            Drag & drop to move
          </Menu.Item>

          <Menu.Item onClick={openSelectImageModal}>
            Select {isImageSourceUser ? 'image' : 'favorite'}
          </Menu.Item>

          <Menu.Item
            onClick={() => clear(time)}
            disabled={!(project && project.nodes && project.nodes[time])}
          >
            Clear
          </Menu.Item>
        </Menu>
      )}
      trigger={['hover']}
    >
      <div
        ref={dropRef}
        className={`${isOver && canDrop ? 'selected' : ''} ${project && project.nodes && project.nodes[time] ? 'grabbable' : ''}`}
        style={{ left: time * pixelsPerSecond }}
        onClick={openSelectImageModal}
      >
        {project && project.nodes && project.nodes[time] ? (
          <StyledDraggableFirebaseImage
            path={`${auth.uid}/${project.id}/${project.nodes[time]}${isImageSourceUser ? '' : '.jpg'}`}
            bucket={isImageSourceUser ? config.customImagesBucket : config.samplesBucket}
            width={80}
            height={80}
            animated={false}
            lazyload={false}
            dragProps={{ time }}
          />
        ) : (
          <Icon type="picture" theme="filled" />
        )}
      </div>
    </Dropdown>
  );
}
