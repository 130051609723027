import { Button } from 'antd';
import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { message } from 'antd';
import CenteredBlock from './CenteredBlock';

export default function () {
  const firebase = useFirebase();

  function sendEmail() {
    const user = firebase.auth().currentUser;

    user.sendEmailVerification()
      .then(() => {
        message.success('Email sent!');
      }).catch((error) => {
        if (error.message) {
          message.error(error.message);
        }
        console.error(error);
      });
  }

  return (
    <CenteredBlock>
      <h2>Verify your email address</h2>

      <p>Please verify your email address using the email we sent you. Please check your spam folder if needed.</p>

      <Button
        type="link"
        onClick={sendEmail}
      >
        <small>Re-send verification email</small>
      </Button>
    </CenteredBlock>
  );
}
