import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

import { getDownloadURLCached } from 'utils/get-download-url';
import config from 'config';
import FirebaseImage from './FirebaseImage';
import EnlargeImageModal from './EnlargeImageModal';

const ImageWrapper = styled.div`
  position: relative;
  user-select: none;
  cursor: pointer;

  &:after {
    content: 'x';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    background: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .anticon {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, 0.85) !important;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    font-size: 16px;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
    .anticon {
      opacity: 1;
    }
  }
`;

export default function (props) {
  const [isEnlargeImageVisible, setIsEnlargeImageModalVisible] = useState(false);

  const showModal = useCallback(() => {
    setIsEnlargeImageModalVisible(true);
  }, []);
  const hideModal = useCallback(() => {
    setIsEnlargeImageModalVisible(false);
  }, []);

  return (
    <>
      <ImageWrapper onClick={showModal}>
        <FirebaseImage {...props} />

        <Icon type="eye" />
      </ImageWrapper>

      <EnlargeImageModal
        src={getDownloadURLCached(config.customImagesBucket, props.path)}
        visible={isEnlargeImageVisible}
        onClose={hideModal}
      />
    </>
  );
}
