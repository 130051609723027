import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionTypes } from 'redux-firestore';
import { isLoaded, isEmpty, useFirebase } from 'react-redux-firebase';
import { useHistory, Link } from 'react-router-dom';
import { Menu, Icon, message } from 'antd';
import Avatar from 'components/Avatar';
import useSelectAuth from 'hooks/use-select-auth';
import useSelectRenderWallet from 'hooks/wallet/use-select-render-wallet';

export default function ({ children, ...rest }) {
  const dispatch = useDispatch();
  const auth = useSelectAuth();
  const user = useSelector(({ firestore: { data } }) => data.userDetails);
  const firebase = useFirebase();
  const history = useHistory();
  const wallet = useSelectRenderWallet();

  if (isEmpty(auth)) {
    return (
      <Menu
        theme="dark"
        mode="horizontal"
        style={{ lineHeight: '64px' }}
        selectedKeys={[]}
      >
        <Menu.Item key="login" disabled={!isLoaded(auth)}>
          <Link to="/login">LOG IN</Link>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      selectedKeys={[]}
      style={{ lineHeight: '64px' }}
    >
      <Menu.SubMenu
        key="account"
        title={
          <div style={{ marginRight: -20 }}>
            <Avatar user={{ ...auth, ...(user || {}) }} style={{ marginRight: 10, marginTop: -6 }} />
            <Icon type="caret-down" />
          </div>
        }
      >
        <Menu.Item
          key="user"
          disabled
          style={{ marginTop: 10 }}
        >
          <Avatar user={{ ...auth, ...(user || {}) }} style={{ marginRight: 10, marginTop: -3 }} />
          <span>{auth.displayName || (user || {}).displayName}</span>
        </Menu.Item>

        <Menu.Item
          key="dashboard"
          onClick={() => history.push('/')}
        >
          <Icon type="appstore" theme="filled" />
          Projects
        </Menu.Item>

        <Menu.Item
          key="render-wallet"
          onClick={() => history.push('/account/render-wallet')}
          style={{ height: 'auto', lineHeight: '1.2em' }}
        >
          <Icon type="wallet" theme="filled" />
          Account<br />
          <small className="mono">{((wallet || {}).balance || 0).toFixed(2)} credits</small>
        </Menu.Item>

        <Menu.Item
          key="help"
          onClick={() => {
            history.push('/intro');
          }}
        >
          <Icon type="question-circle" theme="filled" />
          Help
        </Menu.Item>

        <Menu.Item
          key="contact"
          onClick={() => {
            window.location.href = 'https://wzrd.ai/contact/';
          }}
        >
          <Icon type="message" theme="filled" />
          Get in touch
        </Menu.Item>

        <Menu.Item
          key="logout"
          onClick={() => {
            firebase.logout();
            message.success('Logged out!');
            history.push('/');
            dispatch({ type: actionTypes.CLEAR_DATA });
          }}
        >
          <Icon type="logout" />
          Log out
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
}
