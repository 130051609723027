import calculateNumberOfNodes from './calculate-number-of-nodes';

export default function ({ audioDurationSeconds, numberOfNodesPerMinute, nodeSpacing, overRes = 1 }) {
  if (!audioDurationSeconds || !numberOfNodesPerMinute || !nodeSpacing) {
    return [];
  }

  const nodesTimes = [0];
  // round??
  const numberOfNodesWithoutFirst = calculateNumberOfNodes(audioDurationSeconds, numberOfNodesPerMinute, overRes) - 1;;

  let totalEnergy = 0;
  for (let i = 0, nodeSpacingLength = nodeSpacing.length; i < nodeSpacingLength; i += 1) {
    totalEnergy += nodeSpacing[i];
  }

  const energyNeededPerNode = totalEnergy / numberOfNodesWithoutFirst;
  const secondsPerEnergyPackage = audioDurationSeconds / nodeSpacing.length;

  let index = 0;
  let indexFrac = 0;
  let energyLeftAtIndex = nodeSpacing[0];
  let fracLeftAtIndex = 1;

  for (let i = 0; i < numberOfNodesWithoutFirst; i += 1) {
    let energyNeeded = energyNeededPerNode;

    while (energyNeeded > 0) {
      if (energyNeeded > energyLeftAtIndex + 0.0001) { // Fix round errors? Missing node at end of file
        energyNeeded -= energyLeftAtIndex;
        energyLeftAtIndex = 0;
        indexFrac += fracLeftAtIndex;
        fracLeftAtIndex = 0;

      } else {
        const takeFractionOfPackage = energyNeeded / energyLeftAtIndex;
        indexFrac += takeFractionOfPackage;
        fracLeftAtIndex -= takeFractionOfPackage;
        nodesTimes.push(Math.round(indexFrac * secondsPerEnergyPackage * 1000) / 1000);

        energyLeftAtIndex -= energyNeeded;
        energyNeeded = 0;
      }

      if (energyLeftAtIndex <= 0) {
        if ((index + 1) < nodeSpacing.length) {
          index += 1;
          energyLeftAtIndex = nodeSpacing[index];
          fracLeftAtIndex = 1;
        } else {
          break;
        }
      }
    }
  }

  return nodesTimes;
}