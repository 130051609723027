import React from 'react';
import styled from 'styled-components';

const Placeholder = styled.div`
  display: inline-block;
  background: ${({ color }) => color || '#f2f2f2'};
`;

export default function ({ width, height, ...rest }) {
  return (
    <Placeholder style={{ width, height }} {...rest} />
  );
}
