import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';


class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isSuccess: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isSubmitting: true });

        this.props.firebase.auth().sendPasswordResetEmail(values.email)
          .then(() => {
            this.setState({ isSubmitting: false, isSuccess: true });
          }).catch((error) => {
            this.setState({ isSubmitting: false });
            if (error.message) {
              message.error(error.message);
            }
            console.error(error);
          });
      }
    });
  };

  render() {
    if (this.state.isSuccess) {
      return (
        <p>An email has been sent to you to reset your password. Please check your spam folder if needed.</p>
      );
    }

    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Please input your email' },
              {
                type: 'email',
                message: 'This is not a valid email address',
              },
            ],
          })(
            <Input
              placeholder="Email"
              size="large"
              disabled={this.state.isSubmitting}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={this.state.isSubmitting}
          >
            Send reset email
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default compose(
  withFirebase,
  Form.create({ name: 'forgot-password' })
)(SignupForm);
