import React from 'react';
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { Route, Redirect } from 'react-router';

import useSelectAuth from 'hooks/use-select-auth';
import useSelectUser from 'hooks/use-select-user';
import DelayedSpin from './DelayedSpin';
import TermsAndNewsletter from './TermsAndNewsletter';
import VerifyEmail from './VerifyEmail';

export default function ({ children, ...rest }) {
  const auth = useSelectAuth();
  const user = useSelectUser();

  if (!isLoaded(auth)) {
    return <DelayedSpin />;
  }

  if (isEmpty(auth)) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{ pathname: "/login", state: { from: location } }}
          />
        )}
      />
    );
  }

  if (!user) {
    return <DelayedSpin />;
  }

  if (!user.hasAcceptedTerms || !user.hasGivenNewsletterPreference) {
    return (
      <TermsAndNewsletter />
    );
  }

  if (auth.emailVerified === false) {
    return (
      <VerifyEmail />
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) => !isEmpty(auth) ? children : (
        <Redirect
          to={{ pathname: "/login", state: { from: location } }}
        />
      )}
    />
  );
}
