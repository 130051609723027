import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux'
import styled from 'styled-components';

import { selectPlaybackDuration } from 'store/selectors';
import { currentTimeSubject } from 'store/current-time-subject';

const StyledDiv = styled.div`
  font-size: 12px;
  margin: 0 10px;
  
  &:first-of-type {
    margin-left: 20px;
  }

  &:last-of-type {
    margin-right: 30px;
  }
`;

export default function ({ from = false, to = false }) {
  const elRef = useRef();
  const duration = useSelector(selectPlaybackDuration);

  useEffect(() => {
    const subscription = currentTimeSubject.subscribe((currentTime) => {
      if (!elRef.current) {
        return;
      }

      if (from) {
        elRef.current.innerHTML = `${Math.floor(currentTime / 60)}:${Math.ceil(currentTime % 60).toString().padStart(2, '0')}`;
      } else {
        elRef.current.innerHTML = `${Math.floor((duration || 0) / 60)}:${Math.ceil((duration || 0) % 60).toString().padStart(2, '0')}`;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  return (
    <StyledDiv ref={elRef} />
  );
}
