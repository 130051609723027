import React, { useState, useEffect, useRef } from 'react';

import { Button, message, notification } from 'antd';

import getRenderDownloadURL from 'utils/get-render-download-url';
import useSelectProject from 'hooks/use-select-project';
import SpanHideSm from 'components/SpanHideSm';


export default function ({ style, renderId }) {
  const project = useSelectProject();

  const [isLoading, setIsLoading] = useState(false);
  const isUnmounted = useRef(false);

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    }
  }, [])

  return (
    <Button
      shape="round"
      icon="download"
      style={style}
      onClick={() => {
        if (!project || !project.id || !renderId) {
          return;
        }

        setIsLoading(true);

        getRenderDownloadURL({ projectId: project.id, renderId })
          .then((url) => {
            if (isUnmounted.current) {
              return;
            }

            setIsLoading(false);
            notification.success({
              message: 'Download ready',
              description: (
                <>
                  Click on the button below to open your video.<br /><br />
                  
                  <i>If the video plays instead of downloads, right-click on the video and hit "Save Video As...".</i><br/><br/>

                  <Button type="primary">
                    <a
                      href={url}
                      download={`${project.name}.mp4`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download video
                    </a>
                  </Button>
                </>
              ),
            })

          })
          .catch((err) => {
            if (isUnmounted.current) {
              return;
            }

            setIsLoading(false);
            message.error('Something went wrong');

            console.error(err);
          });
      }}
      loading={isLoading}
    >
      <SpanHideSm>Download</SpanHideSm>
    </Button>
  );
}
