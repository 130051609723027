export function validate(audioDurationSeconds, isStart, value) {  
  if (!value) {
    return null;
  }

  const numOfColons = (value.match(/:/g) || []).length;
  if (numOfColons !== 1) {
    return 'This value is invalid';
  }

  const [minutesStr, secondsRemainingStr] = value.split(':');

  if (minutesStr.length === 0 || secondsRemainingStr.length !== 2) {
    return 'This value is invalid';
  }

  const minutes = Number.parseInt(minutesStr);

  if (Number.isNaN(minutes) || minutes < 0) {
    return 'This value is invalid';
  }

  const secondsRemaining = Number.parseInt(secondsRemainingStr);

  if (Number.isNaN(secondsRemaining) || secondsRemaining < 0 || secondsRemaining > 59) {
    return 'This value is invalid';
  }

  const seconds = (minutes * 60) + secondsRemaining;

  if (isStart && seconds >= audioDurationSeconds) {
    return 'This start time is too late';
  }

  if (!isStart && seconds > audioDurationSeconds) {
    return 'This end time is too late';
  }

  return null;
}

export function validateRule({ audioDurationSeconds, isStart }, value, callback) {  
  const result = validate(audioDurationSeconds, isStart, value);

  if (result) {
    return callback(result)
  }

  callback();
}

export function parse(audioDurationSeconds, str) {
  if (!str) {
    return 0;
  }

  if (validate(audioDurationSeconds, str)) {
    return 0;
  }

  const [minutesStr, secondsRemainingStr] = str.split(':');
  const minutes = Number.parseInt(minutesStr);
  const secondsRemaining = Number.parseInt(secondsRemainingStr);
  return (minutes * 60) + secondsRemaining;
}

export function stringify(seconds) {
  if (!seconds) {
    return '';
  }

  const minutes = Math.floor(seconds / 60);
  const secondsRemaining = Math.round(seconds % 60);

  return `${minutes}:${`${secondsRemaining}`.padStart(2, '0')}`;
}
