import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import Vimeo from './Vimeo';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding-top: 35px;
    overflow: hidden;
  }

  .ant-modal-body {
    margin: -24px -24px 0 -24px;
    padding-bottom: 0;
  }

  .ant-modal-close-x {
    margin-top: -11px;
  }
`;

export default function VimeoModal({ visible, onCancel, videoId, style, wrapped = true }) {
  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      title={null}
      footer={null}
      destroyOnClose
    >
      <Vimeo videoId={videoId} style={style} />
    </StyledModal>
  )
}
