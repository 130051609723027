import styled from 'styled-components';
import { Spin } from 'antd';

export default styled(Spin)`
  animation: delayedspin 0.66s;
  position: relative;

  @keyframes delayedspin {
    0% { opacity: 0; }
    66% { opacity: 0; }
    100% { opacity: 1; }
  }

  &:after {
    content: '${({ text }) => text || ''}';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 110%;
    margin-top: 5px;
  }
`;
