import ReactGA from 'react-ga4';
import parseCookies from 'utils/parse-cookies';

let wrapper = {
  initialize: () => {},
  event: () => {},
  enable: () => {},
  disable: () => {},
  set: () => {},
};

if (process.env.REACT_APP_GOOGLE_ANALYTICS_4) {
  let innerInitialized = false;
  const cookies = parseCookies();
  let isEnabled = (cookies.consent || {}).analytics || false;
  let history = null;
  let dimensionsSet = null;

  function innerInitialize() {
    innerInitialized = true;
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_4);

    if (dimensionsSet !== null) {
      ReactGA.set(dimensionsSet);
    }
  }

  function initialize(hist) {
    if (hist) {
      history = hist;
    }

    if (innerInitialized) {
      return;
    }

    history.listen((location) => {
      if (!isEnabled) {
        return;
      }

      if (!innerInitialized) {
        innerInitialize();
      }

      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    });

    if (!isEnabled) {
      return;
    }

    innerInitialize();
    ReactGA.send({ hitType: 'pageview', page: history.location.pathname });
  }

  wrapper = {
    initialize,
    event: (e) => {
      if (!isEnabled) {
        return;
      }

      if (!innerInitialized) {
        innerInitialize();
      }

      ReactGA.event(e);
    },
    enable: () => {
      isEnabled = true;
      if (!innerInitialized) {
        initialize();
      }
    },
    disable: () => {
      isEnabled = false;
    },
    set: (dimensions) => {
      dimensionsSet = { ...(dimensionsSet || {}), ...dimensions };

      if (!isEnabled) {
        return;
      }

      if (!innerInitialized) {
        innerInitialize();
      } else {
        ReactGA.set(dimensionsSet);
      }
    },
  };
}

export default wrapper;
