import React, { useState } from 'react';

import { Typography, Table, Badge, Checkbox, Tag } from 'antd';
import { isLoaded } from 'react-redux-firebase';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';

import useSelectRenders from 'hooks/use-select-renders';
import useSelectIsAdmin from 'hooks/use-select-is-admin';
import WatchButton from './WatchButton';
import DownloadButton from './DownloadButton';
import LogsButton from './LogsButton';

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18.5px;

  h2 {
    margin: 0 25px 0 0;
  }

  .ant-checkbox-wrapper {
    padding-top: 10px;
  }
`;

const StyledTable = styled(Table)`
  .ant-badge {
    white-space: nowrap;
  }

  .actions {
    line-height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

export default function () {
  const location = useLocation();
  const history = useHistory();
  const [showPreviews, setShowPreviews] = useState(!location.search.includes('showPreviews=0'));
  const renders = useSelectRenders();
  const isAdmin = useSelectIsAdmin();

  let filteredRenders = renders || [];
  if (!showPreviews) {
    filteredRenders = filteredRenders.filter((render) => render.isPreview !== true);
  }
  const hasRenders = filteredRenders.length > 0;

  filteredRenders.sort((a, b) => {
    const aCreatedAtDate = a.createdAt.toDate ? a.createdAt.toDate() : a.createdAt;
    const bCreatedAtDate = b.createdAt.toDate ? b.createdAt.toDate() : b.createdAt;
    return bCreatedAtDate - aCreatedAtDate;
  });

  function setShowPreviewsAndUpdateUrl(value) {
    setShowPreviews(value);
    history.push({
      pathname: location.pathname,
      ...(value ? { search: '?showPreviews=1' } : {}),
    })
  }

  return (
    <>
      <Heading>
        <Typography>
          <Typography.Title level={2}>
            Renders
          </Typography.Title>
        </Typography>
        <Checkbox
          checked={showPreviews}
          onChange={(e) => setShowPreviewsAndUpdateUrl(e.target.checked)}
        >
          Show previews
        </Checkbox>
      </Heading>

      <StyledTable
        dataSource={filteredRenders}
        loading={!isLoaded(renders)}
        locale={{ emptyText: 'No renders yet' }}
        columns={[
          {
            key: 'createdAt',
            title: 'Render',
            dataIndex: 'createdAt',
            render: (createdAt, render) => {
              if (!createdAt) {
                return null;
              }
              
              const createdAtDate = createdAt.toDate ? createdAt.toDate() : createdAt;
              
              const createdAtString = createdAtDate.toLocaleString([], { day: 'numeric', year: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit' });

              return (
                <span>
                  {createdAtString}&nbsp;&nbsp;
                  <Tag color={render.isPreview !== true ? 'purple' : 'magenta'}>{render.isPreview !== true ? 'FULL-LENGTH' : 'PREVIEW'}</Tag>
                </span>
              );
            },
          },
          {
            key: 'status',
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
              switch (status) {
                case 'ERROR':
                  return <Badge status="error" text="Error" />;
                  
                case 'SUCCESS':
                  return <Badge status="success" text="Success" />;

                default:
                  return <Badge status="processing" text="Processing" />;
              }
            },
          },
          {
            key: 'actions',
            title: 'Actions',
            className: 'actions',
            render: (render) => {
              return (
                <>
                  {render.status === 'SUCCESS' && <WatchButton style={{ marginRight: 10 }} renderId={render.id} />}

                  {render.status === 'SUCCESS' && <DownloadButton style={isAdmin ? { marginRight: 10 } : {}} renderId={render.id} />}

                  {isAdmin && <LogsButton renderId={render.id} /> }

                  {/* <Button
                    shape="round"
                    icon="share-alt"
                    onClick={() => setIsShareModalVisible(true)}
                  >
                    Share
                  </Button> */}
                </>
              );
            },
          },
        ]}
        pagination={false}
        rowKey="id"
        footer={hasRenders ?
          () => <small>Note: all previews are removed after 30 days</small> :
          null
        }
        showHeader={hasRenders}
      />

      {/* <ShareRenderModal
        visible={isShareModalVisible}
        close={() => setIsShareModalVisible(false)}
      /> */}
    </>
  );
}
