import React, { useEffect, useState } from 'react';
import { Button, Icon, Typography, Statistic, Table, Tag, /* Tooltip */ } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import useSelectRenderWalletBalance from 'hooks/wallet/use-select-render-wallet-balance';
import useSelectRenderWalletHistory from 'hooks/wallet/use-select-render-wallet-history';
import useSelectAuth from 'hooks/use-select-auth';
import { /* gold, */ success, danger } from 'colors';
import HelpTooltip from 'components/HelpTooltip';
import TopUpModal from './TopUpModal';
import DeleteAccountModal from './DeleteAccountModal';

// const ProButton = styled(Button)`
//   background-color: ${gold[5]};
//   border-color: ${gold[5]};

//   &:hover {
//     background-color: ${gold[4]};
//     border-color: ${gold[4]};
//   }

//   &:active {
//     background-color: ${gold[6]};
//     border-color: ${gold[6]};
//   }
// `;

const StyledTable = styled(Table)`
  max-width: 800px;

  .monospace {
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  }

  th.ant-table-align-right:not(:last-child) {
    text-align: center !important;
  }
`;

const StyledStatistic = styled(Statistic)`
  .ant-statistic-content {
    transform: scale(1.5);
    transform-origin: center left;
  }
`;

export default function () {
  const Paddle = window.Paddle;
  const walletBalance = useSelectRenderWalletBalance();
  const walletHistory = useSelectRenderWalletHistory();
  const auth = useSelectAuth();
  const user = useSelector(({ firestore: { data } }) => data.userDetails);
  const [grossPriceDisplay, setGrossPriceDisplay] = useState(null);
  const [isTopUpModalVisible, setIsTopUpModalVisible] = useState(false);
  const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] = useState(false);

  useEffect(() => {
    Paddle.Product.Prices(660875, (data) => {
      setGrossPriceDisplay(((data || {}).price || {}).gross || null);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopUpModal
        visible={isTopUpModalVisible}
        close={() => setIsTopUpModalVisible(false)}
      />

      {/* {isLoaded(wallets) && !isEmpty(wallets) && ( */}
      <Typography>
        <Typography.Title level={2}>
          Account
        </Typography.Title>
      </Typography>

      <Typography.Paragraph>This page shows the account details of {auth.displayName || (user || {}).displayName} ({auth.email || (user || {}).email}).</Typography.Paragraph>

      <Typography>
        <Typography.Title level={4} style={{ marginTop: 30 }}>
          Render Wallet
          <HelpTooltip
            text="This wallet is used to render videos. An amount of credits is substracted when you start a new render."
            style={{ fontSize: '20px', verticalAlign: 'middle', marginLeft: 10 }}
          />
        </Typography.Title>
      </Typography>

      <StyledStatistic title="Balance" value={walletBalance} precision={2} suffix="credits" />

      <div style={{ marginTop: 20 }}>
        <Button size="large" type="primary" onClick={() => setIsTopUpModalVisible(true)}>
          <Icon type="plus" />
          Top up wallet
        </Button>
        {/* &nbsp;&nbsp;&nbsp;or&nbsp;&nbsp;&nbsp;
        <Tooltip title="Coming soon!">
          <ProButton size="large" type="primary" disabled>
            <Icon type="star" theme="filled" />
            Become a WZRD Pro
          </ProButton>
        </Tooltip> */}

        {grossPriceDisplay && (
          <>
            <br />
            <small>
              Top-ups cost {grossPriceDisplay} per credit<br />
              {/* {isAdmin && 'When use a theme: '}1 audiovisual minute = 1 credit<br />
              {isAdmin && 'When using your own images: 1 audiovisual minute = 3 credits'} */}
              1 audiovisual minute = 1 credit
            </small>
          </>
        )}
      </div>

      <Typography>
        <Typography.Title level={3} style={{ fontWeight: 400, marginTop: 30, marginBottom: 10, color: 'rgba(0,0,0, 0.45)', fontSize: 17 }}>
          History
        </Typography.Title>
      </Typography>

      <StyledTable
        columns={[
          {
            title: 'Description',
            dataIndex: 'description',
          },
          {
            title: 'Date',
            dataIndex: 'date',
            render: (d) => {
              if (!d) {
                return null;
              }
              
              const dDate = d.toDate ? d.toDate() : d;
              
              return dDate.toLocaleString([], { day: 'numeric', year: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit' });
            },
          },
          {
            title: 'Change',
            dataIndex: 'amount',
            align: 'right',
            render: (amount) => (
              <Tag className="monospace" color={amount >= 0 ? success : danger}>{amount >= 0 ? '+' : ''}{amount.toFixed(2)}</Tag>
            ),
          },
          // {
          //   title: 'Remaining Balance',
          //   dataIndex: 'balance',
          //   align: 'right',
          //   render: (balance) => (
          //     <Statistic title={null} value={balance} precision={2} suffix="credits" />
          //   ),
          // },
        ]}
        dataSource={walletHistory}
        bordered
        size="small"
        pagination={false}
        showHeader={walletHistory && walletHistory.length > 0}
        rowKey="id"
        locale={{ emptyText: 'Your wallet has no history yet' }}
      />

      <Typography>
        <Typography.Title level={4} style={{ marginTop: 40 }}>
          Delete this account
        </Typography.Title>
      </Typography>

      <Typography.Paragraph>Deleting your account deletes your user, render wallet, all your projects, and all related images, audio, and video.</Typography.Paragraph>

      <Button type="danger" onClick={() => setIsDeleteAccountModalVisible(true)}>Request deletion</Button>

      <DeleteAccountModal
        visible={isDeleteAccountModalVisible}
        close={() => setIsDeleteAccountModalVisible(false)}
      />
    </>
  );
}
