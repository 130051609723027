import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { Menu, Icon } from 'antd';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { selectProjectDetailsFirestore } from 'store/selectors';

const StyledMenu = styled(Menu)`
  transition: opacity 0.3s;
`;

export default function ProjectMenu(props) {
  const project = useSelector(selectProjectDetailsFirestore);
  const location = useLocation();
  const history = useHistory();
  // const isImageSourceUser = useSelector(selectIsProjectImageSourceUser);

  if (!location.pathname.startsWith('/project')) {
    return null;
  }

  let pathName = location.pathname;
  if (pathName.includes('/start-render')) {
    pathName = pathName.substring(0, pathName.indexOf('/start-render'));
  }

  let projectId = location.pathname.substring(location.pathname.indexOf('/project/') + '/project/'.length);
  projectId = projectId.substring(0, projectId.includes('/') ? projectId.indexOf('/') : projectId.length);

  return ( 
    <StyledMenu
      theme="dark"
      mode="horizontal"
      selectedKeys={[pathName]}
      onSelect={({ key }) => history.push(key)}
      {...props}
      style={{...(props.style || {}), opacity: isLoaded(project) ? 1 : 0 }}
    >
      <Menu.Item key={`/project/${projectId}`}>
        <Icon type="heart" theme="filled" />
        Images
      </Menu.Item>

      <Menu.Item key={`/project/${projectId}/timeline`}>
        <Icon type="clock-circle" theme="filled" />
        Timeline
      </Menu.Item>

      {/* {isImageSourceUser === true && (
        <Menu.Item key={`/project/${projectId}/review`}>
          <Icon type="eye" theme="filled" />
          Review
        </Menu.Item>
      )} */}

      <Menu.Item key={`/project/${projectId}/renders`}>
        <Icon type="play-square" theme="filled" />
        Renders
      </Menu.Item>
    </StyledMenu>
  );
}
