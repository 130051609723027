import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

import useSelectProject from 'hooks/use-select-project';
import { currentTimeSubject, playCTAugmentation, pauseCTAugmentation, setAudioElement } from 'store/current-time-subject';
import setCurrentTimeSubject from 'store/set-current-time-subject';
import { clearDownloadURLCache } from 'utils/get-download-url';
import isChrome from 'utils/is-chrome';
import aspectRatioFromParams from 'utils/aspect-ratio-from-params';
import { selectAudioUrl, selectPlaybackDuration } from 'store/selectors';
import Progress from './Progress';
import Time from './Time';
import PlayButton from './PlayButton';
import RenderButton from './RenderButton';
import config from 'config';
import audioPathToAac from 'utils/audio-path-to-aac';

const Wrapper = styled.div`
  width: auto;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 0 24px;
`;

export default function () {
  const audioUrl = useSelector(selectAudioUrl);
  const duration = useSelector(selectPlaybackDuration);
  const durationRef = useRef(duration);
  const audioRef = useRef();
  const dispatch = useDispatch();
  const project = useSelectProject();
  const projectId = (project || {}).id;
  const [videoUrl, setVideoUrl] = useState(null);
  const keyDownTimestamp = useRef(null);
  const keyDownTimeInAudio = useRef(0);

  setAudioElement(audioRef.current);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onloadedmetadata = () => {
        if (audioRef.current) {
          dispatch({
            type: 'UPDATE_PLAYBACK_DURATION',
            projectId,
            duration: audioRef.current.duration,
          });
        }
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = setCurrentTimeSubject.subscribe((time) => setCurrentTime(time));

    return () => {
      subscription.unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('keydown', onKeyDown);
    
    return () => {
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('keydown', onKeyDown);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      publishCurrentTime(audioRef.current.currentTime);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    durationRef.current = duration;
  }, [duration]);

  useEffect(() => {
    return () => {
      publishCurrentTime(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function publishCurrentTime(time) {
    if (time >= durationRef.current) {
      pauseCTAugmentation();
    }
    currentTimeSubject.next(Math.min(durationRef.current, time));
  }

  function playOrPause(onlyPause = false) {
    setAudioElement(audioRef.current);

    if (!audioRef.current) {
      return;
    }

    const isPlaying = !audioRef.current.paused;

    if (onlyPause === true && !isPlaying) {
      return;
    }

    if (isChrome && !isPlaying) {
      playCTAugmentation();
    }
  
    audioRef.current.ontimeupdate = () => {
      if (audioRef.current) {
        publishCurrentTime(audioRef.current.currentTime);
      }
    };

    // audioRef.current.onplay = () => {
    //   publishCurrentTime(audioRef.current.currentTime - 0.1));
    //   playCTAugmentation();
    // };
    audioRef.current.onplaying = () => {
      playCTAugmentation();
      if (isChrome) {
        publishCurrentTime(audioRef.current.currentTime);
      } else {
        publishCurrentTime(Math.max(0, audioRef.current.currentTime - 0.05));
      }
    };
    audioRef.current.onpause = () => {
      pauseCTAugmentation();
      dispatch({
        type: 'UPDATE_PLAYBACK_PLAYING',
        projectId,
        playing: false,
      });
    };
    if (!isChrome) {
      audioRef.current.onwaiting = () => {
        pauseCTAugmentation();
      };
    }
    audioRef.current.onerror = () => {
      clearDownloadURLCache(
        config.audioReencodedBucket,
        audioPathToAac(project.audio.filePath),
      );
    };

    if (audioRef.current.paused) {
      // Seems weird, but this line is magical!
      // Whithout it there's a weird jump when playing after pause.
      if (audioRef.current.duration <= audioRef.current.currentTime) {
        audioRef.current.currentTime = 0;
      } else {
        // eslint-disable-next-line no-self-assign
        audioRef.current.currentTime = audioRef.current.currentTime;
      }
      
      audioRef.current.play();
      dispatch({
        type: 'UPDATE_PLAYBACK_PLAYING',
        projectId,
        playing: true,
      });
      
    } else {
      audioRef.current.pause();
      publishCurrentTime(audioRef.current.currentTime);
      dispatch({
        type: 'UPDATE_PLAYBACK_PLAYING',
        projectId,
        playing: false,
      });
    }
  }

  function setCurrentTime(time) {
    if (audioRef.current) {
      audioRef.current.currentTime = time;
      publishCurrentTime(time);
    }
  }

  function onKeyDown(event) {
    if (event.code === 'Space' && !event.repeat) {
      event.preventDefault();
      event.stopPropagation();
      playOrPause();
      keyDownTimestamp.current = Date.now();
      keyDownTimeInAudio.current = (audioRef.current || {}).currentTime || 0;
    }
  }

  function onKeyUp(event) {
    if (event.code === 'Space' && !event.repeat) {
      if (keyDownTimestamp.current !== null &&
        keyDownTimeInAudio.current !== null &&
        keyDownTimestamp.current + 1000 < Date.now()) {
        
        // In case of longpress, pause the video and return to time of key down
        playOrPause(true);
        setCurrentTime(keyDownTimeInAudio.current);

        keyDownTimestamp.current = null;
        keyDownTimeInAudio.current = null;
      }
    }
  }

  return (
    <Wrapper>
      {audioUrl && (
        <audio
          playsInline
          preload="auto"
          ref={audioRef}
        >
          <source src={audioUrl} />
        </audio>
      )}

      <PlayButton playOrPause={playOrPause} />

      <Time from />

      <Progress setCurrentTime={setCurrentTime} />

      <Time to />

      <RenderButton setVideoUrl={setVideoUrl} />

      <ModalVideo
        channel='custom'
        isOpen={videoUrl ? true : false}
        url={videoUrl}
        onClose={() => setVideoUrl(null)}
        ratio={aspectRatioFromParams((project || {}).parameters)}
      />
    </Wrapper>
  )
}
