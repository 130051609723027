import produce from 'immer';

function produceForProjectId(state, projectId, callback) {
  return produce(state, (mutated) => {
    if (!projectId) {
      console.error('No projectId in action object');
      return;
    }

    if (projectId && !mutated[projectId]) {
      mutated[projectId] = {
        fetching: { waveform: false },
        fetched: { waveform: false },
        waveform: null,
        nodeSpacing: null,
        playbackDuration: 1,
        playbackPlaying: false,
      };
    }

    callback(mutated[projectId]);
  });
}

export default function (state = {}, { projectId, ...action }) {
  switch (action.type) {
    case 'FETCH_WAVEFORM_REQUESTED':
      return produceForProjectId(state, projectId, (mutated) => {
        mutated.fetching.waveform = true;
      });

    case 'FETCH_WAVEFORM_SUCCEEDED':
      return produceForProjectId(state, projectId, (mutated) => {
        mutated.fetching.waveform = false;
        mutated.fetched.waveform = true;
        mutated.waveform = action.data;
      });

    case 'FETCH_WAVEFORM_FAILED':
      return produceForProjectId(state, projectId, (mutated) => {
        mutated.fetching.waveform = false;
      });

    case 'FETCH_NODE_SPACING_REQUESTED':
      return produceForProjectId(state, projectId, (mutated) => {
        mutated.fetching.nodeSpacing = true;
      });

    case 'FETCH_NODE_SPACING_SUCCEEDED':
      return produceForProjectId(state, projectId, (mutated) => {
        mutated.fetching.nodeSpacing = false;
        mutated.fetched.nodeSpacing = true;
        mutated.nodeSpacing = action.data;
      });

    case 'FETCH_NODE_SPACING_FAILED':
      return produceForProjectId(state, projectId, (mutated) => {
        mutated.fetching.nodeSpacing = false;
      });

    case 'FETCH_AUDIO_URL_REQUESTED':
      return produceForProjectId(state, projectId, (mutated) => {
        mutated.fetching.audioUrl = true;
      });

    case 'FETCH_AUDIO_URL_SUCCEEDED':
      return produceForProjectId(state, projectId, (mutated) => {
        mutated.fetching.audioUrl = false;
        mutated.fetched.audioUrl = true;
        mutated.audioUrl = action.url;
      });

    case 'FETCH_AUDIO_URL_FAILED':
      return produceForProjectId(state, projectId, (mutated) => {
        mutated.fetching.audioUrl = false;
      });

    case 'UPDATE_PLAYBACK_DURATION':
      return produceForProjectId(state, projectId, (mutated) => {
        mutated.playbackDuration = action.duration;
      });

    case 'UPDATE_PLAYBACK_PLAYING':
      return produceForProjectId(state, projectId, (mutated) => {
        mutated.playbackPlaying = action.playing;
      });

    default:
      return state;
  }
}
