import React from 'react';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { message } from 'antd';
import { Link } from 'react-router-dom';
import {
  GoogleLoginButton,
} from 'react-social-login-buttons';

import GoogleAnalytics from 'utils/google-analytics';
import CenteredBlock from 'components/CenteredBlock';
import LoginButton from 'components/LoginButton';
import FixedDivider from 'components/FixedDivider';
import LoginWithEmail from './LoginWithEmail';

export default function () {
  const firebase = useFirebase();
  const firestore = useFirestore();

  function loginSuccessful(result) {
    message.success('You\'re in!');

    // If you change the following, also change it somewhere else ;)
    const userRef = firestore.collection('/users').doc(result.user.uid);

    return userRef.get()
      .then((userSnapshot) => {
        const data = {
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
          email: result.user.email,
          ...(userSnapshot.exists ? userSnapshot.data() : {}),
        };

        if (userSnapshot.exists) {
          userRef.update(data);

        } else {
          userRef.set(data);

          GoogleAnalytics.event({
            category: 'user',
            action: 'create-user'
          });

          firebase.functions().httpsCallable('signUpComplete')()
            .then(() => {})
            .catch(console.error);
        }
      })
      .catch(console.error);
  }

  function doLoginWithProvider(provider, scopes) {
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    
    if (scopes) {
      scopes.forEach((scope) => {
        provider.addScope(scope);
      });
    }

    firebase.auth().signInWithPopup(provider)
      .then(loginSuccessful)
      .catch((error) => {
        message.error(error.message);
      });
  }

  function doLoginWithEmail(email, password) {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(loginSuccessful)
      .catch((error) => {
        message.error(error.message);
      });
  }

  function loginWithGoogle() {
    doLoginWithProvider(
      new firebase.auth.GoogleAuthProvider(),
      [],
    );
  }

  // function loginWithTwitter() {
  //   doLoginWithProvider(
  //     new firebase.auth.TwitterAuthProvider(),
  //     [],
  //   );
  // }

  // function loginWithFacebook() {
  //   doLoginWithProvider(
  //     new firebase.auth.FacebookAuthProvider(),
  //     ['email'],
  //   );
  // }

  return (
    <CenteredBlock>
      <h2>Log in to <span style={{ fontWeight: 900 }}>WZRD</span></h2>

      <div style={{ marginBottom: 30 }}>
        Don't have an account yet? <Link to="/signup">Sign up</Link>
      </div>

      <LoginButton onClick={loginWithGoogle} button={GoogleLoginButton} />

      <FixedDivider>OR</FixedDivider>

      <LoginWithEmail doLoginWithEmail={doLoginWithEmail} />
    </CenteredBlock>
  );
}
