import { BehaviorSubject } from 'rxjs';
import isChrome from 'utils/is-chrome';

export const currentTimeSubject = new BehaviorSubject(0);
export const currentTimeAugmentedSubject = new BehaviorSubject(0);

let currentTime = 0;
let lastCurrentTimeUpdate = null;
let animationLoop = null;
let isPlaying = false;
let audioElement = null;

currentTimeSubject.subscribe((cT) => {
  currentTime = cT;

  if (isChrome && audioElement) {
    currentTimeAugmentedSubject.next(audioElement.currentTime);
  } else {
    if (isPlaying) {
      lastCurrentTimeUpdate = performance.now();

    // Fix for jump at play/resume
    } else {
      currentTimeAugmentedSubject.next(currentTime);
    }
  }
});

function loop() {
  animationLoop = requestAnimationFrame(loop);

  if (isChrome && audioElement) {
    currentTimeAugmentedSubject.next(audioElement.currentTime);
  } else {
    if (lastCurrentTimeUpdate !== null) {
      const now = performance.now();
      const secondsSinceLastCTUpdate = Math.max(0, (now - lastCurrentTimeUpdate) / 1000);
      const augmentedTime = currentTime + secondsSinceLastCTUpdate;

      if (augmentedTime > currentTimeAugmentedSubject._value || augmentedTime < currentTimeAugmentedSubject._value - 0.25) {
        currentTimeAugmentedSubject.next(augmentedTime);
      }
    }
  }
}

export function playCTAugmentation() {
  isPlaying = true;

  if (!animationLoop) {
    loop();
  }
}

export function pauseCTAugmentation() {
  isPlaying = false;

  if (animationLoop) {
    cancelAnimationFrame(animationLoop);
    animationLoop = null;
    lastCurrentTimeUpdate = null;
  }

  if (isChrome && audioElement) {
    currentTimeAugmentedSubject.next(audioElement.currentTime);
  } else {
    currentTimeAugmentedSubject.next(currentTime);
  }
}

export function setAudioElement(el) {
  audioElement = el;
}
