import React from 'react';
import { useSelector } from 'react-redux';

import DelayedSpin from 'components/DelayedSpin';
import { selectIsProjectImageSourceUser } from 'store/selectors';
import CustomImages from '../CustomImages';
import Favourites from '../Favourites';

export default function () {
  const isImageSourceUser = useSelector(selectIsProjectImageSourceUser);

  if (isImageSourceUser === true) {
    return <CustomImages />;
  } else if (isImageSourceUser === false) {
    return <Favourites />;
  } else {
    return <DelayedSpin />;
  }
}
