import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { withSize } from 'react-sizeme';
import { useSelector } from 'react-redux'

import { selectPlaybackDuration } from 'store/selectors';
import { currentTimeAugmentedSubject } from 'store/current-time-subject';
import { lightgrey, brand } from 'colors';

const StyledDiv = styled.div`
  width: 100%;
  height: 40px;
  padding-top: 19px;
  cursor: pointer;

  > div {
    width: 100%;
    height: 2px;
    position: relative;
    background-color: ${lightgrey[1]};

    > .line {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: ${brand.primary};
      pointer-events: none;
      transform-origin: top left;
      will-change: transform;
    }

    > .dot {
      display: block;
      position: absolute;
      top: -3px;
      left: 0;
      margin-left: -4px;
      height: 8px;
      width: 8px;
      border-radius: 4px;
      background-color: ${brand.primary};
      // opacity: 0;
      // transition: opacity 0.15s;
      pointer-events: none;
      // will-change: transform, opacity;
      will-change: transform;
    }
  }

  // &:hover > div > .dot {
  //   opacity: 1;
  // }
`;

function Progress({ size, setCurrentTime }) {
  const duration = useSelector(selectPlaybackDuration);

  const progressLineRef = useRef();
  const progressDotRef = useRef();
  const durationRef = useRef(1);

  const [isMouseDown, setIsMouseDown] = useState(false);

  useEffect(() => {
    durationRef.current = duration;
  }, [duration]);

  useEffect(() => {
    const subscription = currentTimeAugmentedSubject.subscribe((currentTime) => {
      const currentTimeCapped = Math.min(durationRef.current, currentTime);
      const width = (currentTimeCapped / durationRef.current) * size.width;
      
      if (progressLineRef.current) {
        progressLineRef.current.style.transform = `scaleX(${width/100})`;
        if (!progressLineRef.current.style.width || progressLineRef.current.style.width === '0px') {
          progressLineRef.current.style.width = '100px';
        }
      }
      if (progressDotRef.current) {
        progressDotRef.current.style.transform = `translateX(${width}px)`;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size])

  function onMouseUp() {
    setIsMouseDown(false);
  }

  return (
    <div style={{ width: '100%' }}>
      {/* Need to wrap for react-sizeme to work */}
      <StyledDiv
        onMouseDown={(e) => {
          setIsMouseDown(true);
          setCurrentTime(Math.min(duration, Math.max(0, duration * (e.nativeEvent.offsetX / size.width))));
        }}
        onMouseMove={(e) => {
          if (!isMouseDown) {
            return;
          }
          
          setCurrentTime(Math.min(duration, Math.max(0, duration * (e.nativeEvent.offsetX / size.width))));
        }}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
      >
        <div>
          <div className="line" ref={progressLineRef} />
          <div className="dot" ref={progressDotRef} />
        </div>
      </StyledDiv>
    </div>
  )
}

export default withSize({ monitorWidth: true, monitorHeight: false })(Progress)
