import React from 'react';
import { Icon, Dropdown, Menu } from 'antd';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';

import config from 'config';
import FirebaseImage from 'components/FirebaseImage';

const StyledFirebaseImage = styled(FirebaseImage)`
  object-fit: cover;

  img {
    pointer-events: none;
  }
`;

const ImageWrapper = styled.div`
  will-change: left;
`;

export default function ({
  dumbKey,
  time,
  setSelectedTime,
  setIsSelectImageModalVisible,
  clear,
  project,
  projectNodes,
  pixelsPerSecond,
  auth,
  isDraggable,
}) {
  const [{ isDragging }, dragRef, ] = useDrag({
    item: {
      dumbKey,
      time,
      type: 'FirebaseImage',
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  function openSelectImageModal() {
    setSelectedTime(time);
    setIsSelectImageModalVisible(true);
  }

  return (
    <div
      ref={isDraggable ? dragRef : null}
      style={{ left: time * pixelsPerSecond, ...(isDragging ? { opacity: 0 } : {}) }}
    >
      <Dropdown
        placement="topLeft"
        overlay={(
          <Menu>
            {isDraggable && (
              <Menu.Item disabled>
                Drag to move
              </Menu.Item>
            )}

            <Menu.Item onClick={openSelectImageModal}>
              Select image
            </Menu.Item>

            <Menu.Item
              onClick={() => clear(time)}
              disabled={!(projectNodes && projectNodes[time])}
            >
              Clear
            </Menu.Item>
          </Menu>
        )}
        trigger={['hover']}
        overlayStyle={{ transition: 'opacity 0.3s', ...(isDragging ? { opacity: 0 } : {}) }}
      >
        <ImageWrapper
          className={isDraggable ? 'grabbable ' : ''}
        >
          {projectNodes && projectNodes[time] ? (
            <StyledFirebaseImage
              path={`${auth.uid}/${project.id}/${projectNodes[time]}`}
              bucket={config.customImagesBucket}
              width={80}
              height={80}
              animated={false}
              lazyload={false}
            />
          ) : (
            <Icon type="picture" theme="filled" />
          )}
        </ImageWrapper>
      </Dropdown>
    </div>
  );
}
