import styled from 'styled-components';

export default styled.div`
  width: 90%;
  max-width: 300px;
  margin: 0 auto;
  padding-top: calc(15vh - 64px);

  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  button {
    margin-bottom: 20px !important;
  }
`;
