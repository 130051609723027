import parseCookies from 'utils/parse-cookies';

const cookies = parseCookies();
const cookieConsentPresent = cookies.consent ? true : false;

export default function (state = {
  pixelsPerSecond: 20,
  isCookieConsentVisible: !cookieConsentPresent,
}, action) {
  switch (action.type) {
    case 'SET_PIXELS_PER_SECOND':
      return {
        ...state,
        pixelsPerSecond: action.pixelsPerSecond,
      };

    case 'OPEN_COOKIE_CONSENT':
      return {
        ...state,
        isCookieConsentVisible: true,
      };

    case 'CLOSE_COOKIE_CONSENT':
      return {
        ...state,
        isCookieConsentVisible: false,
      };

    default:
      return state;
  }
}
