import { getFirebase } from 'react-redux-firebase';

const storages = {};

let cachedUrlsPerBucket = {};
try {
  cachedUrlsPerBucket = JSON.parse(localStorage.getItem('cachedUrlsPerBucket') || '{}');
} catch (err) {
  console.error(err);
}

const cachedUrlPromisesPerBucket = {};
Object.entries(cachedUrlsPerBucket).forEach(([bucket, urlsForPaths]) => {
  cachedUrlPromisesPerBucket[bucket] = {};

  Object.entries(urlsForPaths).forEach(([path, url]) => {
    cachedUrlPromisesPerBucket[bucket][path] = Promise.resolve(url);
  });
});

export default function (bucket, path, options) {
  const firebase = getFirebase();

  const cached = !options || options.cached !== false;
  const cache = cached ? cachedUrlPromisesPerBucket : {};

  const existingPromise = (cache[bucket] || {})[path];

  if (existingPromise) {
    return existingPromise;
  }

  if (!storages[bucket]) {
    storages[bucket] = bucket ? firebase.app().storage(bucket) : firebase.storage();
  }

  if (!cache[bucket]) {
    cache[bucket] = {};
  }

  cache[bucket][path] = storages[bucket].ref().child(path).getDownloadURL()
    .then((url) => {
      if (!cachedUrlsPerBucket[bucket]) {
        cachedUrlsPerBucket[bucket] = {};
      }

      cachedUrlsPerBucket[bucket][path] = url;
      
      if (cached) {
        localStorage.setItem('cachedUrlsPerBucket', JSON.stringify(cachedUrlsPerBucket));
      }

      return url;
    })
    .catch((err) => {
      delete cache[bucket][path];
      throw err;
    });

  return cache[bucket][path];
}

export function getDownloadURLCached(bucket, path) {
  return (cachedUrlsPerBucket[bucket] || {})[path];
}

export function clearDownloadURLCache(bucket, path) {
  delete cachedUrlsPerBucket[bucket][path];
  delete cachedUrlPromisesPerBucket[bucket][path];

  localStorage.setItem('cachedUrlsPerBucket', JSON.stringify(cachedUrlsPerBucket));
}
