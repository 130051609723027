import React, { useState, useRef } from 'react';
import { Modal, Button, Icon, message } from 'antd';

const AUDIO_SAMPLES = [
  { 
    title: 'Endless Motion',
    url: require('assets/bensound-endlessmotion.mp3'),
    filename: 'endlessmotion.mp3',
  },
  { 
    title: 'All That',
    url: require('assets/bensound-allthat.mp3'),
    filename: 'allthat.mp3',
  },
  { 
    title: 'Evolution',
    url: require('assets/bensound-evolution.mp3'),
    filename: 'evolution.mp3',
  },
];

export default function ({ visible, close, setFieldsValue, handeUploadFile, onSuccessfulSampleSelection }) {
  const [selectedSample, selectSample] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(null);

  function stopAudioAndClose() {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    close();
  }

  return (
    <Modal
      visible={visible}
      title="Select audio sample"
      onCancel={() => {
        if (!isLoading) {
          stopAudioAndClose();
        }
      }}
      closable={!isLoading}
      footer={
        <>
          <Button onClick={stopAudioAndClose} disabled={isLoading}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => {
              setIsLoading(true);

              fetch(selectedSample.url)
                .then(response => response.blob())
                .then((blob) => {
                  blob.lastModifiedDate = new Date();
                  blob.name = selectedSample.filename;
                  handeUploadFile({
                    file: blob, 
                    setFieldsValue,
                    onProgress: () => {},
                    onSuccess: () => {
                      setIsLoading(false);
                      stopAudioAndClose();
                      onSuccessfulSampleSelection(selectedSample);
                    },
                    onError: (err) => {
                      setIsLoading(false);
                      message.error('Something went wrong');
                      console.error(err);
                    },
                  });
                })
                .catch((err) => {
                  setIsLoading(false);
                  message.error('Something went wrong');
                  console.error(err);
                });
            }}
            disabled={!selectedSample}
            loading={isLoading}
          >
            Continue with sample
          </Button>
        </>
      }
    >
      {AUDIO_SAMPLES.map((sample) => (
        <Button
          key={sample.title}
          style={{ marginRight: 10, marginBottom: 10 }}
          onClick={() => selectSample(sample)}
          type={selectedSample === sample ? 'primary' : 'default'}
          disabled={isLoading}
          shape="round"
        >
          <Icon type="play-circle" theme="filled" />
          {sample.title}
        </Button>
      ))}

      {selectedSample && (
        <audio
          controls
          src={selectedSample.url}
          autoPlay
          style={{ marginTop: 5, width: '100%' }}
          ref={audioRef}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      )}

      <p style={{ marginTop: 30 }}>
        <small>
          By creating a project with one of these audio samples, you accept to credit <a href="https://bensound.com" target="_blank" rel="noopener noreferrer">Bensound.com</a> for the audio.
        </small>
      </p>
    </Modal>
  );
}
