import React from 'react';
import styled from 'styled-components';
import { Icon, Modal } from 'antd';

const StyledModal = styled(Modal)`
  width: calc(100vw - 48px) !important;
  top: 24px;

  .ant-modal-content {
    background: transparent;
    box-shadow: none;

    .ant-modal-body {
      padding: 0;
      text-align: center;
    }
  }

  .ant-modal-close-x {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;

  img {
    max-width: 100%;
    max-height: calc(100vh - 96px);
    margin: 0 auto;
    display: block;
    border-radius: 3px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 24px;
  width: 24px;
  line-height: 24px;
  background: rgba(0,0,0, 0.6);
  border-radius: 0 3px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  
  .anticon {
    font-size: 11px;
    color: rgba(255,255,255, 0.7);
    transition: color 0.3s;
  }

  &:hover {
    background: rgba(0,0,0, 0.7);

    .anticon {
      color: rgba(255,255,255, 0.8);
    }
  }
`;

export default function ({ src, visible, onClose }) {
  return (
    <StyledModal visible={visible} footer={null} onCancel={onClose}>
      <div onClick={onClose}>
        <ImageWrapper>
          <img alt="wzrd" src={src} />

          <CloseButton>
            <Icon type="close" />
          </CloseButton>
        </ImageWrapper>
      </div>
    </StyledModal>
  );
}