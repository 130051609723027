import { createSelector } from 'reselect';
import calculateNodesTimes from 'utils/calculate-nodes-times';
import calculateNumberOfNodes from 'utils/calculate-number-of-nodes';

export const selectProjectId = createSelector(
  (state) => state,
  ({ router: { location: { pathname } }}) => {
    const prefix = '/project/';
    if (pathname.startsWith(prefix)) {
      const end = pathname.indexOf('/', prefix.length);
      return pathname.substring(prefix.length, end === -1 ? pathname.length : end);
    }
    return null;
  },
);

export const selectProjectDetails = createSelector(
  (state) => state,
  selectProjectId,
  (state, projectId) => state.project[projectId] || {},
);

export const selectProjectDetailsFirestore = createSelector(
  (state) => state,
  selectProjectId,
  ({ firestore: { data } }, projectId) => data[`projectDetails.${projectId}`],
);

export const selectWaveform = createSelector(
  selectProjectDetails,
  (projectDetails) => projectDetails.waveform,
);

export const selectNodeSpacing = createSelector(
  selectProjectDetails,
  (projectDetails) => projectDetails.nodeSpacing,
);

export const selectAudioUrl = createSelector(
  selectProjectDetails,
  (projectDetails) => projectDetails.audioUrl,
);

export const selectAudioDurationSeconds = createSelector(
  selectProjectDetailsFirestore,
  selectWaveform,
  (projectDetailsFirestore, waveform) => ((projectDetailsFirestore || {}).audio || {}).duration || (waveform ? (waveform.length / 2) : null),
);

export const selectNumberOfNodesPerMinute = createSelector(
  selectProjectDetailsFirestore,
  (projectDetailsFirestore) => ((projectDetailsFirestore || {}).parameters || {}).nodes_per_minute || 15,
)

export const selectIsProjectImageSourceUser = createSelector(
  selectProjectDetailsFirestore,
  (projectDetails) => (projectDetails || {}).imageSource === 'USER',
);

export const selectNodesTimes = createSelector(
  selectProjectDetailsFirestore,
  selectIsProjectImageSourceUser,
  selectAudioDurationSeconds,
  selectNodeSpacing,
  selectNumberOfNodesPerMinute,
  (projectDetailsFirestore, isImageSourceUser, audioDurationSeconds, nodeSpacing, numberOfNodesPerMinute) => {
    if (isImageSourceUser === true) {
      const nodesTimes = Object.keys(projectDetailsFirestore.nodes || {})
        .filter((time) => time !== null && time !== 'null' && time !== 'None')
        .map((time) => typeof time === 'number' ? time : Number.parseFloat(time));
      if (!nodesTimes.includes(0)) {
        nodesTimes.push(0);
      }
      if (!nodesTimes.includes(audioDurationSeconds)) {
        nodesTimes.push(audioDurationSeconds);
      }
      nodesTimes.sort((a, b) => Number.parseFloat(a) - Number.parseFloat(b));
      return nodesTimes;
    } else if (isImageSourceUser === false) {
      return calculateNodesTimes({ audioDurationSeconds, nodeSpacing, numberOfNodesPerMinute });
    } else {
      return [];
    }
  },
);

export const selectNodesTimesOverResed = createSelector(
  selectProjectDetailsFirestore,
  selectNodesTimes,
  selectAudioDurationSeconds,
  selectNodeSpacing,
  selectNumberOfNodesPerMinute,
  (projectDetailsFirestore, regularNodesTimes, audioDurationSeconds, nodeSpacing, numberOfNodesPerMinute) => {
    if ((projectDetailsFirestore || {}).imageSource === 'USER') {
      // No overresed when custom
      return regularNodesTimes;
    } else { 
      return calculateNodesTimes({ audioDurationSeconds, nodeSpacing, numberOfNodesPerMinute, overRes: 10 });
    }
  },
);

export const selectNumberOfNodes = createSelector(
  selectAudioDurationSeconds,
  selectNumberOfNodesPerMinute,
  (audioDurationSeconds, numberOfNodesPerMinute) => calculateNumberOfNodes(audioDurationSeconds, numberOfNodesPerMinute),
)

export const selectPlaybackDuration = createSelector(
  selectAudioDurationSeconds,
  selectProjectDetails,
  (audioDurationSeconds, projectDetails) =>
    // projectDetails.playbackDuration === 1 ? audioDurationSeconds : projectDetails.playbackDuration,
    /*
     * AAC is as streamed format, and does not have header with duration. Browser
     * makes guess based on bit rate and file size, but since we use VBR, that's
     * completely wrong.
     * Use duration from db instead
     */
    audioDurationSeconds || projectDetails.playbackDuration,
);

export const selectPlaybackPlaying = createSelector(
  selectProjectDetails,
  (projectDetails) => projectDetails.playbackPlaying,
);
