import React, { useState, useRef, useEffect } from 'react';
import { Checkbox, Button } from 'antd'
import { useFirebase, useFirestore } from 'react-redux-firebase';
import useSelectAuth from 'hooks/use-select-auth';
import useSelectUser from 'hooks/use-select-user';
import CenteredBlock from './CenteredBlock';

export default function () {
  const firestore = useFirestore();
  const firebase = useFirebase();
  const auth = useSelectAuth();
  const user = useSelectUser();
  const [isTOUAccepted, setIsTOUAccepted] = useState(false);
  const [optInForNewsletter, setOptInForNewsletter] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const isUnmounted = useRef(false);

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  function accept() {
    setIsSaving(true);

    firestore.collection('/users')
      .doc(auth.uid)
      .update({
        hasAcceptedTerms: true,
        hasGivenNewsletterPreference: true,
      })
      .then(() => {
        if (!isUnmounted.current) {
          setIsSaving(false);
        }
      })
      .catch((err) => {
        setIsSaving(false);
        console.error(err);
      });

    if (!user.hasGivenNewsletterPreference) {
      firebase.functions()
        .httpsCallable(optInForNewsletter ? 'optInForNewsletter' : 'optOutForNewsletter')()
        .then(() => console.info('Email preference saved'))
        .catch((err) => console.error(err));
    }
  }

  return (
    <CenteredBlock>
      <h2>Welcome to <span style={{ fontWeight: 900 }}>WZRD</span></h2>

      <div style={{ textAlign: 'left', margin: '0 auto', width: '90%' }}>
        {!user.hasGivenNewsletterPreference && (
          <div>
            <Checkbox
              checked={optInForNewsletter}
              onChange={(e) => setOptInForNewsletter(e.target.checked)}
              style={{ marginBottom: 20 }}
            >
              I'd like to receive the occasional email about new visual themes, features, discounts, and more 
            </Checkbox>
          </div>
        )}

        {!user.hasAcceptedTerms && (
          <div>
            <Checkbox
              checked={isTOUAccepted}
              onChange={(e) => setIsTOUAccepted(e.target.checked)}
              style={{ marginBottom: 30 }}
            >
              I agree to WZRD's <a href='/terms-of-use' target="_blank">Terms of Use</a>
            </Checkbox>
          </div>
        )}
      </div>

      <Button
        onClick={accept}
        disabled={!user.hasAcceptedTerms && !isTOUAccepted}
        type="primary"
        size="large"
        block
        loading={isSaving}
      >
        Continue
      </Button>
    </CenteredBlock>
  );
}
