import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Drawer, Checkbox, Button } from 'antd';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import parseCookies from 'utils/parse-cookies';
import GoogleAnalytics from 'utils/google-analytics';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    height: auto !important;
  }
`;

export default function () {
  const dispatch = useDispatch();
  const isCookieConsentVisible = useSelector((state) => state.view.isCookieConsentVisible);
  const [isManaging, setIsManaging] = useState(false);
  const [draftCookieConsent, setDraftCookieConsent] = useState({});

  const cookies = parseCookies();
  
  const cookieConsent = {
    analytics: true,
    ...(cookies.consent || {}),
    ...draftCookieConsent,
  };

  function save() {
    const isDev = !window.location.host.includes('wzrd.ai');
    document.cookie = `consent=${JSON.stringify(cookieConsent)}${isDev ? '' : ';domain=.wzrd.ai;path=/;SameSite=Lax'}`;
    setDraftCookieConsent({});

    dispatch({ type: 'CLOSE_COOKIE_CONSENT' });

    if (cookieConsent.analytics) {
      GoogleAnalytics.enable();
    } else {
      GoogleAnalytics.disable();
    }
  }

  function acceptAll() {
    setDraftCookieConsent({
      analytics: true,
    });
    save();
  }

  return (
    <StyledDrawer
      placement="bottom"
      closable={false}
      onClose={null}
      visible={isCookieConsentVisible}
      style={{ height: 'auto' }}
    >
      <p>
        We use <b>cookies</b> and similar technologies on our website so that we can offer you the best possible experience. Use the controls below to select which cookie types you want to enable.
      </p>
      <div style={isMobile ? {} : { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {isManaging && (
          <div style={isMobile ? { marginBottom: 10 } : {}}>
            <Checkbox checked disabled>Necessary</Checkbox>
            <Checkbox
              checked={cookieConsent.analytics}
              onChange={(e) => setDraftCookieConsent({ analytics: e.target.checked })}
            >
              Analytics
            </Checkbox>
          </div>
        )}
        {isManaging ? (
          <div>
            <Button
              type="primary"
              onClick={save}
              block={isMobile}
            >
              Accept Preference
            </Button>
          </div>
        ) : (
          <div>
            <Button
              style={{ marginRight: 15, ...(isMobile ? { marginBottom: 10 } : {}) }}
              onClick={() => setIsManaging(true)}
              block={isMobile}
            >
              Manage Cookies
            </Button>
            <Button
              type="primary"
              onClick={acceptAll}
              block={isMobile}
            >
              Accept Cookies
            </Button>
          </div>
        )}
      </div>
    </StyledDrawer>
  );
}
