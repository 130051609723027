import { useEffect, useRef, useState } from 'react';

export default function ({ children, delay }) {
  const previousChildren = useRef(children);
  const [, setShowPreviousChildren]  = useState(false);

  useEffect(() => {
    if (children === null && previousChildren.current !== null) {
      setShowPreviousChildren(true);
      const timeout = setTimeout(() => {
        previousChildren.current = null;
        setShowPreviousChildren(false);
      }, delay);
      return () => clearTimeout(timeout);
    }
  }, [children, delay]);

  if (children !== null) {
    previousChildren.current = children;
    return children;
  }
  return previousChildren.current;
}