import React from 'react';
import { Icon, Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';

export default function () {
  const history = useHistory();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={(
        <Button size="large" onClick={() => history.goBack()}>
          <Icon type="caret-left" />
          Go back
        </Button>
      )}
    />
  );
}
