import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Button, Tag } from 'antd';
import { useFirebase } from 'react-redux-firebase';
import Linkify from 'react-linkify';

import FirebaseImage from 'components/FirebaseImage';
import { magenta, lightgrey } from 'colors';

const Wrapper = styled(({ selected, disabled, ...props }) => <div {...props} />)`
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  width: 450px;
  max-width: 100vw;
  margin-bottom: 20px;

  > div:nth-child(1) {
    width: 450px;
    height: 450px;
    cursor: pointer;
    line-height: 0;
    transition: all 0.2s;
    box-shadow: ${({ selected }) => selected ? `0 0 0 10px ${magenta[2]} !important` : 'none'};
    border-radius: 3px;
    overflow: hidden;
  }

  > div:nth-child(2) {
    padding: 24px 24px 10px 24px;
    display: flex;
    justify-content: space-between;

    h3 {
      margin-bottom: 0;
      line-height: 1;

      .ant-tag {
        margin-left: 10px;
        vertical-align: text-bottom;
      }
    }
  }
`;

const Shape = styled(({ width, height, ...props }) => <div {...props} />)`
  background-color: ${lightgrey[1]};
  width: ${({ width }) => width / 50}px;
  height: ${({ height }) => height / 50}px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  border-radius: 2px;
`;

const Description = styled.div`
  padding: 0 24px;
  line-height: 1.5;
  font-size: 90%;
`;


export default function ({ model, selected, disabled, onSelect, onPreview }) {
  const firebase = useFirebase();
  const isUnmounted = useRef(false);
  const [samplesPaths, setSamplesPaths] = useState(null);
  const [modelIdFetched, setModelIdFetched] = useState(null);

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (!model) {
      return;
    }

    const modelId = model.id;

    firebase.storage().ref().child(`cherries/${model.code}/`).list({ maxResults: 9 })
      .then((response) => {
        if (isUnmounted.current) return;

        if ((model || {}).id !== modelId) {
          return;
        }

        setModelIdFetched(modelId);

        setSamplesPaths(
          response.items
            .filter((item) => item.fullPath.endsWith('.jpg'))
            .map((item) => item.fullPath)
        );
      })
      .catch((err) => {
        console.error(err);
        if (isUnmounted.current) return;
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);


  const outputShape = ((model.parameters || {}).output_shape || [1024, 1024])
  const shapeWidth = outputShape[0];
  const shapeHeight = outputShape[1];

  return (
    <Wrapper selected={selected} disabled={disabled}>
      <div onClick={onSelect}>
        {(!model || model.id === modelIdFetched) && samplesPaths ? samplesPaths.map((path) => (
          <FirebaseImage
            key={path}
            path={path}
            width={150}
            height={150}
            animated={false}
          />
        )) : Array.from(new Array(9).keys()).map((_, index) => (
          <FirebaseImage
            key={index}
            width={150}
            height={150}
            animated={false}
          />
        ))}
      </div>

      <div>
        <div>
          <h3>
            {model.name}

            {!model.isPublic && (
              <Tag color="magenta">
                Not public
              </Tag>
            )}
          </h3>

          <Shape width={shapeWidth} height={shapeHeight} />

          <small>{shapeWidth}x{shapeHeight}</small>
        </div>

        <div>
          {/* <Button
            onClick={onPreview}
            type="link"
            style={{ paddingLeft: 0 }}
          >
            Preview
          </Button>
          
          <Divider type="vertical" /> */}

          <Button
            onClick={onSelect}
            type="link"
          >
            {selected ? 'Selected' : 'Select'}
          </Button>
        </div>
      </div>

      {model.description && (
        <Description>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {model.description}
          </Linkify>
        </Description>
      )}
    </Wrapper>
  );
}
