import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { Layout, Button, message, Divider, Spin } from 'antd';
import styled from 'styled-components';

import GoogleAnalytics from 'utils/google-analytics';
import Routes from './Routes';
import UserDisplay from './UserDisplay';
import ProjectMenu from './ProjectMenu';
import ProjectName from './ProjectName';
import CookieConsent from './CookieConsent';

// const LinkHideSm = styled(Link)`
//   @media(max-width: 576px) {
//     display: none;
//   }
// `;

// const AHideSm = styled.a`
//   @media(max-width: 576px) {
//     display: none;
//   }
// `;

const bigPadding = '32px 48px 48px 48px';
const smallPadding = '24px 24px 48px 24px';

const StyledLayoutContent = styled(Layout.Content)`
  background: #fff;
  min-height: calc(100vh - 200px);

  @media(max-width: 576px) {
    padding: ${smallPadding} !important;
  }
`;

export default function App() {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const [isWaitingCheckoutComplete, setIsWaitingCheckoutComplete] =
    useState(window.location.hash === '#checkout-complete');

  const location = useLocation();
  // const showGoToDashboard = location.pathname !== '/' &&
  //   location.pathname !== '/intro' &&
  //   !location.pathname.startsWith('/project');
  // const showGiveFeedback = location.pathname.startsWith('/project');
  // const showGiveFeedback = true;
  const isOnTimeline = location.pathname.includes('/timeline');
  const isOnReview = location.pathname.includes('/review');
  const isOnFullScreenPage = isOnTimeline || isOnReview;
  const showFooter = !isOnFullScreenPage;
  const fixedHeader = location.pathname.startsWith('/project') && !location.pathname.endsWith('timeline');

  function openCookieConsent() {
    dispatch({ type: 'OPEN_COOKIE_CONSENT' });
  }

  useEffect(() => {
    if (isWaitingCheckoutComplete) {
      const checkoutCompleteTimeout = setTimeout(() => {
        window.location.hash = '';
        setIsWaitingCheckoutComplete(false);
      }, 4000);

      return () => {
        clearTimeout(checkoutCompleteTimeout);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firebase.auth().getRedirectResult()
      .then((result) => {
        if (!result || result.operationType !== 'signIn') {
          return;
        }

        message.success('You\'re in!');

        // If you change the following, also change it somewhere else ;)
        const userRef = firestore.collection('/users').doc(result.user.uid);

        userRef.get()
          .then((userSnapshot) => {
            const data = {
              displayName: result.user.displayName,
              photoURL: result.user.photoURL,
              email: result.user.email,
              ...(userSnapshot.exists ? userSnapshot.data() : {}),
            };

            if (userSnapshot.exists) {
              userRef.update(data);

            } else {
              userRef.set(data);

              GoogleAnalytics.event({
                category: 'user',
                action: 'create-user'
              });

              firebase.functions().httpsCallable('signUpComplete')()
                .then(() => {})
                .catch(console.error);
            }
          })
          .catch(console.error);
      })
      .catch((error) => {
        console.error(error);
        if (error.message) {
          message.error({ content: error.message, duration: 5 });
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isWaitingCheckoutComplete) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -100%)'}}>
        <Spin size="large" tip="Processing purchase" />
      </div>
    );
  }

  return (
    <Layout className="layout" style={{ minHeight: '100vh', overflow: 'hidden' }}>
      <Layout.Header style={{ display: 'flex', justifyContent: 'space-between', zIndex: 11, ...(fixedHeader ? { position: 'fixed', width: '100%' } : {}) }}>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <Link to="/">
            <h1 style={{ color: 'white', margin: '-2px 35px 0 0', fontWeight: 900 }}>WZRD</h1>
          </Link>

          <ProjectName />
        </div>

        <ProjectMenu style={{ lineHeight: '64px', flex: 1, margin: '0 40px', textAlign: 'center' }} />

        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          {/* {showGoToDashboard && (
            <LinkHideSm to="/">
              <Button size="large" type="primary" style={{ marginRight: 15 }}>Go to dashboard</Button>
            </LinkHideSm>
          )} */}

          {/* {showGiveFeedback && (
            <AHideSm href="https://wzrd.ai/contact/">
              <Button size="large" type="primary" style={{ marginRight: 15 }}>
                <Icon type="message" theme="filled" />
                Feedback
              </Button>
            </AHideSm>
          )} */}

          <UserDisplay />
        </div>
      </Layout.Header>

      <StyledLayoutContent style={{ padding: isOnFullScreenPage ? smallPadding : bigPadding, ...(fixedHeader ? { marginTop: 64 } : {}) }}>
        <Routes />
      </StyledLayoutContent>

      {showFooter && (
        <Layout.Footer style={{ textAlign: 'center', position: 'relative' }}>
          <span style={{ fontWeight: 900 }}>WZRD</span> © {(new Date()).getFullYear()}
          <Divider type="vertical" />
          <a href='https://wzrd.ai/terms-of-use'>Terms of Use</a>&nbsp;
          <Divider type="vertical" />
          <a href='https://wzrd.ai/privacy-policy'>Privacy Policy</a>&nbsp;
          <Divider type="vertical" />
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={openCookieConsent}
          >
            Manage Cookies
          </Button>
        </Layout.Footer>
      )}

      <CookieConsent />
    </Layout>
  );
}
