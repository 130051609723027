import React, { useEffect, useRef } from 'react';

import { withSize } from 'react-sizeme';
import styled, { keyframes } from 'styled-components';
import { Icon, Tooltip } from 'antd';
import { useSelector } from 'react-redux';

import config from 'config';
import { lightgrey } from 'colors';
import {
  selectWaveform,
  selectNodesTimes,
  selectNodesTimesOverResed,
  selectPlaybackDuration,
  selectIsProjectImageSourceUser,
} from 'store/selectors';
import { currentTimeAugmentedSubject } from 'store/current-time-subject';
import useSelectAuth from 'hooks/use-select-auth';
import useSelectProject from 'hooks/use-select-project';
import FirebaseImage from 'components/FirebaseImage';

const PADDING_BETWEEN_IMAGES = 8;
const PADDING_PAGE = 24;
const MAIN_DIMENSION_RATIO = Math.sqrt(1.618);
const BORDER_RADIUS = 4;

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

const InnerWrapper = styled.div`
  padding: 0 ${PADDING_PAGE}px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  overflow-x: visible;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
  z-index: 1;
  opacity: 0.3;

  > .firebase-img {
    flex-shrink: 0;
    margin-right: ${PADDING_BETWEEN_IMAGES}px;
  }
`;

const Placeholder = styled.div`
  flex-shrink: 0;
  background-color: #c0c0c0;
  margin-right: ${PADDING_BETWEEN_IMAGES}px;
  background-color: ${lightgrey[0]};
  border-radius: 0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    float: left;
    padding-top: 100%;
  }

  .anticon {
    font-size: 60px;
    color: ${lightgrey[3]};
  }
`;

const StyledFirebaseImage = styled(FirebaseImage)`
  border-radius: 0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px;
  object-fit: cover;
`;
const StyledFirebaseImageMain = styled(StyledFirebaseImage)`
  border-radius: ${BORDER_RADIUS}px;
  position: absolute;
  opacity: 0;
  will-change: opacity;
`;

const mainWrapperIntroAnimation = keyframes`
  0% {
    box-shadow: none;
  }
  100% {
    /* https://smoothshadows.com/#djEsMSwzLDAuMTMsMjQsLTEyLDAsIzAzMDcxMiwjZjNmNGY2LCNmZmZmZmYsMg%3D%3D */
    box-shadow: 0px -1px 3px rgba(3, 7, 18, 0.04),
      0px -5px 11px rgba(3, 7, 18, 0.09),
      0px -12px 24px rgba(3, 7, 18, 0.13);
  }
`;
const MainWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${mainWrapperIntroAnimation} 1s ease;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  border-radius: ${BORDER_RADIUS}px;
  will-change: opacity;
  
  .anticon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    opacity: 0.5;
    filter: drop-shadow(0 0 1px rgba(0,0,0,0.4));
  }
`;

function calculatePosition({ size, nodesTimes, currentTime }) {
  const dimension = Math.min(512, size.height);
  let position = -(size.width / 2) + (dimension / 2) + PADDING_PAGE;

  if (nodesTimes) {
    let index, nodesTimesLength;
    for (index = 0, nodesTimesLength = nodesTimes.length; index < nodesTimesLength && nodesTimes[index] < currentTime; index += 1) {
      // pass
    }
    index = Math.min(index, nodesTimes.length - 1);

    const frac = (currentTime - nodesTimes[index - 1]) / (nodesTimes[index] - nodesTimes[index - 1]);

    if (!Number.isNaN(frac)) {
      position += (dimension + PADDING_BETWEEN_IMAGES) * (index - 1 + frac);
    }
  }

  return position;
}

function setOpacity(element, opacity) {
  if (element) {
    element.style.opacity = opacity;
    return true;
  }
  return false;
}

function Preview({ size }) {
  const project = useSelectProject();
  const projectNodes = (project || {}).nodes;
  const isImageSourceUser = useSelector(selectIsProjectImageSourceUser);
  const auth = useSelectAuth();
  const waveform = useSelector(selectWaveform);
  const nodesTimes = useSelector(selectNodesTimes);
  const nodesTimesOverResed = useSelector(selectNodesTimesOverResed);
  const duration = useSelector(selectPlaybackDuration);

  const durationRef = useRef(1);
  const innerWrapperRef = useRef();
  const currentTimeCappedRef = useRef(0);
  const nodesTimesOverResedRef = useRef(null);
  const sizeRef = useRef(size);
  const mainWrapperRef = useRef();
  const mainRefs = useRef({});
  function setMainRef(index, ref) {
    mainRefs.current[index] = ref;
  }

  useEffect(() => {
    durationRef.current = duration;
  }, [duration]);

  useEffect(() => {
    const subscription = currentTimeAugmentedSubject.subscribe((currentTime) => {
      const currentTimeCapped = Math.min(durationRef.current, currentTime);
      currentTimeCappedRef.current = currentTimeCapped;
      
      if (innerWrapperRef.current) {
        const position = calculatePosition({
          size: sizeRef.current,
          nodesTimes: nodesTimesOverResedRef.current,
          currentTime: currentTimeCappedRef.current,
        });
        innerWrapperRef.current.style.transform = `translate(${-position}px, -50%)`;
      }

      if (nodesTimes.length === 0){
        return;
      }
      setOpacity(mainWrapperRef.current, 0);
      for (let i = 0; i < nodesTimes.length; i += 1) {
        setOpacity(mainRefs.current[i], 0);
      }
      let i = 0;
      while (i < nodesTimes.length && nodesTimes[i] < currentTimeCapped) {
        i += 1;
      }
      if (i === nodesTimes.length) {
        if (setOpacity(mainRefs.current[nodesTimes.length - 1], 1)) {
          setOpacity(mainWrapperRef.current, 1)
        }
      } else {
        if (currentTimeCapped === nodesTimes[i]) {
          if (setOpacity(mainRefs.current[i], 1)) {
            setOpacity(mainWrapperRef.current, 1)
          }
        } else {
          if (i === 0) {
            if (setOpacity(mainRefs.current[0], 1)) {
              setOpacity(mainWrapperRef.current, 1);
            }
          } else {
            const frac = (currentTimeCapped - nodesTimes[i - 1]) / (nodesTimes[i] - nodesTimes[i - 1]);
            const prevVisible = setOpacity(mainRefs.current[i - 1], 1);
            const nextVisible = setOpacity(mainRefs.current[i], frac);
            if (prevVisible && nextVisible) {
              setOpacity(mainWrapperRef.current, 1);
            } else if (prevVisible) {
              setOpacity(mainWrapperRef.current, 1 - frac);
            } else if (nextVisible) {
              setOpacity(mainRefs.current[i], 1);
              setOpacity(mainWrapperRef.current, frac);
            }
          }
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectNodes])

  useEffect(() => {
    nodesTimesOverResedRef.current = nodesTimes;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waveform, nodesTimesOverResed]);

  const dimension = Math.min(512, size.height);

  useEffect(() => {
    sizeRef.current = size;

    if (innerWrapperRef.current) {
      const position = calculatePosition({
        size: sizeRef.current,
        nodesTimes: nodesTimesOverResedRef.current,
        currentTime: currentTimeCappedRef.current,
      });
      innerWrapperRef.current.style.transform = `translate(${-position}px, -50%)`;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  return (
    <Wrapper>
      <InnerWrapper ref={innerWrapperRef}>
        {nodesTimes && nodesTimes.map((time) => {
          if (projectNodes && projectNodes[time]) {
            return (
              <StyledFirebaseImage
                key={time}
                path={`${auth.uid}/${project.id}/${projectNodes[time]}${isImageSourceUser ? '' : '.jpg'}`}
                bucket={isImageSourceUser ? config.customImagesBucket : config.samplesBucket}
                width={dimension}
                height={dimension}
                animated={false}
                lazyload={false}
              />
            );
          }

          return (
            <Placeholder key={time} style={{ width: dimension, height: dimension }}>
              <Icon type="picture" theme="filled" />
            </Placeholder>
          );
        })}
      </InnerWrapper>

      {projectNodes && Object.keys(projectNodes).length > 0 && (
        <MainWrapper
          style={{
            width: dimension / MAIN_DIMENSION_RATIO,
            height: dimension / MAIN_DIMENSION_RATIO,
          }}
          ref={mainWrapperRef}
        >
          {nodesTimes && nodesTimes.map((time, index) => {
            if (projectNodes && projectNodes[time]) {
              return (
                <StyledFirebaseImageMain
                  key={time}
                  path={`${auth.uid}/${project.id}/${projectNodes[time]}${isImageSourceUser ? '' : '.jpg'}`}
                  bucket={isImageSourceUser ? config.customImagesBucket : config.samplesBucket}
                  width={dimension / MAIN_DIMENSION_RATIO}
                  height={dimension / MAIN_DIMENSION_RATIO}
                  animated={false}
                  lazyload={false}
                  ref={(ref) => setMainRef(index, ref)}
                />
              );
            }

            return null;
          })}

          <Tooltip title={`Note that this view can help you compose your video, but to get a real view of your video you need to start a render by clicking "Render video".`}>
            <Icon type="info-circle" theme="filled" />
          </Tooltip>
        </MainWrapper>
      )}
    </Wrapper>
  );
}

export default withSize({ monitorWidth: true, monitorHeight: true })(Preview)
