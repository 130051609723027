import styled from 'styled-components';
import { Divider } from 'antd';
import { text } from 'colors';

export default styled(Divider)`
  display: flex !important;
  height: auto;
  font-weight: 400 !important;
  font-size: 17px !important;
  color: ${text} !important;
`;
