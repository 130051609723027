/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import LazyLoad from 'react-lazy-load';
import getDownloadURL, { getDownloadURLCached, clearDownloadURLCache } from 'utils/get-download-url';

const loadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

const Placeholder = styled.div`
  display: inline-block;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  overflow: hidden;

  &.animated {
    animation: ${loadingAnimation} 1.4s ease infinite;
  }

  .LazyLoad {
    line-height: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: '';
    float: left;
    padding-top: 100%;
  }

  div {
    margin: 0;
  }

  img {
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
    will-change: opacity;
    object-fit: cover;
  }

  // Empty pixel
  img[src=""] {
    opacity: 0;
    content: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
  }
`;

let globalLoadedImageUrls = new Set();
export function clearGlobalLoadedImageUrls() {
  globalLoadedImageUrls = new Set();
}

export default React.forwardRef(function ({
  path,
  bucket,
  fallbackPath,
  fallbackBucket,
  width = '100%',
  height = '100%',
  animated = true,
  lazyload = true,
  cached = true,
  ...rest
}, ref) {
  const [url, setUrl] = useState(getDownloadURLCached(bucket, path));
  const [loadedUrl, setLoadedUrl] = useState(null);
  const [isGettingUrl, setIsGettingUrl] = useState(false);
  const [isGotUrl, setIsGotUrl] = useState(false);
  const isUnmounted = useRef(false);

  function getUrl(force = false, useFallback = false) {
    const pathChosen = useFallback ? fallbackPath : path;
    const bucketChosen = useFallback ? fallbackBucket : bucket;

    if (!pathChosen) {
      return;
    }

    if (!isUnmounted.current && (force || (!url && !isGettingUrl && !isGotUrl))) {
      setIsGettingUrl(true);

      getDownloadURL(bucketChosen, pathChosen, { cached })
        .then((url) => {
          if (isUnmounted.current) {
            return;
          }

          setIsGettingUrl(false);
          setIsGotUrl(true);
          setUrl(url);
        })
        .catch((err) => {
          console.error(err);

          if (isUnmounted.current) {
            return;
          }

          if (!useFallback && fallbackPath) {
            getUrl(force, true);
          }

          setIsGotUrl(true);
        });
    }
  }

  useEffect(() => {
    if (isGettingUrl || isGotUrl) {
      getUrl(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (!lazyload) {
      getUrl();
    }

    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const img = url ? (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      src={url}
      {...rest} // className and style get overridden in the next line
      className={null}
      style={{
        ...(url === loadedUrl || globalLoadedImageUrls.has(url) ? {} : { opacity: 0 }),
        ...(rest.onClick ? { cursor: 'pointer' } : {}),
      }}
      onError={() => {
        clearDownloadURLCache(bucket, path);
        getUrl(true);
      }}
      onLoad={() => {
        globalLoadedImageUrls.add(url);
        setLoadedUrl(url);
      }}
    />
  // eslint-disable-next-line jsx-a11y/alt-text
  ) : <img src="" {...rest} />;

  return (
    <Placeholder
      style={{ width, height, ...(rest.style || {}) }}
      className={`firebase-img ${animated && !url ? 'animated' : ''} ${rest.className || ''}`}
      ref={ref}
    >
      {lazyload ? (
        <LazyLoad onContentVisible={getUrl} offset={0} throttle={200} debounce={false} once>
          {img}
        </LazyLoad>
      ) : img}
    </Placeholder>
  );
})
