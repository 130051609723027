import React from 'react';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import { Button, Icon } from 'antd';

import {
  selectPlaybackPlaying,
} from 'store/selectors';
import { brand } from 'colors';

const StyledButton = styled(Button)`
  margin: 5px 0;
  padding: 0 !important;
  width: 32px;
  height: 32px;
  line-height: 32px;
  background-color: transparent !important;

  .anticon {
    font-size: 32px;
    margin-top: -1px;
    margin-left: -1px;
    color: ${brand.primary};
  }
`;

export default function ({ playOrPause }) {
  const isPlaying = useSelector(selectPlaybackPlaying);

  return (
    <StyledButton onClick={playOrPause} type="primary" shape="circle">
      <Icon type={isPlaying ? 'pause-circle' : 'play-circle'} theme="filled" />
    </StyledButton>
  )
}
