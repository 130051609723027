import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import useSelectProject from './use-select-project';

export default function () {
  const project = useSelectProject();
  const modelId = ((project || {}).model || {}).id;
  useFirestoreConnect([
    { collection: 'models', doc: modelId, storeAs: 'modelDetails' },
  ]);
  const model = useSelector(({ firestore: { data } }) => data.modelDetails);

  if (model) {
    return {
      id: modelId,
      ...model,
    };
  }

  return undefined;
}
