import React from 'react';
import { Link } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import {
  GoogleLoginButton,
} from 'react-social-login-buttons';
import { Route } from 'react-router';

import CenteredBlock from 'components/CenteredBlock';
import LoginButton from 'components/LoginButton';
import FixedDivider from 'components/FixedDivider';
import SignupForm from './SignupForm';
import EmailSignUpButton from './EmailSignUpButton';

export default function () {
  const firebase = useFirebase();

  function doLoginWithProvider(provider, scopes) {
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    
    if (scopes) {
      scopes.forEach((scope) => {
        provider.addScope(scope);
      });
    }

    firebase.auth().signInWithRedirect(provider)
    // firebase.auth().signInWithPopup(provider)
    // .then(loginSuccessful);
  }

  function loginWithGoogle() {
    doLoginWithProvider(
      new firebase.auth.GoogleAuthProvider(),
      [],
    );
  }

  return (
    <CenteredBlock>
      <h2>Sign up for <span style={{ fontWeight: 900 }}>WZRD</span></h2>

      <div style={{ marginBottom: 30 }}>
        Already have an account? <Link to="/login">Log in</Link>
      </div>

      <Route exact path="/signup">
        <LoginButton onClick={loginWithGoogle} button={GoogleLoginButton} text="Sign up with Google" />

        <FixedDivider>OR</FixedDivider>

        <Link to="/signup/email">
          <LoginButton button={EmailSignUpButton} />
        </Link>
      </Route>

      <Route exact path="/signup/email">
        <SignupForm />
      </Route>
    </CenteredBlock>
  );
}
