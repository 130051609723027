import React from 'react';
import { Icon } from 'antd';
import { createButton } from 'react-social-login-buttons';
import { lightgrey } from 'colors';

export default createButton({
  text: 'Sign up with Email',
  icon: () => <Icon type="mail" theme="filled" />,
  style: { color: 'black', background: 'white' },
  activeStyle: { background: lightgrey[0] }
});
