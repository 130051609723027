import React, { useState, useRef, useEffect } from 'react';
import { Progress, Button, message } from 'antd';
import styled from 'styled-components';

import useSelectRender from 'hooks/use-select-render';
import useSelectProject from 'hooks/use-select-project';
import getRenderDownloadURL from 'utils/get-render-download-url';

const StyledProgress = styled(Progress)`
  display: block;
  width: 85%;
  margin: 0 auto;

  .ant-progress-bg {
    transition-duration: 1s;
    height: 10px !important;
  }
`;

const STATUS_TO_DESCRIPTION = {
  SCHEDULED: 'Waiting in queue...',
  RENDERING: 'Rendering...',
  FINALIZING: 'Finalizing...',
  SUCCESS: 'Success! Opening video...',
  ERROR: 'Something went wrong',
};
const STATUS_TO_PROGRESS_TYPE = {
  SCHEDULED: 'active',
  RENDERING: 'active',
  FINALIZING: 'active',
  SUCCESS: 'success',
  ERROR: 'exception',
};

export default function RenderProgress({ renderId, closeAndSetVideoUrl, retryRender, isStartingRender }) {
  const project = useSelectProject();
  const render = useSelectRender(renderId);
  const [isFetchingVideoUrl, setIsFetchingVideoUrl] = useState(false);
  const isUnmounted = useRef(false);

  const renderSafe = render || {};

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    }
  }, [])

  useEffect(() => {
    if (render && render.status === 'SUCCESS' && !isFetchingVideoUrl) {
      setIsFetchingVideoUrl(true);

      getRenderDownloadURL({ projectId: project.id, renderId: render.id })
        .then((returnedUrl) => {
          if (isUnmounted.current) {
            return;
          }

          closeAndSetVideoUrl(returnedUrl);
        })
        .catch((err) => {
          if (isUnmounted.current) {
            return;
          }

          message.error('Something went wrong');

          console.error(err);
        });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render])

  const status = renderSafe.status || 'SCHEDULED';

  return (
    <>
      <p className="text-center" style={{ marginTop: 5, marginBottom: 10 }}>
        {STATUS_TO_DESCRIPTION[status]}
        {status === 'ERROR' && (
          <>
            &nbsp;&nbsp;
            <Button
              onClick={retryRender}
              type="dashed"
              loading={isStartingRender}
            >
              Rerun render
            </Button>
          </>
        )}
      </p>
      <StyledProgress
        percent={((renderSafe.progress || 0) * 95) + 5}
        status={STATUS_TO_PROGRESS_TYPE[status]}
        showInfo={false}
      />
    </>
  )
}
