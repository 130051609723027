import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { magenta, purple } from 'colors';
import useSelectProject from 'hooks/use-select-project';
import { useSelector } from 'react-redux';
import { selectIsProjectImageSourceUser } from 'store/selectors';

const Wrapper = styled.div`
  display: flex;
`;

const Option = styled.div`
  flex: 1;
  border-radius: 4px;
  padding: 10px 16px 1px 16px;
  background-color: ${({ color, selected }) => color[selected ? 1 : 0]};
  cursor: pointer;
  box-shadow: ${({ color, selected }) => selected ? `0 0 0 4px ${color[2]}` : 'none'};
  transition: all 0.2s;
  user-select: none;

  .anticon {
    margin-right: 5px;
  }

  &:first-child {
    margin-right: 20px;
  }

  &:hover {
    box-shadow: ${({ color, selected }) => `0 0 0 4px ${color[selected ? 2 : 1]}`};
  }
`;

export default function RenderTypeSelect(props) {
  const history = useHistory();
  const location = useLocation();
  const project = useSelectProject();
  const isImageSourceUser = useSelector(selectIsProjectImageSourceUser);

  const isPreview = !location.pathname.includes('full');

  return (
    <Wrapper {...props}>
      <Option color={magenta} selected={isPreview} onClick={() => history.push(`/project/${project.id}/timeline/start-render`)}>
        <h3><Icon type="eye" theme="filled" /> Preview</h3>
        <p>
          {isImageSourceUser ? 'Watermarked and half fps' : 'Fast 30 second render at half fps'}
        </p>
      </Option>
      <Option color={purple} selected={!isPreview} onClick={() => history.push(`/project/${project.id}/timeline/start-render/full`)}>
        <h3><Icon type="star" theme="filled" /> Full-length</h3>
        <p>
          Full-length render at full fps
        </p>
      </Option>
    </Wrapper>
  )
}
