import React, { useState, useRef, useEffect } from 'react';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { Modal, Button, Alert, Collapse, Tooltip, message } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';

import useSelectProject from 'hooks/use-select-project';
import useSelectModel from 'hooks/use-select-model';
import useSelectUser from 'hooks/use-select-user';
import useSelectRenderWalletBalance from 'hooks/wallet/use-select-render-wallet-balance';
import { selectAudioDurationSeconds, selectIsProjectImageSourceUser } from 'store/selectors';
import GoogleAnalytics from 'utils/google-analytics';
import AdvancedRenderSettings from './AdvancedRenderSettings';
import RenderTypeSelect from './RenderTypeSelect';
import RenderProgress from './RenderProgress';

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  margin-top: 30px;
  background-color: transparent;

  .ant-collapse-item {
    border-bottom: none;

    .ant-collapse-header {
      margin-left: -16px;
      padding-right: 0;
    }

    .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-top: 17px;
  }

  .ant-modal-content {
    border-radius: ${({ title }) => title ? '4px' : '1000px'};
  }
`;

export default function RenderModal({ close, setVideoUrl }) {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const project = useSelectProject();
  const model = useSelectModel();
  const user = useSelectUser();
  const walletBalance = useSelectRenderWalletBalance();
  const [isStartingRender, setIsStartingRender] = useState(false);
  const [isStaging, setIsStaging] = useState(false);
  const [previewRenderId, setPreviewRenderId] = useState(null);
  const updateAdvancedRenderSettingsTimeout = useRef(null);
  const audioDurationSeconds = useSelector(selectAudioDurationSeconds);
  const location = useLocation();
  const isPreview = !location.pathname.includes('full');
  const isImageSourceUser = useSelector(selectIsProjectImageSourceUser);

  function startRender() {
    if (!project) {
      return;
    }

    setIsStartingRender(true);

    const startRenderFunction = firebase.functions().httpsCallable('startRender');

    const renderJob = {
      projectId: project.id,
      isPreview,
    };

    if (isStaging) {
      renderJob.isStaging = true;
    }

    startRenderFunction(renderJob)
      .then(({ data: renderId }) => {
        setIsStartingRender(false);
        if (isPreview && !isImageSourceUser) {
          setPreviewRenderId(renderId);
        }

        GoogleAnalytics.event({
          category: 'project',
          action: 'render'
        });
      })
      .catch((err) => {
        console.error(err);
        message.error('Something went wrong!');
      });
  }

  function onAdvancedRenderSettingsChanged({ data, isStaging: isStag }) {
    setIsStaging(isStag);

    if (updateAdvancedRenderSettingsTimeout.current) {
      clearTimeout(updateAdvancedRenderSettingsTimeout.current);
    }

    if (project) {
      updateAdvancedRenderSettingsTimeout.current = setTimeout(() => {
        updateAdvancedRenderSettingsTimeout.current = null;
        firestore.collection('projects').doc(project.id).update(data);
      }, 250);
    }
  }

  function doClose(force) {
    if (force || !isRendering) {
      setPreviewRenderId(null);
    }
    close();
  }

  function closeAndSetVideoUrl(url) {
    setVideoUrl(url);
    doClose(true);
  }

  useEffect(() => {
    // component will unmount
    return () => {
      if (updateAdvancedRenderSettingsTimeout.current) {
        clearTimeout(updateAdvancedRenderSettingsTimeout.current);
        updateAdvancedRenderSettingsTimeout.current = null;
      }
    };
  }, []);

  const isRendering = project && project.isRendering;
  const isPremium = (project || {}).noWatermarkAllowed;
  const startAtSeconds = (project.parameters || {}).startAtSeconds || 0;
  const endAtSeconds = (project.parameters || {}).endAtSeconds || audioDurationSeconds;
  const renderLengthMinutes = Math.floor(((endAtSeconds - startAtSeconds) / 60) * 100) / 100;
  const walletBalanceAfterRender = Math.round((walletBalance - renderLengthMinutes) * 100) / 100;
  const walletAmountToTopUp = walletBalanceAfterRender < 0 ? Math.ceil(Math.abs(walletBalanceAfterRender)) : 0;
  const hasEnoughWalletBalance = walletBalanceAfterRender >= 0;
  const hasEnoughPreviewBalance = !isImageSourceUser || ((project.numPreviewsSucceeded || 0) < 3);
  const canRender = isPreview ? (!isImageSourceUser || hasEnoughPreviewBalance) : (isPremium || hasEnoughWalletBalance);

  return (
    <StyledModal
      visible
      title={previewRenderId ? null : 'Render video'}
      width={previewRenderId ? 450 : 650}
      onCancel={!isStartingRender && !previewRenderId ? close : null}
      closable={!isStartingRender && !previewRenderId}
      footer={previewRenderId ? null : (isRendering ? (
        <>
          <Button onClick={doClose} style={{ marginRight: 8 }}>Close</Button>
        
          <Link to={`/project/${project.id}/renders`}>
            <Button type="primary">
              Go to renders
            </Button>
          </Link>
        </>
      ) : (
        <>
          <Button onClick={doClose} disabled={isStartingRender}>Cancel</Button>
        
          {canRender ? (
            <Button
              type="primary"
              onClick={startRender}
              loading={isStartingRender}
            >
              Start render
            </Button>
          ) : (isPreview ? (
            <Tooltip title="You've already created 3 previews.">
              <Button
                type="primary"
                disabled
                style={{ marginLeft: 8 }}
              >
                Start render
              </Button>
            </Tooltip>
          ) : (
            <Link to={`/account/render-wallet?amount=${walletAmountToTopUp}`} style={{ marginLeft: 8 }}>
              <Button
                type="primary"
              >
                Top up wallet
              </Button>
            </Link>
          ))}
        </>
      ))}
    >
      {previewRenderId ? (
        <RenderProgress
          renderId={previewRenderId}
          closeAndSetVideoUrl={closeAndSetVideoUrl}
          retryRender={startRender}
          isStartingRender={isStartingRender}
        />
      ) : (isRendering ? (
        <Alert
          message="Render in progress"
          description="Your video is rendering. We'll send you an email when it's done."
          type="success"
          showIcon
          banner
        />
      ) : (
        <div>
          <RenderTypeSelect
            isPreview={isPreview}
            style={{ marginBottom: 30 }}
          />

          {isPreview && !isImageSourceUser ? (
            <>Previews take only <b>one or two minutes</b> to complete and are watermarked. Create as many as you wish, for free.</>
          ) : (isPremium || hasEnoughWalletBalance || isImageSourceUser ? ( // Premium is legacy
            <>
              {isPreview && isImageSourceUser && (<>Previews are half fps, and watermarked. You can create 3 previews per project for free.&nbsp;</>)}
              {!isPremium && !isPreview && (<>Your <b>Render Wallet</b> is used for full renders. <b>Your balance will decrease from {walletBalance} to {walletBalanceAfterRender} credits.</b><br /><br /></>)}
              Depending on your audio's length, a {!isPreview ? 'full-length' : ''} render can take from <b>10 minutes to a few hours</b> to complete.<br/>We'll send you an email when it's done!
            </>
          ) : (
            <>Your <b>Render Wallet</b> is used to start full-length renders at full fps. <b>You don't have enough balance on your wallet, so you need to <Link to={`/account/render-wallet?amount=${walletAmountToTopUp}`}>top up</Link>.</b></>
          ))}

          {(isImageSourceUser || (model && model.parameters)) && (
            <StyledCollapse
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel header="Advanced settings" key="1">
                <AdvancedRenderSettings
                  project={project}
                  model={model}
                  user={user}
                  isPreview={isPreview}
                  isStaging={isStaging}
                  audioDurationSeconds={audioDurationSeconds}
                  onChange={onAdvancedRenderSettingsChanged}
                  isImageSourceUser={isImageSourceUser}
                />
              </Panel>
            </StyledCollapse>
          )}
        </div>
      ))}
    </StyledModal>
  );
}
