import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Upload, Icon, Modal, message } from 'antd';
import styled from 'styled-components';
import shortid from 'shortid';
import { useFirebase, useFirestore } from 'react-redux-firebase';

import { useSelector } from 'react-redux'

import getDownloadURL from 'utils/get-download-url';
import getCustomImages from 'utils/get-custom-images';
import getFileNameFromPath from 'utils/get-file-name-from-path';
import { red, brand, lightgrey } from 'colors';
import config from 'config';
import useSelectProject from 'hooks/use-select-project';
import useSelectAuth from 'hooks/use-select-auth';
import EnlargeImageModal from 'components/EnlargeImageModal';


const StyledUpload = styled(Upload)`
  .ant-upload {
    color: ${brand.primary};
    border-radius: 4px;
    border: none;
    transition: all 0.2s;

    .ant-upload-text {
      font-size: 90%;
    }

    .anticon {
      font-size: 130%;
    }

    &:hover {
      background: ${lightgrey[0]};
      transform: scale(1.03);
    }
  }    

  .ant-upload, .ant-upload-list-picture-card-container {
    width: 200px;
    height: 200px;
    margin: 0 20px 20px 0;
  }

  .ant-upload-list-item-thumbnail {
    opacity: 1;
  }

  .ant-upload-list-picture-card-container {
    border-radius: 4px;
  }

  .ant-upload-list-item {
    width: 200px;
    height: 200px;
    padding: 0;
    border: 0;
    border-radius: 4px;
    overflow: hidden;

    &.ant-upload-list-item-error {
      box-shadow: 0 0 0 3px ${red[3]};
    }
  }

  .ant-upload-list-item-uploading-text {
    margin: 18px;
  }

  .ant-upload-list-item-name {
    display: none !important;
  }

  .ant-upload-list-item-info {
    background: rgb(250, 250, 250);

    .anticon {
      display: none;
    }
  }

  .ant-upload-list-item-image {
    font-size: 0;

    &:before {
      display: none !important;
    }
  }
`;

export default function UploadImages() {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const auth = useSelectAuth();
  const project = useSelectProject();
  const uploadProgress = useSelector((state) => state.uploadProgress);
  const isUnmounted = useRef(false);
  const uploadedFilesURLs = useRef({});
  const [uploadedFilesURLsState, setUploadedFilesURLsState] = useState({});

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  useEffect(() => {
    let fileListLocal = [];

    getCustomImages(auth, project)
      .then((paths) => {
        if (isUnmounted.current) return;

        fileListLocal = paths.map((path) => ({
          uid: path,
          name: path,
          status: 'done',
        }));
        setFileList(fileListLocal);

        return Promise.all(paths.map((path) =>
          getDownloadURL(config.customImagesBucket, path, { cached: true })
            .then((url) => {
              fileListLocal = fileListLocal.map((file) => file.uid === path ? {
                ...file,
                url: `${url}`,
              } : file);
              // setFileList(fileListLocal); Too many state updates, which made the component hang
              // Doing it at then end is faster in terms of UI :/
            })
        ));
      })
      .then(() => {
        setFileList(fileListLocal);
      })
      .catch((err) => {
        console.error(err);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(auth || []).uid, (project || []).id]);

  const onCloseModal = useCallback(() => setIsPreviewVisible(false), []);

  const onPreview = useCallback(async file => {
    setPreviewImage(file.url);
    setIsPreviewVisible(true);
  }, []);

  const onChange = useCallback(({ fileList: fl }) => setFileList(fl), []);

  const onUploadFile = useCallback(({ file, onSuccess, onError }) => {    
    let fileExtension = '';
    if (file.name && file.name.includes('.') && file.name.lastIndexOf('.') < file.name.length - 1) {
      fileExtension = `.${file.name.substring(file.name.lastIndexOf('.') + 1)}`;
    }

    const fileUid = file.uid;
    const fileName = `${shortid.generate()}${fileExtension}`;
    const fileDir = `${auth.uid}/${project.id}`;
    const fullPath = `${fileDir}/${fileName}`;
    file.fullPath = fullPath;

    firebase.uploadFile(
      fileDir,
      file,
      null,
      { name: fileName, progress: true, bucket: 'wzrd-3585d-custom-images' },
    )
      .then((result) => {
        onSuccess(result);
        return getDownloadURL(config.customImagesBucket, fullPath)
          .then((url) => {
            uploadedFilesURLs.current[fileUid] = url;
            setUploadedFilesURLsState({...uploadedFilesURLs.current});
          })
          .catch(console.error);
      })
      .then(() => {})
      .catch(onError);
  }, [firebase, auth, project]);

  const onRemove = useCallback((file) => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: 'Are you sure you want to delete this image?',
        okText: 'Yes',
        onOk: (close) => {
          resolve(true);
          close();
          
          // Delete file in storage
          const fileRef = firebase.app().storage(config.customImagesBucket).ref().child(file.fullPath || file.uid);
          fileRef.delete().then(() => {});
          
          // Unselect from timeline
          const fileName = getFileNameFromPath(file.fullPath || file.uid);
          const nodeEntries = Object.entries(project.nodes || {});
          let timeToDelete = null;
          for (let i = 0; i < nodeEntries.length; i += 1) {
            const [time, fN] = nodeEntries[i];
            if (fN === fileName) {
              timeToDelete = time;
              break;
            }
          }
          if (timeToDelete !== null) {
            const newNodes = {
              ...(project.nodes || {}),
            };
            delete newNodes[timeToDelete];
            firestore.collection('projects').doc(project.id).update({
              nodes: newNodes,
              updatedAt: new Date(),
            });
          }
        },
        onCancel: (close) => {
          resolve(false);
          close();
        },
      });
    });
  }, [firebase, firestore, project]);

  const fileListWithProgress = fileList.map((file) => ({
    ...file,
    percent: uploadProgress[file.uid] || 0,
    url: file.url ? file.url : uploadedFilesURLsState[file.uid],
  }));
  
  return (
    <div className="clearfix">
      <StyledUpload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileListWithProgress}
        onPreview={onPreview}
        onChange={onChange}
        multiple
        name="file"
        accept="image/png,image/jpeg"
        beforeUpload={(file) => {
          const maxMegs = 5;
          const isOkSize = file.size / 1024 / 1024 < maxMegs;

          if (!isOkSize) {
            message.error(`File must smaller than ${maxMegs}MB!`);
            return false;
          }

          return true;
        }}
        customRequest={onUploadFile}
        onRemove={onRemove}
      >
        <Icon type="plus" />
        <div className="ant-upload-text">Add images</div>
      </StyledUpload>

      <EnlargeImageModal
        src={previewImage}
        visible={isPreviewVisible}
        onClose={onCloseModal}
      />
    </div>
  );
}
