import React from 'react';
import { useDrag } from 'react-dnd';
import FirebaseImage from './FirebaseImage';

export default function ({ dragProps, ...props }) {
  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'FirebaseImage', ...(dragProps || {}) },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <FirebaseImage
      {...props}
      style={{
        ...(props.style || {}),
        ...(isDragging ? { opacity: 0.2 } : {}),
      }}
      ref={dragRef}
    />
  );
}
