import React, { useState, useEffect, useRef } from 'react';

import { Button, message } from 'antd';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

import getRenderDownloadURL from 'utils/get-render-download-url';
import useSelectProject from 'hooks/use-select-project';
import useSelectRender from 'hooks/use-select-render';
import aspectRatioFromParams from 'utils/aspect-ratio-from-params';
import SpanHideSm from 'components/SpanHideSm';


export default function ({ style, renderId }) {
  const project = useSelectProject();
  const render = useSelectRender(renderId);

  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(false);
  const isUnmounted = useRef(false);

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    }
  }, [])

  return (
    <>
      <Button
        shape="round"
        icon="eye"
        style={style}
        onClick={() => {
          if (!project || !project.id || !renderId) {
            return;
          }

          setIsLoading(true);

          getRenderDownloadURL({ projectId: project.id, renderId })
            .then((returnedUrl) => {
              if (isUnmounted.current) {
                return;
              }

              setIsLoading(false);
              setUrl(returnedUrl);
            })
            .catch((err) => {
              if (isUnmounted.current) {
                return;
              }

              setIsLoading(false);
              message.error('Something went wrong');

              console.error(err);
            });
        }}
        loading={isLoading}
      >
        <SpanHideSm>Watch</SpanHideSm>
      </Button>

      <ModalVideo
        channel='custom'
        isOpen={url ? true : false}
        url={url}
        onClose={() => setUrl(null)}
        ratio={aspectRatioFromParams((render || {}).parameters)}
      />
    </>
  );
}
