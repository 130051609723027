import React from 'react';
import { Alert, Button, Icon } from 'antd';
import { Link } from 'react-router-dom';

import useSelectProject from 'hooks/use-select-project';
import UploadImages from './UploadImages';

export default function () {
  const project = useSelectProject();

  return (
    <>
      <Alert
        message="Upload the images you would like to see in your video"
        description="Next, you'll be able to put your images on your audio timeline"
        type="info"
        showIcon
        banner
        style={{ marginTop: 6, marginBottom: 30 }}
      />

      <UploadImages />

      <div style={{ flex: 1, textAlign: 'right', marginTop: 24 }}>
        <Link to={`/project/${(project || {}).id}/timeline`}>
          <Button
            type="primary"
            size="large" 
          >
            <Icon type="caret-right" />
            Next step
          </Button>
        </Link>
      </div>
    </>
  );
}
