import { getFirebase } from 'react-redux-firebase';

export default function (options) {
  const firebase = getFirebase();

  const getRenderDownloadLinkFunction = firebase.functions().httpsCallable('getRenderDownloadLink');

  return getRenderDownloadLinkFunction(options)
    .then(({ data }) => {
      return data;
    });
}
