import React, { useEffect, useRef, useState, useMemo } from 'react';
import { isLoaded, useFirestore, useFirebase } from 'react-redux-firebase'
import { useSelector } from 'react-redux';
import { Modal, Button } from 'antd';
import { useHistory } from 'react-router-dom';

import useSelectAuth from 'hooks/use-select-auth';
import useSelectProject from 'hooks/use-select-project';
import getSampleIdFromPath from 'utils/get-sample-id-from-path';
import getCustomImages from 'utils/get-custom-images';
import getFileNameFromPath from 'utils/get-file-name-from-path';
import { selectIsProjectImageSourceUser, selectNodesTimes } from 'store/selectors';
import config from 'config';

export default function ({ visible, close }) {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const project = useSelectProject();
  const isImageSourceUser = useSelector(selectIsProjectImageSourceUser);
  const nodesTimes = useSelector(selectNodesTimes);
  const auth = useSelectAuth();
  const isUnmounted = useRef(false);
  const [isFilling, setIsFilling] = useState(false);
  const history = useHistory();
  const [customImagePaths, setCustomImagesPaths] = useState([]);

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (!isImageSourceUser) {
      return;
    }

    getCustomImages(auth, project)
      .then((paths) => {
        if (isUnmounted.current) return;
        setCustomImagesPaths(paths)
      })
      .catch((err) => {
        console.error(err);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(auth || []).uid, (project || []).id]);

  if (!isLoaded(project) || !nodesTimes) {
    return null;
  }

  const pickedImageIds = useMemo(() => {
    const set = new Set();
    Object.values(project.nodes || {}).forEach((sampleId) => {
      set.add(sampleId);
    });
    return set;
  }, [project.nodes]);

  const unFilledNodeTimes = nodesTimes.filter((time) => !(project.nodes || {})[time]);
  const unPickedImages = isImageSourceUser ?
    customImagePaths
      .map(getFileNameFromPath)
      .filter((fileName) => !pickedImageIds.has(fileName)) :
    (project.favourites || []).filter((sampleId) => !pickedImageIds.has(sampleId));

  function pickImagesAtRandom() {
    const neededAmount = unFilledNodeTimes.length;
    const randomImages = [...unPickedImages].sort(() => 0.5 - Math.random()).slice(0, neededAmount);

    const updatedNodes = project.nodes || {};
    unFilledNodeTimes.forEach((time, index) => {
      if (time || time === 0) {
        updatedNodes[time] = randomImages[index];
      }
    });

    firestore.collection('projects').doc(project.id).update({
      nodes: updatedNodes,
      updatedAt: new Date(),
    });
  }

  function fetchAllSampleIds() {
    return firebase.app().storage(config.samplesBucket).ref().child(`${auth.uid}/${project.id}/`).listAll()
      .then((response) => {
        const sampleIds = response.items
          .filter((item) => item.fullPath.endsWith('.jpg'))
          .map((item) => getSampleIdFromPath(item.fullPath));

        return sampleIds;
      });
  }

  function fetchAndPickRandomSamples() {
    setIsFilling(true);

    // Step 1: Get available samples from storage
    return fetchAllSampleIds()
      .then((sampleIds) => {
        if (isUnmounted.current) return;

        let unPickedImageIds = sampleIds.filter((sampleId) => !pickedImageIds.has(sampleId));

        // Step 2: If needed, generate more samples
        let generateMoreSamplesPromise;
        const neededAmount = unFilledNodeTimes.length;

        if (neededAmount <= unPickedImageIds.length) {
          generateMoreSamplesPromise = Promise.resolve(sampleIds);
        } else {
          const generateAmount = neededAmount - unPickedImageIds.length;
          const generateSamplesFunction = firebase.functions().httpsCallable('generateSamples');

          generateMoreSamplesPromise = generateSamplesFunction({
            projectId: project.id,
            modelCode: project.model.code,
            amount: generateAmount,
          }).then(() => {
            if (isUnmounted.current) return;
            return fetchAllSampleIds();
          });
        }

        return generateMoreSamplesPromise
          .then((updatedSampleIds) => {
            if (isUnmounted.current) return;

            unPickedImageIds = updatedSampleIds.filter((sampleId) => !pickedImageIds.has(sampleId));

            // Step 3: Auto-fill
            const randomSamples = unPickedImageIds
              .filter((sampleId) => !unPickedImages.includes(sampleId))
              .sort(() => 0.5 - Math.random())
              .slice(0, unFilledNodeTimes.length - unPickedImages.length);
            randomSamples.push(...unPickedImages);
            randomSamples.sort(() => 0.5 - Math.random());

            const updatedNodes = project.nodes || {};
            unFilledNodeTimes.forEach((time, index) => {
              updatedNodes[time] = randomSamples[index];
            });

            firestore.collection('projects').doc(project.id).update({
              nodes: updatedNodes,
              updatedAt: new Date(),
            });

            setIsFilling(false);
          });
      })
      // .catch((err) => {
      //   console.error(err);
      //   if (isUnmounted.current) return;
      //   setIsFetchedSamples(true);
      //   setIsFetchingSamples(false);
      // }) 
  }

  function safeClose() {
    if (!isFilling) {
      close();
    }
  }

  let inner = null;
  let footer = null;

  // Option 1: No open spaces -> message and cancel
  if (unFilledNodeTimes.length === 0) {
    inner = (
      <p>{'There\'s no space left to auto-fill. You\'re ready to render your video, awesome!'}</p>
    );
    footer = (
      <Button type="primary" onClick={safeClose}>OK</Button>
    );

  // Option 2: Open spaces, and enough unpicked favorites to fill -> propose to fill
  } else if (unFilledNodeTimes.length <= unPickedImages.length) {
    inner = (
      <p>Do you want to auto-fill with your remaining {isImageSourceUser ? 'images' : 'favorites'}?</p>
    );
    footer = (
      <>
        <Button onClick={safeClose} disabled={isFilling}>Cancel</Button>
        <Button
          type="primary"
          onClick={() => {
            pickImagesAtRandom();
            safeClose();
          }}
          loading={isFilling}
        >
          Auto-fill
        </Button>
      </>
    );

  // Option 3: Open spaces, not enough favorites to fill -> propose to pick at random
  //           -> behind the scenes, generate more samples if needed!!
  // If custom images -> link to upload images
  } else {
    inner = (
      <p>{`You don't have enough remaining ${isImageSourceUser ? 'images' : 'favorites'} to auto-fill everything. ${isImageSourceUser ? 'Go to the Images page to upload more.' : 'Do you want to fill the rest with random images?'}`}</p>
    );
    footer = (
      <>
        <Button onClick={safeClose} disabled={isFilling}>Cancel</Button>
        <Button
          type="primary"
          onClick={() => {
            if (isImageSourceUser) {
              history.push(`/project/${project.id}`);
            } else {
              fetchAndPickRandomSamples().then(() => safeClose());
            }
          }}
          loading={isFilling}
        >
          {isImageSourceUser ? 'Upload more images' : 'Auto-fill'}
        </Button>
      </>
    );
  }

  return (
    <Modal
      visible={visible}
      onCancel={safeClose}
      title="Auto-fill images"
      footer={footer}
    >
      {inner}
    </Modal>
  );
}
