import React, { useState, useEffect, useRef } from 'react';

import { Button, Modal, message } from 'antd';
import styled from 'styled-components';

import getRenderLogs from 'utils/get-render-logs';
import useSelectProject from 'hooks/use-select-project';
import SpanHideSm from 'components/SpanHideSm';

const Logs = styled.div`
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 8pt;
`;

export default function ({ style, renderId }) {
  const project = useSelectProject();

  const [isLoading, setIsLoading] = useState(false);
  const isUnmounted = useRef(false);

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    }
  }, [])

  return (
    <Button
      shape="round"
      icon="file-text"
      style={style}
      onClick={() => {
        if (!project || !project.id || !renderId) {
          return;
        }

        setIsLoading(true);

        getRenderLogs({ projectId: project.id, renderId })
          .then((data) => {
            if (isUnmounted.current) {
              return;
            }

            setIsLoading(false);

            const logs = Buffer.from(data).toString('utf8');
            
            Modal.info({
              icon: null,
              content: <Logs>{logs}</Logs>,
              width: 1080,
              maskClosable: true,
            });
          })
          .catch((err) => {
            if (isUnmounted.current) {
              return;
            }

            setIsLoading(false);
            message.error('Something went wrong');

            console.error(err);
          });
      }}
      loading={isLoading}
    >
      <SpanHideSm>Logs</SpanHideSm>
    </Button>
  );
}
