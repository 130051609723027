import { getFirebase } from 'react-redux-firebase';
import config from 'config';

export default function (auth, project) {
  if (!auth || !project) {
    return Promise.resolve([]);
  }

  const firebase = getFirebase();

  return firebase.app().storage(config.customImagesBucket).ref().child(`${auth.uid}/${project.id}/`).listAll()
    .then((response) => response.items.map((item) => item.fullPath));
}
