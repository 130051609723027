import { forceSimulation, forceCollide } from 'd3';

export const MINIMUM_SECONDS_BETWEEN_NODES = 2;

export default function fixCustomNodes(nodes, audioDurationSeconds, fixedIndex) {
  const entries = Object.entries(nodes).map(([timeStr, image]) => [Number.parseFloat(timeStr), image]);

  if (entries.length === 0) {
    return nodes;
  }
  entries.sort(([a,], [b,]) => a - b);

  // Temporary solution -> equally spaced
  if (entries.length > 0 && entries[0][0] !== 0) {
    entries.splice(0, 0, [0, 'dummy']);
  }
  if (entries.length > 0 && entries[entries.length - 1][0] !== audioDurationSeconds) {
    entries.push([audioDurationSeconds, 'dummy']);
  }

  const d3Nodes = entries.map(([time, image], index) => {
    let fixedTime = time;
    if (fixedTime < 0) {
      fixedTime = index === 0 ? 0 : 0.001;
    } else if (fixedTime > audioDurationSeconds) {
      fixedTime = index === entries.length - 1 ? audioDurationSeconds : (audioDurationSeconds - 0.001);
    }
    
    // const fix = index === 0 ||
    //   index === entries.length - 1 ||
    //   (index === fixedIndex &&
    //     fixedTime > MINIMUM_SECONDS_BETWEEN_NODES &&
    //     fixedTime < (audioDurationSeconds - MINIMUM_SECONDS_BETWEEN_NODES));
    // Temporary solution -> equally spaced
    const fix = index === 0 ||
      index === entries.length - 1;
      // (index === fixedIndex &&
      //   fixedTime > MINIMUM_SECONDS_BETWEEN_NODES &&
      //   fixedTime < (audioDurationSeconds - MINIMUM_SECONDS_BETWEEN_NODES));

    return {
      index,
      x: fixedTime,
      y: 0,
      vx: 0,
      vy: 0,
      ...(fix ? { fx: fixedTime } : {}),
      fy: 0,
      image,
    };
  });

  const simulation = forceSimulation(d3Nodes)
    // .force('collision', forceCollide().radius(MINIMUM_SECONDS_BETWEEN_NODES / 2));
    // Temporary solution -> equally spaced
    .force('collision', forceCollide().radius(audioDurationSeconds / (((d3Nodes.length - 1) * 2) || 1)));
  simulation.stop();
  simulation.tick(300);

  const fixedNodes = simulation.nodes().reduce((fixed, d3Node, index) => {
    if (d3Node.image === 'dummy') {
      return fixed;
    }

    let fixedTime = d3Node.x;
    if (fixedTime !== 0 && fixedTime !== audioDurationSeconds) {
      // round to millisecond
      fixedTime = Math.round(fixedTime * 1000) / 1000;
    }

    if (fixedTime < 0 || fixedTime > audioDurationSeconds) {
      return fixed;
    }

    fixed[fixedTime] = d3Node.image;
    return fixed;
  }, {});

  if (fixedIndex !== null) {
    // Do another round without fixedIndex, to avoid "squeezing" frames to the border (start or end)
    return fixCustomNodes(fixedNodes, audioDurationSeconds, null);
  } else {
    return fixedNodes;
  }
}
