import React, { useState, useRef, useEffect } from 'react';
import { Modal, Input, message } from 'antd';
import { useFirebase } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'redux-firestore';

export default function ({ visible, close }) {
  const firebase = useFirebase();
  const [checkText, setCheckText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const isUnmounted = useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const doDelete = () => {
    if (checkText !== 'DELETE') {
      return message.error('Please type DELETE to validate');
    }

    setIsDeleting(true);

    const deleteAccountFunction = firebase.functions().httpsCallable('deleteAccount');

    deleteAccountFunction()
      .then(() => {
        if (!isUnmounted.current) {
          setIsDeleting(false);
          close();
        }
        message.success('Your account was deleted. You\'re being logged out.');
        firebase.logout();
        history.push('/');
        dispatch({ type: actionTypes.CLEAR_DATA });
      })
      .catch((err) => {
        console.error(err);
        if (isUnmounted.current) return;
        message.error('Something went wrong. Please try again or contact us.');
        setIsDeleting(false);
      });
  }

  return (
    <Modal
      title="Delete this account"
      onCancel={() => {
        if (!isDeleting) {
          close();
        }
      }}
      visible={visible}
      okText="Delete account"
      onOk={doDelete}
      okType="danger"
      confirmLoading={isDeleting}
      closable={!isDeleting}
    >
      <p>
        Deleting your account deletes your user, render wallet, all your projects, and all related images, audio, and video.<br />
        Type <b>DELETE</b> to continue:
      </p>

      <Input
        value={checkText}
        onChange={(e) => setCheckText(e.target.value)}
      />
    </Modal>
  );
}
