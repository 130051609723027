import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom';

export default function (renderId) {
  const { projectId } = useParams();
  const reduxKey = `render.${projectId}.${renderId}`;
  useFirestoreConnect([
    { collection: `projects/${projectId}/renders`, doc: renderId, storeAs: reduxKey },
  ]);
  const render = useSelector(({ firestore: { data } }) => data[reduxKey]);

  if (render) {
    return {
      id: renderId,
      ...render,
    };
  }

  return render;
}
