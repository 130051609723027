import React, { useState, useRef, useEffect } from 'react';
import { Modal, InputNumber } from 'antd';
import useSelectAuth from 'hooks/use-select-auth';

const DEFAULT_AMOUNT = 7;

export default function ({ visible, close }) {
  const Paddle = window.Paddle;
  const auth = useSelectAuth();
  const [amount, setAmount] = useState(DEFAULT_AMOUNT);
  const [grossPriceDisplay, setGrossPriceDisplay] = useState(null);
  const fetchPriceTimeout = useRef(null);

  function openCheckout() {
    const userId = auth.uid;

    if (!userId) {
      return;
    }

    Paddle.Checkout.open({
      product: 660875,
      allowQuantity: true,
      quantity: amount || 1,
      email: auth.email,
      passthrough: JSON.stringify({ userId }),
      successCallback: () => {
        window.location.hash = 'checkout-complete';
        window.location.reload();
      },
    });
  }

  function setAmountAndUpdatePrice(v) {
    setAmount(v);
    setGrossPriceDisplay(null);

    if (fetchPriceTimeout.current) {
      clearTimeout(fetchPriceTimeout.current);
    }

    fetchPriceTimeout.current = setTimeout(() => {
      fetchPriceTimeout.current = null;

      Paddle.Product.Prices(660875, v, (data) => {
        setGrossPriceDisplay(((data || {}).price || {}).gross || null);
      })
    }, 500);
  }

  useEffect(() => {
    setAmountAndUpdatePrice(DEFAULT_AMOUNT);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title="Render Wallet Top-up"
      onCancel={close}
      visible={visible}
      okText="Continue"
      onOk={openCheckout}
    >
      <p>
        How many credits would you like?
      </p>

      <InputNumber
        value={amount}
        onChange={setAmountAndUpdatePrice}
        min={1}
      />&nbsp;&nbsp;credits for {grossPriceDisplay}
    </Modal>
  );
}
