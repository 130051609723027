import React from 'react';
import { Avatar } from 'antd';

import {
  volcano,
  orange,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta,
} from 'colors';

const colors = [
  [volcano[5], volcano[1]],
  [orange[5], orange[1]],
  [lime[6], lime[1]],
  [green[6], green[1]],
  [cyan[6], cyan[1]],
  [blue[5], blue[1]],
  [geekblue[5], geekblue[1]],
  [purple[5], purple[1]],
  [magenta[5], magenta[1]],
];

function getInitials(name) {
  return name.split(' ').map((n) => n[0]).join('');
}

function getColorForName(name) {
  let score = 0;
  for (let i = 0; i < name.length; i += 1) {
    score += name.charCodeAt(i);
  }

  return colors[score % colors.length];
}

export default function ({ user, style, ...otherProps }) {
  if (!user) {
    return null;
  }

  if (user.photoURL) {
    return (
      <Avatar src={user.photoURL} style={style} {...otherProps} />
    )
  }

  if (!user.displayName) {
    return <Avatar style={style} {...otherProps} />
  }

  const initials = getInitials(user.displayName);
  const color = getColorForName(user.displayName);

  return (
    <Avatar
      style={{ color: color[0], backgroundColor: color[1], ...(style || {}) }}
      {...otherProps}
    >
      {initials}
    </Avatar>
  );
}
