import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage'; 
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore

import parseCookies from 'utils/parse-cookies';
import GoogleAnalytics from 'utils/google-analytics';
import configureStore, { history } from './store/configure'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

GoogleAnalytics.set({ dimension2: process.env.REACT_APP_BRANCH });
const cookies = parseCookies();
if (cookies.siteBranch) {
  GoogleAnalytics.set({ dimension1: cookies.siteBranch });
}
const isDev = !window.location.host.includes('wzrd.ai');
const oneWeekFromNow = new Date(Date.now() + (7 * 24 * 3600 * 1000));
document.cookie = `appBranch=${process.env.REACT_APP_BRANCH}${isDev ? '' : `;domain=.wzrd.ai;path=/;SameSite=Lax;expires=${oneWeekFromNow.toUTCString()}`}`;

const fbConfig = {
  apiKey: 'AIzaSyC1v5J7BfFcxVJi2NE0BrpoxnY1JhXJfhU',
  // authDomain: 'wzrd-3585d.firebaseapp.com',
  authDomain: 'auth.wzrd.ai',
  databaseURL: 'https://wzrd-3585d.firebaseio.com',
  projectId: 'wzrd-3585d',
  storageBucket: "wzrd-3585d.appspot.com",
  messagingSenderId: '544139982611',
  appId: '1:544139982611:web:3c2687d2f1225b67b18ce6',
};
// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  // enableClaims: true, // Get custom claims along with the profile
};
// Initialize firebase instance
firebase.initializeApp(fbConfig);
firebase.firestore(); // <- needed if using firestore
firebase.functions(); // <- needed if using httpsCallable

const initialState = {};
const store = configureStore(initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
let resizeTimeout = null;
window.addEventListener('resize', () => {
  if (resizeTimeout) {
    clearTimeout(resizeTimeout);
  }

  resizeTimeout = setTimeout(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, 200);
});

GoogleAnalytics.initialize(history);

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
