import React from 'react';
import { Form, Slider, Radio, Input, Select } from 'antd';
import styled from 'styled-components';
import HelpTooltip from 'components/HelpTooltip';
import {
  validateRule as validateStartEndAtRule,
  parse as parseStartEndAt,
  stringify as stringifyStartEndAt,
} from 'utils/start-end-at';
import { lightgrey } from 'colors';

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 5px;
`;

const AspectRatioOption = styled(({ value, ...props }) => <span {...props}><span>{value}<span></span></span></span>)`
  display: inline-block;
  width: 3.5em;
  
  > span {
    display: flex;
    align-items: center;

    > span {
      height: 1px;
      background: ${lightgrey[3]};
      flex: 1;
      margin: 0 4px;
    }
  }
`;

function getDefaultParameters(props) {
  const model = props.model || {};
  const modelParameters = model.parameters || {};
  const modelPercusPushFactor = modelParameters.percus_push_factor || null;
  const modelPercusEnergyFactors = modelParameters.percus_energy_factors || [];

  const defaultParameters = {};

  defaultParameters.pushFactor = modelPercusPushFactor || 7;
  defaultParameters.lowFrequencyImpact = modelPercusEnergyFactors[0] || 0.25;
  defaultParameters.midFrequencyImpact = modelPercusEnergyFactors[1] || 0.6;
  defaultParameters.highFrequencyImpact = modelPercusEnergyFactors[2] || 0.15;
  defaultParameters.outputShapeUser = modelParameters.output_shape || [1024, 1024];

  return defaultParameters;
}

// From /10 to x10
// To -10 to +10
function multiplierToSlider(multiplier) {
  if (multiplier >= 1) {
    return multiplier - 1;
  }

  // if (multiplier < 1)
  return -(1 / multiplier) + 1;
}

// From -10 to +10
// To /10 to x10
function sliderToMultiplier(slider) {
  if (slider >= 0) {
    return slider + 1;
  }

  // if (slider < 0)
  return 1 / (-slider + 1);
}

function sliderToMultiplierDisplay(slider) {
  const multiplier = sliderToMultiplier(slider);
  
  if (multiplier >= 1) {
    return `x${multiplier.toFixed(1)}`;
  }

  return `/${(Math.round(10 / multiplier) / 10).toFixed(1)}`;
}

function getResolutionOptions(isImageSourceUser, defaultParameters) {
  if (isImageSourceUser) {
    return [
      {
        resolution: [768, 768],
        aspectRatioDisplay: '1:1',
        pDisplay: null,
      },
      {
        resolution: [1024, 576],
        aspectRatioDisplay: '16:9',
        pDisplay: null,
      },
      {
        resolution: [576, 1024],
        aspectRatioDisplay: '9:16',
        pDisplay: null,
      },
    ];
  }

  const outputShapeUser = defaultParameters.outputShapeUser;

  const minDim = Math.min(outputShapeUser[0], outputShapeUser[1]);

  const resolutionOptions = [
    {
      resolution: outputShapeUser,
      aspectRatioDisplay: outputShapeUser[0] === outputShapeUser[1] ? '1:1' : 'Cstm',
      pDisplay: null,
    },
    {
      resolution: [Math.round(minDim * 16 / 9), minDim],
      aspectRatioDisplay: '16:9',
      pDisplay: null,
    },
    {
      resolution: [minDim, Math.round(minDim * 16 / 9)],
      aspectRatioDisplay: '9:16',
      pDisplay: null,
    },
  ];

  if (outputShapeUser[0] > 1000 || outputShapeUser[1] > 1000) {
    resolutionOptions.push(...[
      {
        resolution: [1280, 720],
        aspectRatioDisplay: '16:9',
        pDisplay: '720p',
      },
      {
        resolution: [720, 1280],
        aspectRatioDisplay: '9:16',
        pDisplay: null,
      },
    ]);
  } else {
    resolutionOptions.push(...[
      {
        resolution: [860, 480],
        aspectRatioDisplay: '16:9',
        pDisplay: '480p',
      },
      {
        resolution: [480, 860],
        aspectRatioDisplay: '9:16',
        pDisplay: null,
      },
    ]);
  }

  return resolutionOptions;
}

class AdvancedRenderSettings extends React.Component {
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: this.props.isImageSourceUser ? 6 : 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: this.props.isImageSourceUser ? 18 : 14 },
      },
    };

    const defaultParameters = getDefaultParameters(this.props);

    const project = this.props.project;
    const projectParameters = project.parameters || {};
    const projectPercusPushFactor = projectParameters.percus_push_factor || null;
    const projectPercusEnergyFactors = projectParameters.percus_energy_factors || [];
    const projectOutputShapeUser = projectParameters.output_shape_user || null;

    const initialPushFactor = projectPercusPushFactor || defaultParameters.pushFactor;
    const initialPushFactorSlider = multiplierToSlider(initialPushFactor / defaultParameters.pushFactor);
    const initialLowFrequencyImpact = projectPercusEnergyFactors[0] || defaultParameters.lowFrequencyImpact;
    const initialLowFrequencyImpactSlider = multiplierToSlider(initialLowFrequencyImpact / defaultParameters.lowFrequencyImpact);
    const initialMidFrequencyImpact = projectPercusEnergyFactors[1] || defaultParameters.midFrequencyImpact;
    const initialMidFrequencyImpactSlider = multiplierToSlider(initialMidFrequencyImpact / defaultParameters.midFrequencyImpact);
    const initialHighFrequencyImpact = projectPercusEnergyFactors[2] || defaultParameters.highFrequencyImpact;
    const initialHighFrequencyImpactSlider = multiplierToSlider(initialHighFrequencyImpact / defaultParameters.highFrequencyImpact);
    let initialOutputShapeUser;
    if (this.props.isImageSourceUser) {
      initialOutputShapeUser = projectOutputShapeUser || getResolutionOptions(this.props.isImageSourceUser, defaultParameters)[0].resolution;
    } else {
      initialOutputShapeUser = projectOutputShapeUser || defaultParameters.outputShapeUser;
    }

    return (
      <Form {...formItemLayout} labelAlign="left" colon={false}>
        <StyledFormItem label={<span>Resolution {/*(soon PRO-only) */}<HelpTooltip text={this.props.isImageSourceUser ? 'Select your desired output video size' : 'Using neural padding, you can increase the video resolution'} /></span>}>
          {getFieldDecorator('outputShapeUser', {
            initialValue: "768x768",
          })(
            <Select disabled>
              <Select.Option key="768x768" value="768x768">
                Coming soon - average image aspect ratio is used
              </Select.Option>
            </Select>
          )}
        </StyledFormItem>

        {!this.props.isImageSourceUser && (
          <>
            <StyledFormItem label={<span>Resolution {/*(soon PRO-only) */}<HelpTooltip text={this.props.isImageSourceUser ? 'Select your desired output video size' : 'Using neural padding, you can increase the video resolution'} /></span>}>
              {getFieldDecorator('outputShapeUser', {
                initialValue: `${initialOutputShapeUser[0]}x${initialOutputShapeUser[1]}`,
              })(
                <Select>
                  {getResolutionOptions(this.props.isImageSourceUser, defaultParameters).map((option, index) => {
                    const { resolution, aspectRatioDisplay, pDisplay } = option;
                    const resolutionDisplay = `${resolution[0]}x${resolution[1]}`;
                    return (
                      <Select.Option key={resolutionDisplay} value={resolutionDisplay}>
                        <AspectRatioOption value={aspectRatioDisplay} />{resolutionDisplay} {pDisplay ? `(${pDisplay})` : null} {index === 0 ? '(Fastest)' : null}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </StyledFormItem>

            <StyledFormItem label={<span>Start at <HelpTooltip text="You can change the start time of render here" /></span>}>
              {getFieldDecorator('startAtSeconds', {
                initialValue: stringifyStartEndAt(projectParameters.startAtSeconds),
                rules: [{ validator: validateStartEndAtRule, audioDurationSeconds: this.props.audioDurationSeconds, isStart: true }],
              })(
                <Input placeholder="0:00" style={{ width: '5em' }} />
              )}
            </StyledFormItem>

            <StyledFormItem label={<span>End at <HelpTooltip text="You can change the end time of the render here" /></span>}>
              {getFieldDecorator('endAtSeconds', {
                initialValue: stringifyStartEndAt(projectParameters.endAtSeconds),
                rules: [
                  { validator: validateStartEndAtRule, audioDurationSeconds: this.props.audioDurationSeconds, isStart: false },
                  { validator: (data, value, callback) => {
                    validateStartEndAtRule(
                      { audioDurationSeconds: this.props.audioDurationSeconds, isStart: false },
                      value,
                      (error) => {
                        if (error || !value || value === '') {
                          return callback();
                        }

                        const startAtSeconds = parseStartEndAt(this.props.audioDurationSeconds, getFieldValue('startAtSeconds')) || 0;
                        const endAtSeconds = parseStartEndAt(this.props.audioDurationSeconds, value);
                        if (endAtSeconds <= startAtSeconds) {
                          return callback('End must be before start');
                        }
                        callback();
                      },
                    );
                  }},
                ],
              })(
                <Input placeholder={stringifyStartEndAt(this.props.audioDurationSeconds)} style={{ width: '5em' }} />
              )}
            </StyledFormItem>

            <StyledFormItem label={<span>Push factor <HelpTooltip text="Defines how much each bass pushes the visual forward" /></span>}>
              {getFieldDecorator('pushFactor', { initialValue: initialPushFactorSlider })(
                <Slider min={-4} max={4} step={0.1} tipFormatter={sliderToMultiplierDisplay} />
              )}
            </StyledFormItem>

            <StyledFormItem label={<span>Low frequency impact <HelpTooltip text="Defines how much the visual shape gets distorted by low frequency sounds" /></span>}>
              {getFieldDecorator('lowFrequencyImpact', { initialValue: initialLowFrequencyImpactSlider })(
                <Slider min={-4} max={4} step={0.1} tipFormatter={sliderToMultiplierDisplay} />
              )}
            </StyledFormItem>

            <StyledFormItem label={<span>Mid frequency impact <HelpTooltip text="Defines how much the visual gets distorted by mid frequency sounds" /></span>}>
              {getFieldDecorator('midFrequencyImpact', { initialValue: initialMidFrequencyImpactSlider })(
                <Slider min={-4} max={4} step={0.1} tipFormatter={sliderToMultiplierDisplay} />
              )}
            </StyledFormItem>

            <StyledFormItem label={<span>High frequency impact <HelpTooltip text="Defines how much the visual color gets distorted by high frequency sounds" /></span>}>
              {getFieldDecorator('highFrequencyImpact', { initialValue: initialHighFrequencyImpactSlider })(
                <Slider min={-4} max={4} step={0.1} tipFormatter={sliderToMultiplierDisplay} />
              )}
            </StyledFormItem>
          </>
        )}

        {this.props.user.isAdmin && (
          <StyledFormItem label="Environment">
            {getFieldDecorator('isStaging', { initialValue: this.props.isStaging })(
              <Radio.Group>
                <Radio.Button value={false}>Production</Radio.Button>
                <Radio.Button value={true}>Staging</Radio.Button>
              </Radio.Group>
            )}
          </StyledFormItem>
        )}
      </Form>
    );
  }
}

export default Form.create({
  name: 'advanced-render-settings',
  onValuesChange: (props, changedValues, allValues) => {
    const oldParameters = props.project.parameters || {};
    const defaultParameters = getDefaultParameters(props);

    const parameters = {
      ...oldParameters,
      percus_push_factor: sliderToMultiplier(allValues.pushFactor) * defaultParameters.pushFactor,
      percus_energy_factors: [
        sliderToMultiplier(allValues.lowFrequencyImpact) * defaultParameters.lowFrequencyImpact,
        sliderToMultiplier(allValues.midFrequencyImpact) * defaultParameters.midFrequencyImpact,
        sliderToMultiplier(allValues.highFrequencyImpact) * defaultParameters.highFrequencyImpact,
      ],
      output_shape_user: allValues.outputShapeUser ? allValues.outputShapeUser.split('x').map((v) => parseInt(v)) : null,
    };

    const startAtSeconds = parseStartEndAt(props.audioDurationSeconds, allValues.startAtSeconds)
    if (startAtSeconds || startAtSeconds === 0) {
      parameters.startAtSeconds = startAtSeconds;
    } else {
      delete parameters.startAtSeconds;
    }

    const endAtSeconds = parseStartEndAt(props.audioDurationSeconds, allValues.endAtSeconds)
    if (endAtSeconds) {
      parameters.endAtSeconds = endAtSeconds;
    } else {
      delete parameters.endAtSeconds;
    }

    props.onChange({
      data: {
        parameters,
        updatedAt: new Date(),
      },
      ...(props.user.isAdmin ? { isStaging: allValues.isStaging } : {}),
    });
  },
})(AdvancedRenderSettings);
