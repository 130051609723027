import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import useSelectAuth from 'hooks/use-select-auth';

export default function () {
  const auth = useSelectAuth();
  
  useFirestoreConnect([
    { collection: 'users', doc: auth.uid, storeAs: 'userDetails' },
  ]);
  const user = useSelector(({ firestore: { data } }) => data.userDetails);

  return user;
}
