import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Select } from 'antd';
import { withSize } from 'react-sizeme';

import useSelectProject from 'hooks/use-select-project';
import useSelectAuth from 'hooks/use-select-auth';
import Transition from './Transition';
import HideChildrenAfterAnimation from './HideChildrenAfterAnimation';
import { ANIMATION_DURATION_SECONDS, FOOTER_HEIGHT, KEY_FRAME_TO_INTER_FRAME_DIMENSION, NUMBER_OF_INTER_FRAMES, NUMBER_OF_SEEDS, SEED_BACKGROUND_PADDING, SPACING_BETWEEN_IMAGES, SPACING_BETWEEN_ROWS } from './config';
import { getDownloadURLCached } from 'utils/get-download-url';
import config from 'config';
import EnlargeImageModal from 'components/EnlargeImageModal';

const Wrapper = styled.div`
  min-height: calc(100vh - 64px);
  margin: -24px 0 -48px 0;
  display: flex;
  flex-direction: column;
`;

const TransitionWrapperWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
`;

const TransitionWrapper = styled.div`
  position: absolute;
  white-space: nowrap;
  transition: transform ${ANIMATION_DURATION_SECONDS}s;
  will-change: transform;
`;

const StyledSelect = styled(Select)`
  .ant-select-selection {
    padding-right: 7px;
  }
`;

function Review({ size }) {
  const auth = useSelectAuth();
  const project = useSelectProject();
  const projectNodes = (project || {}).nodes || {};
  const [transitionIndex, setTransitionIndex] = useState(0);
  const [showEnlargeImageModal, setShowEnlargeImageModal] = useState(null);
  const [enlargeImageModalSource, setEnlargeImageModalSource] = useState(null);

  const projectNodesEntries = Object.entries(projectNodes);
  projectNodesEntries.sort(([a,], [b,]) => Number.parseFloat(a) - Number.parseFloat(b));
  const projectNodesImages = projectNodesEntries.map(([, a]) => a);
  const numOfTransitions = projectNodesImages.length - 1;

  const imageMaxHeight = (size.height - FOOTER_HEIGHT - (NUMBER_OF_SEEDS * SPACING_BETWEEN_ROWS) - (3 * SEED_BACKGROUND_PADDING)) / NUMBER_OF_SEEDS;
  const widthRowWithoutImages = (SPACING_BETWEEN_IMAGES * 4) + (2 * SEED_BACKGROUND_PADDING) +
    (SPACING_BETWEEN_IMAGES * (NUMBER_OF_INTER_FRAMES - 1));
  const imageMaxWidth = (size.width - widthRowWithoutImages) / (NUMBER_OF_INTER_FRAMES + (2 * KEY_FRAME_TO_INTER_FRAME_DIMENSION));
  const imageDimension = Math.min(256, imageMaxHeight, imageMaxWidth);
  const widthRowWithoutLastImage = ((imageDimension * 2.5) + (SPACING_BETWEEN_IMAGES * 2)) +
    ((imageDimension + SPACING_BETWEEN_IMAGES) * NUMBER_OF_INTER_FRAMES) + SPACING_BETWEEN_IMAGES
    + (2 * SEED_BACKGROUND_PADDING);
  const widthRow = widthRowWithoutLastImage + (imageDimension * 2.5);
  const heightTransition = (NUMBER_OF_SEEDS * SPACING_BETWEEN_ROWS) + (NUMBER_OF_SEEDS * imageDimension);

  function enlargeImage(path) {
    setShowEnlargeImageModal(true);
    setEnlargeImageModalSource(getDownloadURLCached(config.customImagesBucket, path));
  }

  return (
    <Wrapper>
      <TransitionWrapperWrapper
        style={{
          marginTop: Math.max(0, (size.height - FOOTER_HEIGHT - heightTransition) / 2),
          width: widthRow,
        }}
      >
        <TransitionWrapper
          style={{
            transform: `translateX(${-widthRowWithoutLastImage * transitionIndex}px)`,
          }}
        >
          {projectNodesImages.map((_, index) => {
            let transition = null;
            if (transitionIndex - 1 <= index && index <= transitionIndex + 2) {
              transition = (
                <Transition
                  key={`t${index}`}
                  project={project}
                  auth={auth}
                  imageDimension={imageDimension}
                  projectNodesImages={projectNodesImages}
                  transitionIndex={index}
                  widthRowWithoutLastImage={widthRowWithoutLastImage}
                  enlargeImage={enlargeImage} 
                />
              );
            }

            // The user could click next very fast. At the same time, we don't want to render
            // everything since that would just be too much. The problem then is that we get
            // these glitches since the animation is too slow and the elements are already being
            // removed. The solution is to only hide the elements after the animation. This
            // neat little wrapper component does this.
            return (
              <HideChildrenAfterAnimation
                key={index}
                delay={ANIMATION_DURATION_SECONDS * 1000}
              >
                {transition}
              </HideChildrenAfterAnimation>
            );
          })}
        </TransitionWrapper>
      </TransitionWrapperWrapper>

      <div style={{ height: FOOTER_HEIGHT, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <StyledSelect defaultValue="123" style={{ marginRight: 20 }}>
            <Select.Option value="123">Timeline v1 - 2023-05-01</Select.Option>
          </StyledSelect>

          <Button.Group size="medium" style={{ marginRight: 10 }}>
            <Button
              type="primary"
              icon="left"
              disabled={transitionIndex === 0}
              onClick={() => setTransitionIndex(transitionIndex - 1)}
            />
            <Button
              type="primary"
              icon="right"
              disabled={transitionIndex === numOfTransitions - 1}
              onClick={() => setTransitionIndex(transitionIndex + 1)}
            />
          </Button.Group>

          {transitionIndex + 1} / {numOfTransitions}
        </div>

        <div>
          <Button type="primary">
            Render full video
          </Button>
        </div>
      </div>

      <EnlargeImageModal
        src={enlargeImageModalSource}
        visible={showEnlargeImageModal}
        onClose={() => setShowEnlargeImageModal(false)}
      />
    </Wrapper>
  );
}

export default withSize({ monitorWidth: true, monitorHeight: true })(Review)
