import React from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import { compose } from 'redux';
import GoogleAnalytics from 'utils/google-analytics';

class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSigningUp: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isSigningUp: true });

        this.props.firebase.auth().createUserWithEmailAndPassword(values.email, values.password)
          .then((result) => {
            this.setState({ isSigningUp: false });
            message.success('You successfully signed up!');

            // If you change the following, also change it somewhere else ;)
            const userRef = this.props.firestore.collection('/users').doc(result.user.uid);

            userRef.get()
              .then((userSnapshot) => {
                const data = {
                  displayName: result.user.displayName || values.displayName,
                  photoURL: result.user.photoURL,
                  email: result.user.email,
                  hasAcceptedTerms: true,
                  hasGivenNewsletterPreference: true,
                };

                if (userSnapshot.exists) {
                  userRef.update(data);

                } else {
                  userRef.set(data);
                }

                GoogleAnalytics.event({
                  category: 'user',
                  action: 'create-user'
                });

                this.props.firebase.functions()
                  .httpsCallable('signUpComplete')()
                  .then(() => {})
                  .catch(console.error);

                this.props.firebase.functions()
                  .httpsCallable(values.optInForNewsletter ? 'optInForNewsletter' : 'optOutForNewsletter')()
                  .then(() => {})
                  .catch(console.error);
              })
              .catch(console.error);

            result.user.sendEmailVerification()
              .then(() => {})
              .catch((error) => {
                if (error.message) {
                  message.error(error.message);
                }
                console.error(error);
              });
          })
          .catch((error) => {
            this.setState({ isSigningUp: false });
            message.error(error.message);
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('displayName', {
            rules: [
              { required: true, whitespace: true, message: 'Please input your name' },
              { max: 20, message: 'This can\'t be more than 20 characters long' }
            ],
          })(
            <Input
              placeholder="(Nick)name"
              size="large"
              disabled={this.state.isSigningUp}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Please input your email' },
              {
                type: 'email',
                message: 'This is not a valid email address',
              },
            ],
          })(
            <Input
              placeholder="Email"
              size="large"
              disabled={this.state.isSigningUp}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: 'Please input your password' },
              { min: 8, message: 'Password must be at least 8 characters long' }
            ],
          })(
            <Input
              type="password"
              placeholder="Password"
              size="large"
              disabled={this.state.isSigningUp}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password2', {
            rules: [
              { required: true, message: 'Please confirm your password' },
              {
                validator: (rule, value, callback) => {
                  const { form } = this.props;
                  if (value && value !== form.getFieldValue('password')) {
                    callback('The two passwords are not equal');
                  } else {
                    callback();
                  }
                },
              },
            ],
          })(
            <Input
              type="password"
              placeholder="Confirm password"
              size="large"
              disabled={this.state.isSigningUp}
            />
          )}
        </Form.Item>
        <Form.Item style={{ marginBottom: 7 }}>
          {getFieldDecorator('optInForNewsletter')(
            <Checkbox disabled={this.state.isSigningUp} style={{ lineHeight: 1.5 }}>
              I'd like to receive the occasional email about new visual themes, features, discounts, and more 
            </Checkbox>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('hasAcceptedTerms', {
            rules: [
              {
                validator: (rule, value, callback) => {
                  if (value !== true) {
                    callback('You need to accept the terms');
                  } else {
                    callback();
                  }
                },
              },
            ],
          })(
            <Checkbox disabled={this.state.isSigningUp}>
              I agree to WZRD's <a target="_blank" href='/terms-of-use'>Terms of Use</a>
            </Checkbox>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={this.state.isSigningUp}
          >
            Sign up
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default compose(
  withFirebase,
  withFirestore,
  Form.create({ name: 'signup-with-email' })
)(SignupForm);
