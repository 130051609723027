import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  color: white;
  border-left: 1px solid #ffffff30;
  height: 32px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  transition: opacity 0.3s;
`;

function getProjectIdFromLocation(location) {
  const { pathname } = location;

  const prefix = '/project/';
  if (pathname.startsWith(prefix)) {
    const end = pathname.indexOf('/', prefix.length);
    return pathname.substring(prefix.length, end === -1 ? pathname.length : end);
  }

  return null;
}

export default function ProjectName() {
  const location = useLocation();
  const project = useSelector(({ firestore: { data } }) => data[`projectDetails.${getProjectIdFromLocation(location)}`]);

  if (!location.pathname.startsWith('/project')) {
    return null;
  }

  return (
    <Wrapper style={{ opacity: project ? 1 : 0 }}>
      <div style={{ lineHeight: 1.2, maxHeight: '2.4em', overflow: 'hidden' }}>
        {project ? project.name : ''}
      </div>
    </Wrapper>
  );
}
