import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Slider, Icon } from 'antd';
import styled from 'styled-components';
import { brand } from 'colors';

const StyledSlider = styled(Slider)`
  margin-top: 6px;
  margin-bottom: 6px;

  .ant-slider-track {
    background: ${brand.primary};
  }
  .ant-slider-handle {
    border-color: ${brand.primary};
  }
`;

export default function () {
  const pixelsPerSecond = useSelector((state) => state.view.pixelsPerSecond);
  const dispatch = useDispatch();

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%', maxWidth: 250 }}>
      <Icon type="zoom-in" />

      <StyledSlider
        value={pixelsPerSecond}
        min={8}
        max={52}
        onChange={(value) => dispatch({
          type: 'SET_PIXELS_PER_SECOND',
          pixelsPerSecond: value,
        })}
        tooltipVisible={false}
        style={{ flex: 1 }}
      />
    </div>
  );
}
