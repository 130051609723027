import React from 'react';
import { Form, Input, Icon, Button, Upload, message, InputNumber, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { isLoaded } from 'react-redux-firebase'
import { firebaseConnect } from 'react-redux-firebase';
import shortid from 'shortid';
import styled from 'styled-components';

import { brand } from 'colors';
import ModelPreviewModal from './ModelPreviewModal';
import ModelCard from './ModelCard';
// import MakeYourOwn from './MakeYourOwn';
import SelectAudioSampleModal from './SelectAudioSampleModal';
import { isMobile } from 'react-device-detect';
import ProjectTypeSelect from './ProjectTypeSelect';

const StyledForm = styled(Form)`
  max-width: 960px;
  margin: 0 auto;
`;

const ModelWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  margin-bottom: 30px;
  margin-top: 5px;

  @media (max-width: 500px) {
    padding-left: 0;
    margin-left: -24px;
  }
`;

// const StyledRadioGroup = styled(Radio.Group)`
//   .ant-radio-wrapper {
//     display: block;
//     margin-top: 15px;
//     white-space: normal;

//     small {
//       display: inline-block;
//       line-height: 1.75;
//     }
//   }
// `;


class NewProjectForm extends React.Component {
  state = {
    confirmDirty: false,
    previewModel: null,
    audioUploadSuccess: false,
    isSelectAudioSampleVisible: false,
    successfullySelectedSample: null,
  };

  constructor(props) {
    super(props);

    this.uploadProgress = {};
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!this.state.audioUploadSuccess) {
        return message.error('Audio file is not uploaded yet');
      }

      if (!values.audioDuration || values.audioDuration >= 15 * 60) {
        return message.error('Currently only audio files less than 15 minutes long are supported');
      }

      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  handeUploadFile = ({ file, setFieldsValue, onProgress, onSuccess, onError }) => {
    this.setState({ audioUploadSuccess: false });

    this.file = file;
    this.onProgress = onProgress;

    let fileExtension = '';
    if (file.name && file.name.includes('.') && file.name.lastIndexOf('.') < file.name.length - 1) {
      fileExtension = `.${file.name.substring(file.name.lastIndexOf('.') + 1)}`;
    }

    const fileName = `${shortid.generate()}${fileExtension}`;
    const fileDir = this.props.userId;
    const filePath = `${fileDir}/${fileName}`;

    // Get audio duration
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    audio.addEventListener('error', onError);
    audio.addEventListener('canplaythrough', () => {
      setFieldsValue({ audioDuration: audio.duration });

      this.props.firebase.uploadFile(
        fileDir,
        file,
        null,
        { name: fileName, progress: true, bucket: 'wzrd-3585d-audio' },
      )
        .then(() => {
          this.file = null;
          this.onProgress = null;
          onSuccess();
          setFieldsValue({ audioFilePath: filePath });
          this.setState({ audioUploadSuccess: true });
        })
        .catch((err) => {
          this.file = null;
          this.onProgress = null;
          onError(err);
        });
    });
  }

  openPreview(model) {
    this.setState({ previewVisible: true, previewModel: model });
  }

  render() {
    if (this.file && this.props.uploadProgress[this.file.uid] && this.onProgress) {
      const progress = this.props.uploadProgress[this.file.uid];

      if (this.uploadProgress[this.file.uid] !== progress) {
        this.uploadProgress[this.file.uid] = progress;
        setTimeout(() => {
          if (this.onProgress) {
            this.onProgress({ percent: progress }, this.file);
          }
        }, 1);
      }
    }

    const { getFieldDecorator, setFieldsValue, getFieldValue } = this.props.form;

    const imageSource = getFieldValue('imageSource');

    if (imageSource !== 'THEME' && getFieldValue('model') !== null) {
      setTimeout(() => {
        setFieldsValue({ model: null });
      }, 0);
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    return (
      <StyledForm
        {...formItemLayout}
        hideRequiredMark
        onSubmit={this.handleSubmit}
        labelAlign="left"
        colon={false}
      >
        <Typography>
          <Typography.Title level={2}>
            New project
          </Typography.Title>
        </Typography>


        <Form.Item label="Give your project a name">
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: 'Please input a project name', whitespace: true },
              { max: 256, message: 'This name is too long' },
            ],
          })(
            <Input
              placeholder="Stairway to Heaven?"
              size="large"
              style={{ maxWidth: 500, paddingTop: 23, paddingBottom: 23 }}
            />
          )}
        </Form.Item>

        <Form.Item label="Upload your music">
          {getFieldDecorator('audioFilePath', {
            trigger: null,
            rules: [
              { required: true, message: 'Please add an audio file' },
            ],
          })(
            this.state.successfullySelectedSample ? (
              <p style={{ color: brand.primary }}>
                <Icon type="check" style={{ marginRight: 5 }} />
                Selected audio sample&nbsp;<b>{this.state.successfullySelectedSample.title}</b>
              </p>
            ) : (
              <div>
                <Upload.Dragger
                  name="file"
                  multiple={false}
                  accept="audio/mpeg,audio/wav,audio/mp3" // Need to include audio/mpeg to enable drag&drop of MP3 files
                  beforeUpload={(file) => {
                    const maxMegs = 25;
                    const isOkSize = file.size / 1024 / 1024 < maxMegs;

                    if (!isOkSize) {
                      message.error(`File must smaller than ${maxMegs}MB!`);
                      return false;
                    }

                    return true;
                  }}
                  customRequest={({ file, onProgress, onSuccess, onError }) => {
                    this.handeUploadFile({ file, setFieldsValue, onProgress, onSuccess, onError });
                  }}
                  onChange={(info) => {
                    const { status } = info.file;

                    if (status === 'done') {
                      message.success(`${info.file.name} file uploaded successfully.`);
                    } else if (status === 'error') {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                  }}
                  style={{ paddingTop: 30, paddingBottom: 40 }}
                  disabled={this.props.submitting}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="file-add" theme="twoTone" />
                  </p>
                  <p className="ant-upload-text">Select an audio file</p>
                  <p className="ant-upload-hint">
                    MP3 or WAV
                  </p>
                </Upload.Dragger>

                {!this.file && !getFieldValue('audioFilePath') && (
                  <Button
                    onClick={() => this.setState({ isSelectAudioSampleVisible: true })}
                    type="link"
                    style={{ paddingLeft: 0 }}
                  >
                    Or choose a sample!
                  </Button>
                )}
              </div>
            )
          )}
        </Form.Item>

        {this.props.isAdmin ? (
          <Form.Item label="How do you want to create your visuals?">
            {getFieldDecorator('imageSource', {
              rules: [
                { required: true, message: 'Please choose a project type' },
              ],
            })(
              <ProjectTypeSelect onChange={(value) => setFieldsValue({ imageSource: value })} />
            )}
          </Form.Item>
        ) : getFieldDecorator('imageSource', { initialValue: "THEME" })(<span />)}

        {/* Enable setting this field, see below */}
        {getFieldDecorator('parameters', { initialValue: {} })(<span />)}

        {imageSource === 'THEME' && (
          <Form.Item label="Choose a theme">
            {getFieldDecorator('model', {
              rules: [{ required: true, message: 'Please choose a visual theme' }],
            })(
              <ModelWrapper>
                {isLoaded(this.props.models) && (
                  <>
                    {this.props.models
                      .map((model) => (
                        <ModelCard
                          key={model.id}
                          model={model}
                          selected={getFieldValue('model') && getFieldValue('model').id === model.id}
                          onSelect={() => {
                            if (!this.props.submitting) {
                              const previousModel = getFieldValue('model');
                              if (previousModel && previousModel.id === model.id) {
                                setFieldsValue({ model: null });
                              } else {
                                setFieldsValue({
                                  model: { id: model.id, code: model.code },
                                  ...(
                                    (model.parameters || {}).nodes_per_minute ?
                                    { parameters: { nodes_per_minute: model.parameters.nodes_per_minute } } :
                                    {}
                                  ),
                                });
                              }
                            }
                          }}
                          onPreview={() => this.openPreview(model)}
                        />
                      ))}

                      {/* <MakeYourOwn /> */}
                  </>
                )}
              </ModelWrapper>
            )}
          </Form.Item>
        )}

        <Form.Item style={{ display: 'none' }}>
          {getFieldDecorator('audioDuration', {
            rules: [
              { required: true, message: 'Please add an audio duration' },
            ],
          })(
            <InputNumber />
          )}
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', marginTop: 30 }}>
            <Link to="/" style={{ marginBottom: 15, ...(isMobile ? { width: '100%' } : {}) }}>
              <Button
                size="large"
                style={{ marginRight: 15 }}
                block={isMobile}
              >
                Cancel
              </Button>
            </Link>

            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={this.props.submitting}
              block={isMobile}
            >
              Continue
            </Button>
          </div>
        </Form.Item>

        <ModelPreviewModal
          visible={this.state.previewVisible}
          model={this.state.previewModel}
          close={() => this.setState({ previewVisible: false })}
        />

        <SelectAudioSampleModal
          visible={this.state.isSelectAudioSampleVisible}
          close={() => this.setState({ isSelectAudioSampleVisible: false })}
          setFieldsValue={setFieldsValue}
          handeUploadFile={this.handeUploadFile}
          onSuccessfulSampleSelection={(sample) => this.setState({ successfullySelectedSample: sample })}
        />
      </StyledForm>
    );
  }
}

export default firebaseConnect()(Form.create({ name: 'new-project' })(NewProjectForm));
