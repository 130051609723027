import React, { useEffect, useState } from 'react';
import { isLoaded, useFirestore } from 'react-redux-firebase'
import { useDispatch, useSelector } from 'react-redux'
import { DndProvider } from 'react-dnd';
import { HTML5Backend }  from 'react-dnd-html5-backend';

import { Button, Spin, Modal, Popconfirm } from 'antd';
import styled from 'styled-components';

import useSelectProject from 'hooks/use-select-project';
import { selectIsProjectImageSourceUser, selectNodeSpacing, selectWaveform } from 'store/selectors';
import ZoomSlider from './ZoomSlider';
import Waveform from './Waveform';
import SamplePicking from './SamplePicking';
import SamplePickingCustomImages from './SamplePickingCustomImages';
import Preview from './Preview';
import AudioPlayback from './AudioPlayback/index';
import AutoFillModal from './AutoFillModal';

let helpModalShown = false;

const Wrapper = styled.div`
  min-height: calc(100vh - 64px);
  margin: -24px -24px -48px -24px;
  display: flex;
  flex-direction: column;
`;

export default function () {
  const firestore = useFirestore();

  const dispatch = useDispatch();
  const project = useSelectProject();
  const isImageSourceUser = useSelector(selectIsProjectImageSourceUser);
  const waveform = useSelector(selectWaveform);
  const nodeSpacing = useSelector(selectNodeSpacing);
  const [isAutoFillModalVisible, setIsAutoFillModalVisible] = useState(false);

  const isAudioAnalysisReady = waveform && nodeSpacing;
  const audioAnalysisNotReadyString = isAudioAnalysisReady ? null :
    (waveform != null ? 'Loading audio analysis...' :
      (nodeSpacing == null ? 'Loading audio analysis and waveform...' : 'Loading waveform...')
    );

  useEffect(() => {
    if (!project) {
      return;
    }
  
    dispatch({ type: 'FETCH_WAVEFORM_IF_NEEDED_REQUESTED' });
    dispatch({ type: 'FETCH_NODE_SPACING_IF_NEEDED_REQUESTED' });
    dispatch({ type: 'FETCH_AUDIO_URL_IF_NEEDED_REQUESTED' });

    if (!helpModalShown && Object.keys(project.nodes || {}).length === 0) {
      helpModalShown = true;

      Modal.info({
        title: 'Welcome to Timeline!',
        content: isImageSourceUser ? (
          <span>Please add your images to the audio timeline by clicking the plus icon when hovering over the waveform.
          After that, hit the <b>Render video</b> button to continue to the next step.</span>
        ) : (
          <span>You can either fill in every empty spot by hand, or choose <b>Auto-fill</b> to quickly fill all available spots on the audio timeline.
          After that, hit the <b>Render video</b> button, which creates a visual that smoothly travels between your chosen images, and responds to your audio.</span>
        ),
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(project || {}).id]);

  function clearAllNodes() {
    firestore.collection('projects').doc(project.id).update({
      nodes: {},
      updatedAt: new Date(),
    });
  }

  if (!isLoaded(project)) {
    return null;
  }

  return (
    <Wrapper>
      {isAudioAnalysisReady ? (
        <>
          <div style={{ flex: 1, display: 'flex' }}>
            <Preview />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 24px' }}>
            {isImageSourceUser ? (
              <div style={{ flex: '0 1 300px' }}></div>
            ) : (
              <div style={{ margin: '2px 0', flex: '0 1 300px', minWidth: 250 }}>
                <Button onClick={() => setIsAutoFillModalVisible(true)} type="primary" style={{ marginRight: 10 }}>
                  Auto-fill images
                </Button>

                <Popconfirm title="Are you sure you want to clear all images?" onConfirm={clearAllNodes} okText="Yes">
                  <Button type="dashed">Clear</Button>
                </Popconfirm>
              </div>
            )}

            <ZoomSlider />

            <div style={{ flex: '0 1 300px' }}></div>
          </div>

          <div style={{ overflowX: 'auto', minHeight: 200 }}>
            <div style={{ margin: '0 24px' }}>
              <Waveform />

              <DndProvider backend={HTML5Backend}>
                {isImageSourceUser ?
                  <SamplePickingCustomImages /> :
                  <SamplePicking />}
              </DndProvider>
            </div>
          </div>

          <AudioPlayback />

          <AutoFillModal
            visible={isAutoFillModalVisible}
            close={() => setIsAutoFillModalVisible(false)}
          />
        </>
      ) : (
        <Spin size="large" style={{ marginTop: '25vh' }} tip={audioAnalysisNotReadyString}></Spin>
      )}
    </Wrapper>
  );
}
