import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import useSelectAuth from 'hooks/use-select-auth';

export default function (walletsCollection) {
  const auth = useSelectAuth();

  useFirestoreConnect(auth.uid ? [
    { collection: walletsCollection, doc: auth.uid, storeAs: 'renderWallet' },
  ] : []);
  const wallet = useSelector(({ firestore: { data } }) => data.renderWallet);

  return wallet;
}
