import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

import { lightgrey, magenta } from 'colors';
import { selectNodesTimes, selectWaveform } from 'store/selectors';
import useSelectAuth from 'hooks/use-select-auth';
import useSelectProject from 'hooks/use-select-project';
import SelectImageModal from '../SelectImageModal';
import DroppableNodeTime from './DroppableNodeTime'

const Wrapper = styled.div`
  position: relative;
  height: 80px;
  margin: 0 auto;
  will-change: width;
  transition: width 0.3s;

  > div {
    position: absolute;
    top: 0;
    width: 80px;
    height: 80px;
    background-color: ${lightgrey[0]};
    border-radius: 0 4px 4px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transform-origin: 50% 100%;
    will-change: left;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s, left 0.3s;
    cursor: pointer;

    &.selected {
      z-index: 3;
      background-color: ${magenta[3]} !important;

      &:after {
        border-left-color: ${magenta[3]} !important;
      }

      .anticon {
        color: white;
      }
    }

    &.grabbable {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    &:hover {
      z-index: 3;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: -10px;
      left: 0;
      width: 0; 
      height: 0; 
      border-top: 10px solid transparent;
      border-left: 10px solid ${lightgrey[8]};
      transition: border-left-color 0.3s;
    }

    .firebase-img {
      z-index: 2;
      border-radius: 0 4px 4px 4px;
    }

    .anticon {
      font-size: 30px;
      color: ${lightgrey[3]};
      transition: color 0.3s;
    }
  }
`

export default function () {
  const firestore = useFirestore();
  const auth = useSelectAuth();
  const project = useSelectProject();

  const waveform = useSelector(selectWaveform);
  const samplesPerSecond = (waveform || {}).num_samples_per_second || 2; // default is 2 samples per second
  const waveformData = (waveform || {}).data || waveform;
  const pixelsPerSecond = useSelector((state) => state.view.pixelsPerSecond);
  const pixelsPerBar = pixelsPerSecond / samplesPerSecond;
  const width = waveformData.length * pixelsPerBar;

  const nodesTimes = useSelector(selectNodesTimes);

  const [isSelectImageModalVisible, setIsSelectImageModalVisible] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);

  function selectFavourite(sampleId) {
    if (project && (selectedTime || selectedTime === 0)) {
      firestore.collection('projects').doc(project.id).update({
        nodes: {
          ...(project.nodes || {}),
          [selectedTime]: sampleId,
        },
        updatedAt: new Date(),
      });
    }
  }

  function swap(time1, time2) {
    if (project) {
      const existingNodes = project.nodes || {};
      const newNodes = {
        ...existingNodes,
      };

      if (existingNodes[time1]) {
        newNodes[time2] = existingNodes[time1];
      } else {
        delete newNodes[time2];
      }

      if (existingNodes[time2]) {
        newNodes[time1] = existingNodes[time2];
      } else {
        delete newNodes[time1];
      }

      firestore.collection('projects').doc(project.id).update({
        nodes: newNodes,
        updatedAt: new Date(),
      });

      setSelectedTime(null);
    }
  }

  function clear(time) {
    if (project) {
      const existingNodes = project.nodes || {};
      const newNodes = {
        ...existingNodes,
      };

      delete newNodes[time];

      firestore.collection('projects').doc(project.id).update({
        nodes: newNodes,
        updatedAt: new Date(),
      });
    }
  }

  if (!nodesTimes || nodesTimes.length === 0) {
    return <Wrapper />
  }

  return (
    <Wrapper style={{ width }}>
      {nodesTimes.map((time, index) => (
        <DroppableNodeTime
          key={index}
          time={time}
          index={index}
          setSelectedTime={setSelectedTime}
          setIsSelectImageModalVisible={setIsSelectImageModalVisible}
          clear={clear}
          swap={swap}
          project={project}
          pixelsPerSecond={pixelsPerSecond}
          auth={auth}
        />
      ))}

      <SelectImageModal
        visible={isSelectImageModalVisible}
        close={() => {
          setIsSelectImageModalVisible(false);
          setSelectedTime(null);
        }}
        onSelect={(favourite) => selectFavourite(favourite)}
      />
    </Wrapper>
  );
}
