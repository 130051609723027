import React, { useEffect, useRef, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { Modal } from 'antd';
import styled from 'styled-components';

import config from 'config';
import FirebaseImage from 'components/FirebaseImage';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-top: 0;

    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }
`;

const NUMBER_OF_SAMPLES = 16;

export default function ({ visible, close, model }) {
  const firebase = useFirebase();
  const isUnmounted = useRef(false);
  const [samplesPaths, setSamplesPaths] = useState(null);
  const [modelIdFetched, setModelIdFetched] = useState(null);

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (!model) {
      return;
    }

    const modelId = model.id;

    firebase.app().storage(config.previewSamplesBucket).ref().child(`${model.code}/`).list({ maxResults: NUMBER_OF_SAMPLES * 2 })
      .then((response) => {
        if (isUnmounted.current) return;

        if ((model || {}).id !== modelId) {
          return;
        }

        setModelIdFetched(modelId);

        setSamplesPaths(
          response.items
            .filter((item) => item.fullPath.endsWith('.jpg'))
            .map((item) => item.fullPath)
        );
      })
      .catch((err) => {
        console.error(err);
        if (isUnmounted.current) return;
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  return (
    <StyledModal
      visible={visible}
      title={(model || {}).name}
      onOk={close}
      onCancel={close}
      footer={null}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={1000}
    >
      {(!model || model.id === modelIdFetched) && samplesPaths ? samplesPaths.map((path) => (
        <FirebaseImage
          key={path}
          path={path}
          bucket={config.previewSamplesBucket}
        />
      )) : Array.from(new Array(NUMBER_OF_SAMPLES).keys()).map((_, index) => (
        <FirebaseImage
          key={index}
        />
      ))}
    </StyledModal>
  );
}
