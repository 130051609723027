import React from 'react';
import { Link } from 'react-router-dom';
import CenteredBlock from 'components/CenteredBlock';
import ForgotPasswordForm from './ForgotPasswordForm';

export default function () {
  return (
    <CenteredBlock>
      <h2>Forgot password</h2>

      <ForgotPasswordForm />

      <div style={{ marginTop: 10 }}>
        <Link to="/login">Return to log in</Link>
      </div>
    </CenteredBlock>
  );
}
