import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useFirebase, useFirestore, isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { Alert, Button, Icon, Progress } from 'antd';
import styled from 'styled-components';

import { lightgrey } from 'colors';
import config from 'config';
import useSelectAuth from 'hooks/use-select-auth';
import useSelectProject from 'hooks/use-select-project';
import { selectNumberOfNodes } from 'store/selectors';
import getSampleIdFromPath from 'utils/get-sample-id-from-path';
import DelayedSpin from 'components/DelayedSpin';
import Sample from './Sample';

const StyledProgress = styled(Progress)`
  position: fixed;
  top: 63px;
  z-index: 1;
  height: 8px;
  line-height: 8px;
  margin-left: -48px;

  @media(max-width: 576px) {
    margin-left: -24px;
  }

  .ant-progress-outer {
    height: 8px;

    .ant-progress-inner {
      margin-top: -3px;
      border-radius: 0px !important;
      background-color: rgba(234, 234, 234, 0.5);

      .ant-progress-bg {
        border-radius: 0px !important;
      }
    }
  }
`;

export default function () {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();

  const project = useSelectProject();
  const auth = useSelectAuth();
  const numberOfNodes = useSelector(selectNumberOfNodes);

  const [isFetchedSamples, setIsFetchedSamples] = useState(false);
  const [isFetchingSamples, setIsFetchingSamples] = useState(false);
  const [samplesPaths, setSamplesPaths] = useState(null);
  const [isGeneratingSamples, setIsGeneratingSamples] = useState(false);
  const [isGeneratedSamples, setIsGeneratedSamples] = useState(false);
  const isUnmounted = useRef(false);

  useEffect(() => {
    if (!project) {
      return;
    }

    setIsGeneratedSamples(false);
    setIsGeneratingSamples(false);
    setIsFetchedSamples(false);
    setIsFetchingSamples(false);
    setSamplesPaths(null)
  
    dispatch({ type: 'FETCH_WAVEFORM_IF_NEEDED_REQUESTED' });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(project || {}).id]);

  function fetchSamples() {
    if (isUnmounted.current || !project) {
      return;
    }

    setIsFetchingSamples(true);

    firebase.app().storage(config.samplesBucket).ref().child(`${auth.uid}/${project.id}/`).listAll()
      .then((response) => {
        if (isUnmounted.current) return;
        setSamplesPaths(
          response.items
            .filter((item) => item.fullPath.endsWith('.jpg'))
            .map((item) => item.fullPath)
        );
        setIsFetchedSamples(true);
        setIsFetchingSamples(false);
      })
      .catch((err) => {
        console.error(err);
        if (isUnmounted.current) return;
        setIsFetchedSamples(true);
        setIsFetchingSamples(false);
      })
  }

  function generateSamples() {
    if (isUnmounted.current || !project || !project.model || !project.model.code) {
      return;
    }

    setIsGeneratingSamples(true);

    const generateSamplesFunction = firebase.functions().httpsCallable('generateSamples');

    generateSamplesFunction({
      projectId: project.id,
      modelCode: project.model.code,
      amount: 50,
    })
      .then(() => {
        if (isUnmounted.current) return;
        fetchSamples();
        setIsGeneratedSamples(true);
        setIsGeneratingSamples(false);
      })
      .catch((err) => {
        console.error(err);
        if (isUnmounted.current) return;
        fetchSamples();
        setIsGeneratedSamples(true);
        setIsGeneratingSamples(false);
      });
  }

  function removeFavourite(sampleId) {
    if (project) {
      firestore.collection('projects').doc(project.id).update({
        favourites: (project.favourites || []).filter((sId) => sId !== sampleId),
        updatedAt: new Date(),
      });
    }
  }

  function addFavourite(sampleId) {
    if (project) {
      firestore.collection('projects').doc(project.id).update({
        favourites: [...(project.favourites || []), sampleId],
        updatedAt: new Date(),
      });
    }
  }

  useEffect(() => {
    if (isUnmounted.current) {
      return;
    }

    if (project && !isFetchedSamples && !isFetchingSamples) {
      fetchSamples();
    }

    if (!isGeneratedSamples && isFetchedSamples && samplesPaths && samplesPaths.length === 0 && !isGeneratingSamples && !isFetchingSamples) {
      generateSamples();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchedSamples, isFetchingSamples, auth.uid, (project || {}).id, firebase]);

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const favouritesSet = new Set((project || {}).favourites || []);

  let alert = null;
  const progressPercent = numberOfNodes ? Math.min(100, 100 * (favouritesSet.size / numberOfNodes)) : 0

  if (samplesPaths && samplesPaths.length > 0) {
    if (progressPercent === 100) {
      alert = (
        <Alert
          message="You've picked enough favorites for your video"
          description={(
            <>
              You can now continue to <Link to={`/project/${(project || {}).id}/timeline`}>Timeline</Link>
            </>
          )}
          type="success"
          showIcon
          banner
          style={{ marginTop: 6, marginBottom: 30 }}
        />
      );

    } else {
      alert = (
        <Alert
          message="Pick the images you would like to see in your video"
          description={(
            <>
              You need {numberOfNodes || '...'} images to fill your video. You can handpick them, or choose a random selection by&nbsp;
              <Link to={`/project/${(project || {}).id}/timeline`}>skipping this step</Link>.
            </>
          )}
          type="info"
          showIcon
          banner
          style={{ marginTop: 6, marginBottom: 30 }}
        />
      );
    }
  }

  return (
    <>
      <StyledProgress percent={progressPercent} showInfo={false} />

      {alert}

      {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>12 of 143 favorites</h2>
      
        <ImageViewSwitcher
          imageView={imageView}
          setImageView={setImageView}
        />
      </div> */}

      <div style={{ marginTop: 10 }}>
        {(!isLoaded(project) || (!isFetchedSamples && isFetchingSamples)) ? <DelayedSpin /> : null}

        {isLoaded(project) && (
          <div
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 1fr))',
              gridColumnGap: 24,
              gridRowGap: 24,
            }}
          >
            {samplesPaths && samplesPaths.map((path) => {
              const sampleId = getSampleIdFromPath(path);
              const isFavourite = favouritesSet.has(sampleId);
              return (
                <Sample
                  key={path}
                  path={path}
                  bucket={config.samplesBucket}
                  favourite={isFavourite}
                  animated={false}
                  onClick={() => {
                    if (isFavourite) {
                      removeFavourite(sampleId);
                    } else {
                      addFavourite(sampleId);
                    }
                  }}
                />
              );
            })}
          </div>
        )}

        {isLoaded(project) && isFetchedSamples && (
          <div style={{ marginTop: 50, textAlign: 'center', display: 'flex', justifyContent: 'stretch' }}>
            <div style={{ flex: 1 }}>

            </div>

            <div style={{ flex: 1 }}>
              <Button
                onClick={generateSamples}
                type="default"
                shape="round"
                size="large" 
                loading={isGeneratingSamples || isFetchingSamples}
              >
                {isGeneratingSamples || isFetchingSamples ? 'Generating' : 'Generate'} {samplesPaths && samplesPaths.length > 0 ? 'more' : ''} images
              </Button>

              {(samplesPaths || []).length === 0 && (
                <small style={{ display: 'block', marginTop: 10, color: lightgrey[3] }}>
                  Every image is uniquely generated just for you
                </small>
              )}
            </div>

            <div style={{ flex: 1, textAlign: 'right' }}>
              {(samplesPaths || []).length > 0 && (
                <Link to={`/project/${(project || {}).id}/timeline`}>
                  <Button
                    type="primary"
                    size="large" 
                  >
                    <Icon type="caret-right" />
                    Next step
                  </Button>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
