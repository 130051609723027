import React from 'react';
import { Switch, Route } from 'react-router';

import AuthenticatedRoute from 'components/AuthenticatedRoute';
import UnauthenticatedRoute from 'components/UnauthenticatedRoute';

import NotFound from 'pages/NotFound';
import Intro from 'pages/Intro';
import Dashboard from 'pages/Dashboard';
import NewProject from 'pages/NewProject';
import ProjectFavouritesOrCustomImages from 'pages/Project/FavouritesOrCustomImages';
import ProjectTimeline from 'pages/Project/Timeline';
import ProjectReview from 'pages/Project/Review';
import ProjectRenders from 'pages/Project/Renders';
import Login from 'pages/Login';
import Signup from 'pages/Signup';
import ForgotPassword from 'pages/ForgotPassword';
import RenderWallet from 'pages/Account/RenderWallet';

export default function () {
  return (
    <Switch>
      <UnauthenticatedRoute path="/login"><Login /></UnauthenticatedRoute>
      <UnauthenticatedRoute path="/signup"><Signup /></UnauthenticatedRoute>
      <UnauthenticatedRoute path="/forgot-password"><ForgotPassword /></UnauthenticatedRoute>

      <AuthenticatedRoute exact path="/intro"><Intro /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/"><Dashboard /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/new-project"><NewProject /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/project/:projectId"><ProjectFavouritesOrCustomImages /></AuthenticatedRoute>
      <AuthenticatedRoute path="/project/:projectId/timeline"><ProjectTimeline /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/project/:projectId/review"><ProjectReview /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/project/:projectId/renders"><ProjectRenders /></AuthenticatedRoute>
      <AuthenticatedRoute exact path="/account/render-wallet"><RenderWallet /></AuthenticatedRoute>

      <Route path="*"><NotFound /></Route>
    </Switch>
  );
}
