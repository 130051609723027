import { actionTypes } from 'react-redux-firebase';

export default function (state = {}, action) {
  switch (action.type) {
    case actionTypes.FILE_UPLOAD_PROGRESS:
      return {
        ...state,
        [action.meta.file.uid]: action.payload.percent,
      };

    default:
      return state;
  }
}
