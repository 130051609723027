export default function (audioDurationSeconds, numberOfNodesPerMinute, overRes = 1) {
  if (!audioDurationSeconds) {
    return null;
  }

  const numberOfNodesPerSecond = numberOfNodesPerMinute / 60;
  const numberOfNodesPerSecondResed = numberOfNodesPerSecond * overRes;

  const numberOfNodesWithoutFirst = Math.ceil(audioDurationSeconds * numberOfNodesPerSecondResed);

  return numberOfNodesWithoutFirst + 1;
}
