import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom';

export default function () {
  const { projectId } = useParams();
  const reduxKey = `renders.${projectId}`;

  useFirestoreConnect([
    {
      collection: `projects/${projectId}/renders`,
      storeAs: reduxKey,
    },
  ]);
  const renders = useSelector(({ firestore: { ordered } }) => ordered[reduxKey]);

  return renders;
}
