import React from 'react';
import styled from 'styled-components';

export default styled(({ button: Button, ...props }) => <Button {...props} />)`
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
`;
