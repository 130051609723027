import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import dateFormat from 'dateformat';
import { Button, Icon, Card, Empty, Typography } from 'antd';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import config from 'config';
import { brand } from 'colors';
import useSelectAuth from 'hooks/use-select-auth';
import DelayedSpin from 'components/DelayedSpin';
import FirebaseImage from 'components/FirebaseImage';
import ImagePlaceholder from 'components/ImagePlaceholder';

const ProjectCard = styled(Card)`
  width: 220px;
  margin-right: 50px !important;
  margin-bottom: 30px !important;
  box-shadow: none !important;
  transition: all 0.2s;

  .ant-card-cover {
    margin: -1px;
  }

  .ant-card-body {
    padding: 12px 0 18px 10px;

    .ant-card-meta-title {
      margin-bottom: 0;
    }
  }

  img, .img {
    border-radius: 3px;
    object-fit: cover;
  }

  &:hover {
    transform: scale(1.06);
  }
  &:active {
    transform: scale(1.025);
  }
`;

export default function () {
  const auth = useSelectAuth();

  useFirestoreConnect([
    { collection: 'projects', where: ['userId', '==', auth.uid], orderBy: ['updatedAt', 'desc'] },
  ]);
  
  const projects = useSelector((state) => state.firestore.ordered.projects);

  function getProjectLink(project) {
    if (Object.entries(project.nodes || {}).length > 0) {
      // If already picked samples, go to Timeline
      return `/project/${project.id}/timeline`;
    }

    return `/project/${project.id}`;
  }

  return (
    <>
      {isLoaded(projects) && !isEmpty(projects) && (
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: -15, marginBottom: 25 }}>
          <Typography>
            <Typography.Title level={2} style={{ margin: '15px 25px 15px 0', whiteSpace: 'nowrap' }}>
              Projects
            </Typography.Title>
          </Typography>

          <Link to="new-project" style={{ margin: '15px 0', ...(isMobile ? { width: '100%' } : {}) }}>
            <Button size="large" type="primary" block={isMobile}>
              <Icon type="plus" />
              Start new project
            </Button>
          </Link>
        </div>
      )}

      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 15, marginRight: -50 }}>
        {!isLoaded(projects) && <DelayedSpin />}

        {isLoaded(projects) && projects.map((project) => (
          <Link key={project.id} to={getProjectLink(project)}>
            <ProjectCard
              hoverable
              bordered={false}
              cover={project.nodes && Object.values(project.nodes).length > 0 ? (
                <FirebaseImage
                  path={`${auth.uid}/${project.id}/${Object.values(project.nodes)[0]}${project.imageSource === 'USER' ? '' : '.jpg'}`}
                  bucket={project.imageSource === 'USER' ? config.customImagesBucket : config.samplesBucket}
                  fallbackPath={project.model ? `models/${project.model.code}.jpg` : ''}
                  fallbackBucket={null}
                  width={220}
                  height={220}
                />
              ) : (project.model ? (
                <FirebaseImage path={`models/${project.model.code}.jpg`} width={220} height={220} />
              ) : (
                <ImagePlaceholder width={220} height={220} color={brand.primary} className="img" />
              ))}
            >
              <Card.Meta
                title={project.name}
                description={project.updatedAt ? <small>{dateFormat(new Date(project.updatedAt.seconds * 1000), 'mediumDate')}</small> : null}
              />
            </ProjectCard>
          </Link>
        ))}
      </div>

      {isLoaded(projects) && isEmpty(projects) && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 60 }}
          description="There's nothing here yet"
          style={{ margin: '50px auto 0 auto' }}
        >
          <Link to="new-project">
            <Button size="large" type="primary" style={{ marginTop: 10 }}>
              <Icon type="plus" />
              Start your first project
            </Button>
          </Link>
        </Empty>
      )}
    </>
  );
}
