import React from 'react';

import { Tooltip, Icon } from 'antd';
import styled from 'styled-components';

import { lightgrey } from 'colors';

const QuestionIcon = styled(Icon)`
  color: ${lightgrey[2]};
  margin-left: 2px;
`;

export default function ({ position, text, ...props }) {
  return (
    <Tooltip placement={position || 'bottom'} title={text}>
      <QuestionIcon type="question-circle" theme="filled" {...props} />
    </Tooltip>
  );
}
