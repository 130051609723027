import React from 'react';
import { Icon, Button, Tag } from 'antd';
import styled from 'styled-components';
import { magenta, purple } from 'colors';
import VimeoModal from 'components/VimeoModal';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-bottom: -20px;
`;

const Option = styled.div`
  flex: 1;
  min-width: 350px;
  border-radius: 4px;
  padding: 10px 16px 0 16px;
  background-color: ${({ color, selected }) => color[selected ? 1 : 0]};
  cursor: pointer;
  box-shadow: ${({ color, selected }) => selected ? `0 0 0 4px ${color[2]}` : 'none'};
  transition: all 0.2s;
  user-select: none;
  line-height: 1.5;
  margin-right: 20px;
  margin-bottom: 20px;

  .anticon {
    margin-right: 9px;
    color: ${({ color }) => color[4]};
  }

  &:hover {
    box-shadow: ${({ color }) => `0 0 0 4px ${color[2]}`};
  }

  button {
    margin-top: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  h3 {
    display: flex;
    align-items: center;
  }

  .ant-tag {
    border: none;
    background: rgba(0, 0, 0, 0.85) !important;
    color: ${({ color, selected }) => color[selected ? 1 : 0]};
    margin-left: 10px;
    margin-bottom: -1px;
  }

  .ant-btn-link {
    color: ${({ color }) => color[4]};

    &:hover {
      color: ${({ color }) => color[3]};
    }
    &:active {
      color: ${({ color }) => color[5]};
    }
  }

  .ant-btn-primary {
    background-color: ${({ color }) => color[4]};
    border: ${({ color }) => color[4]};
    margin-left: 16px;

    &:hover {
      background-color: ${({ color }) => color[3]};
      border: ${({ color }) => color[3]};
    }
    &:active {
      background-color: ${({ color }) => color[5]};
      border: ${({ color }) => color[5]};
    }
  }
`;

// const StyledBadge = styled(({ color, ...props }) => <Badge {...props} />)`
//   .ant-badge-count {
//     background-color: ${({ color }) => color} !important;
//     box-shadow: none !important;
//   }
// `;

const ButtonRow = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

const ExampleLink = styled((props) => <Button type="link" {...props} />)`
  display: inline;
  height: auto;
`;

// const SelectButton = styled(Button)`
//   &.selected {
//     opacity: 0;
//     pointer-events: none;
//     cursor: pointer;
//   }
// `;

export default class ProjectTypeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { exampleVimeoId: null, exampleVimeoVisible: false };
  }

  onShowExampleVideo = (event, exampleVimeoId) => {
    event.stopPropagation();
    this.setState({ exampleVimeoId, exampleVimeoVisible: true });
  }

  render() {
    return (
      <>
        <Wrapper>
          <Option color={magenta} selected={this.props.value === 'THEME'} onClick={() => this.props.onChange('THEME')}>
            <h3>
              <Icon type="thunderbolt" theme="filled" /> Visual Theme <Tag>Recommended</Tag>
            </h3>
            <p>
              Use a visual theme to easily pick beautiful images. The resulting video transitions smoothly from image to image.
            </p>
            <ButtonRow>
              <div>
                <ExampleLink
                  onClick={(e) => this.onShowExampleVideo(e, 369531132)}
                >
                  <small>Example video 1</small>
                </ExampleLink>

                <ExampleLink
                  onClick={(e) => this.onShowExampleVideo(e, 466429168)}
                >
                  <small>Example video 2</small>
                </ExampleLink>
              </div>

              {/* <SelectButton
                onClick={() => this.props.onChange('THEME')}
                type="primary"
                className={this.props.value === 'THEME' ? 'selected' : ''}
              >
                Select
              </SelectButton> */}
            </ButtonRow>
          </Option>
          <Option color={purple} selected={this.props.value === 'USER'} onClick={() => this.props.onChange('USER')}>
            <h3>
              <Icon type="picture" theme="filled" /> Custom Images <Tag>Alpha</Tag>
            </h3>
            <p>
              Upload your own custom images for more control. (This project type does not yet offer advanced settings.)
            </p>
            <ButtonRow>
              <ExampleLink
                onClick={(e) => this.onShowExampleVideo(e, 903114509)}
              >
                <small>Example video</small>
              </ExampleLink>
              
              {/* <SelectButton
                onClick={() => this.props.onChange('USER')}
                type="primary"
                className={this.props.value === 'USER' ? 'selected' : ''}
              >
                Select
              </SelectButton> */}
            </ButtonRow>
          </Option>
        </Wrapper>

        {this.state.exampleVimeoId && (
          <VimeoModal
            videoId={this.state.exampleVimeoId}
            visible={this.state.exampleVimeoVisible}
            onCancel={() => this.setState({ exampleVimeoVisible: false })}
          />
        )}
      </>
    );
  }
}
