import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'; // <- needed if using firestore
import uploadProgress from './upload-progress';
import view from './view';
import project from './project';

export default function (history) {
  return combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer, // <- needed if using firestore

    uploadProgress,
    view,
    project,
  });
}
