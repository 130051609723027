import React from 'react';
import { Row, Col, Typography, Button, Icon } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { grey, brand, red, green, magenta } from 'colors';
import useSelectIsAdmin from 'hooks/use-select-is-admin';

const TitleWithIcon = styled(Typography.Title)`
  svg {
    margin-right: 12px;
  }
`;

const textStyle = { fontSize: 20, color: grey[0], fontWeight: 300 };

export default function () {
  const isAdmin = useSelectIsAdmin();

  return (
    <Row style={{ maxWidth: /* 1280 */ 750, margin: '0 auto', paddingTop: 30 }}>
      <Col md={{ span: 24, offset: 0 }} lg={{ span: 22, offset: 1 }} >
        <Row gutter={[40, 0]}>
          {/* <Col md={24} lg={10}>
            <Typography>
              <Typography.Title level={2}>
                Head over to your project dashboard to start creating!
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                From your project dashboard, you'll be able to create a WZRD project.
              </Typography.Paragraph>
            </Typography>

            <Link to="/">
              <Button size="large" type="primary" style={{ marginBottom: 40, marginTop: 6 }}>Go to dashboard</Button>
            </Link>
          </Col> */}

          {/* <Col md={24} lg={14}> */}
          <Col>
            <Typography>
              <Typography.Title level={3}>
                How does <span style={{ fontWeight: 900 }}>WZRD</span> work?
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                WZRD provides an easy way for people to enhance audio with stunning visuals. The visuals are automatically generated, which means you can quickly create an awesome video for your project.

                WZRD works in a couple of steps:
              </Typography.Paragraph>

              <TitleWithIcon level={4}>
                <Icon type="file-add" theme="filled" style={{ color: brand[5] }} />
                Create a project
              </TitleWithIcon>

              <Typography.Paragraph style={textStyle}>
                First, you create a project by uploading an audio file and choosing a visual theme.
              </Typography.Paragraph>

              <TitleWithIcon level={4}>
                <Icon type="heart" theme="filled" style={{ color: red[5] }} />
                Images
              </TitleWithIcon>

              <Typography.Paragraph style={textStyle}>
                Once that's done, if you chose a visual theme then random images are uniquely generated for you. You can pick your favorites which will define the visuals of your video.<br />
                {isAdmin && 'You can also upload your own images instead of using a visual theme.'}
              </Typography.Paragraph>

              <TitleWithIcon level={4}>
                <Icon type="clock-circle" theme="filled" style={{ color: green[5] }} />
                Timeline
              </TitleWithIcon>

              <Typography.Paragraph style={textStyle}>
                After that, head over to Timeline, where you can put your images on the audio track. You can either hand-pick every image or choose Auto-fill to quickly fill the available spots on the timeline. You can also use drag&drop to move images around.
              </Typography.Paragraph>

              <TitleWithIcon level={4}>
                <Icon type="play-square" theme="filled" style={{ color: magenta[5] }} />
                Render
              </TitleWithIcon>

              <Typography.Paragraph style={textStyle}>
                Finally, hit the render button. This will create a video that blends your chosen images into a stunning visual. The render process can take up to one hour to complete, so we'll send you an email once it's done.
              </Typography.Paragraph>
            </Typography>

            <div className="text-center" style={{ margin: '40px 0' }}>
              <Link to="/">
                <Button size="large" type="primary" style={{ padding: '0 18px' }}>
                  Get started
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
