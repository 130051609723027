import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { Modal, Empty, Button } from 'antd';
import styled from 'styled-components';

import config from 'config';
import useSelectAuth from 'hooks/use-select-auth';
import useSelectProject from 'hooks/use-select-project';
import getCustomImages from 'utils/get-custom-images';
import FirebaseImage from 'components/FirebaseImage';
import getFileNameFromPath from 'utils/get-file-name-from-path';
import { selectIsProjectImageSourceUser } from 'store/selectors';

const StyledFirebaseImage = styled(({ picked, ...rest }) => <FirebaseImage {...rest} />)`
  opacity: ${({ picked }) => picked ? 0.25 : 1};
  transition: opacity 0.3s, transform 0.2s !important;
  border-radius: 2px;
  overflow: hidden;
  object-fit: cover;

  &:hover {
    opacity: 1;
    transform: scale(1.035);
  }
`;

export default function ({ visible, close, onSelect }) {
  const auth = useSelectAuth();
  const project = useSelectProject();
  const isImageSourceUser = useSelector(selectIsProjectImageSourceUser);
  const isUnmounted = useRef(false);
  const [customImagePaths, setCustomImagesPaths] = useState([]);
  const isEmpty = (project.favourites || []).length === 0 && customImagePaths.length === 0;

  useEffect(() => {
    isUnmounted.current = false;
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if ((project || {}).imageSource !== 'USER') {
      return;
    }

    getCustomImages(auth, project)
      .then((paths) => {
        if (isUnmounted.current) return;
        setCustomImagesPaths(paths)
      })
      .catch((err) => {
        console.error(err);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(auth || []).uid, (project || []).id]);

  if (!isLoaded(project)) {
    return null;
  }

  const pickedImageIds = new Set();
  Object.values(project.nodes || {}).forEach((sampleId) => {
    pickedImageIds.add(sampleId);
  });

  return (
    <Modal
      visible={visible}
      title={isImageSourceUser ? 'Select an image' : 'Select a favorite'}
      onCancel={close}
      footer={null}
      width={1080}
      style={{ maxWidth: '95%', ...(!isEmpty ? { top: 40 } : {}) }}
      bodyStyle={{ paddingTop: 0 }}
    >
      {!isEmpty && (
        <div
          style={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 1fr))',
            gridColumnGap: 24,
            gridRowGap: 24,
          }}
        >
          {isImageSourceUser ? 
            customImagePaths
              .map((path) => {
                const fileName = getFileNameFromPath(path);
                return (
                  <StyledFirebaseImage
                    key={path}
                    path={path}
                    bucket={config.customImagesBucket}
                    onClick={() => {
                      onSelect(fileName);
                      close();
                    }}
                    picked={pickedImageIds.has(fileName)}
                    lazyload={false} // Doesn't work on latest chrome with lazyload :/
                  />
                );
              }) :
            project.favourites
              .sort((a, b) => {
                const pickedA = pickedImageIds.has(a);
                const pickedB = pickedImageIds.has(b);
                return (pickedA === pickedB) ? 0 : (pickedA ? 1 : -1);
              })
              .map((sampleId) => (
                <StyledFirebaseImage
                  key={sampleId}
                  path={`${auth.uid}/${project.id}/${sampleId}.jpg`}
                  bucket={config.samplesBucket}
                  onClick={() => {
                    onSelect(sampleId);
                    close();
                  }}
                  picked={pickedImageIds.has(sampleId)}
                  lazyload={false} // Doesn't work on latest chrome with lazyload :/
                />
              ))
          }
        </div>
      )}

      {isEmpty && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 60 }}
          description={`You don't have ${isImageSourceUser ? 'images' : 'favorites'} yet`}
          style={{ margin: '30px auto 70px auto' }}
        >
          <Link to={`/project/${project.id}`}>
            <Button size="large" type="primary" style={{ marginTop: 10 }}>
              Go to {isImageSourceUser ? 'images' : 'favorites'}
            </Button>
          </Link>
        </Empty>
      )}
    </Modal>
  );
}
